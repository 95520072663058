<template>
  <div id="scanner"></div>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode';
import scannerBeep from '@/assets/audio/scanner-beep.mp3';
import { playAudio } from '@/utils/audio';

export default {
  emits: ['scanned'],
  props: {
    config: Object,
    playSoundOnScanned: {
      type: Boolean,
      default: false,
    },
    dynamicQrBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      camera: null,
      scanner: null,
    };
  },
  computed: {
    scannerConfig() {
      return {
        fps: 2,
        qrbox: this.dynamicQrBox
          ? this.getDynamicQrBoxSize
          : {
              width: 250,
              height: 250,
            },
        ...this.config,
      };
    },
  },
  methods: {
    async setupCamera() {
      const devices = await Html5Qrcode.getCameras();

      this.camera = devices[0].id;
    },
    async setupScanner() {
      this.scanner = new Html5Qrcode('scanner', this.scannerConfig);
    },
    async startScanning() {
      try {
        await this.scanner.start(
          this.camera,
          this.scannerConfig,
          this.handleScanned,
          () => {}
        );
      } catch (err) {
        console.log(err);
      }
    },
    async dropScanner() {
      if (this.scanner) {
        await this.scanner.stop();
      }
    },
    getDynamicQrBoxSize(viewfinderWidth, viewfinderHeight) {
      const minEdgePercentage = 0.7;
      const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
      const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);

      return {
        width: qrboxSize,
        height: qrboxSize,
      };
    },
    handleScanned(txt, res) {
      this.$emit('scanned', txt, res);

      if (this.playSoundOnScanned) {
        playAudio(scannerBeep);
      }
    },
  },
  async mounted() {
    await this.setupCamera();
    await this.setupScanner();

    this.startScanning();
  },
  destroyed() {
    this.dropScanner();
  },
};
</script>
