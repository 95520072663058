<template>
  <div class="space-y-4 border-t border-gray-200 pt-5">
    <div class="flex justify-end">
      <base-input
        type="text"
        :shadow="false"
        placeholder="Cari kode atau nama"
        debounce
        v-model="filter.search"
        v-on:native-input="handleSearch"
      />
    </div>
    <div class="flex sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full align-middle sm:px-6 lg:px-8">
        <datatable
          :total="getProducts.meta.page.total"
          :perPage="getProducts.meta.page.perPage"
          :currentPage="getProducts.meta.page.currentPage"
          @pagechanged="handleChangePage"
        >
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Kode Barang
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Kode Supplier
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Kelompok Produk
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="getProducts.data?.length">
              <tr
                v-for="product in getProducts.data"
                :key="product.id"
                class="cursor-pointer bg-white hover:bg-green-100"
                @click="handleClickProduct(product)"
              >
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-700">
                  <p class="font-bold">
                    {{ product.attributes.code }}
                  </p>
                  <p class="text-xs">
                    {{ product.attributes.name }}
                  </p>
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                >
                  {{ product.attributes.supplier_code }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                >
                  {{ product.attributes.product_category_name ?? '-' }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="bg-white">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  colspan="8"
                >
                  Data tidak ditemukan
                </td>
              </tr>
            </tbody>
          </template>
        </datatable>
      </div>
    </div>
  </div>
</template>

<script>
import Datatable from '@/components/base/Datatable';
import { mapGetters } from 'vuex';
import BaseInput from '@/components/base/BaseInput';

export default {
  name: 'ProductTable',
  components: {
    Datatable,
    BaseInput,
  },
  props: {},

  data() {
    return {
      filter: {
        search: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getProducts: 'products/getProducts',
    }),
  },
  methods: {
    handleClickProduct(product) {
      this.$emit('click-row', product);
    },
    handleChangePage(page) {
      this.$emit('change-page', page);
    },
    handleSearch() {
      this.$emit('search', this.filter.search);
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
