<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <base-input :shadow="false" placeholder="Cari Promo" type="search" debounce v-on:native-input="onSearch" v-model="filter.search" />
        <base-button :to="{ name: 'admin.setup.promo.tambah' }"
          >Tambah Promo</base-button
        >
      </div>
      <div>
        <datatable
          :columns="columns"
          :data="productPromos.data"
          :scroll-x="false"
          :total="productPromos.meta.page.total"
          :perPage="productPromos.meta.page.perPage"
          :currentPage="productPromos.meta.page.currentPage"
          @pagechanged="onPageChanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="(productPromo, index) in productPromos.data"
              :key="productPromo.id"
              :class="[
                classes.tr,
                index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                'cursor-pointer bg-white hover:bg-green-100',
              ]"
              @click="onClick(productPromo)"
            >
              <td :class="[classes.td]">
                <span class="font-bold text-gray-900">{{
                  productPromo.attributes.name
                }}</span>
              </td>
              <td :class="classes.td">
                {{ productPromo.attributes.description }}
              </td>
              <td :class="classes.td">
                {{ productPromo.attributes.sc_start_at | formatDate }}
                -
                {{ productPromo.attributes.sc_finish_at | formatDate }}
              </td>
              <td :class="classes.td">
                <base-badge>
                  {{
                    productPromo.attributes.is_active
                      ? 'Aktif'
                      : 'Tidak Aktif'
                  }}
                </base-badge>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </div>
    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  data() {
    return {
      filter: {
        search: null
      },
      loading: false,
      productPromos: {
        data: [],
        meta: {
          page: {
            total: 0
          }
        }
      },
    };
  },
  computed: {
    columns() {
      return [
        { id: 'name', name: 'Nama Promo' },
        { id: 'description', name: 'Deskripsi' },
        { id: 'date', name: 'Tanggal Mulai - Berakhir' },
        { id: 'status', name: 'Status' },
      ];
    },
  },
  methods: {
    async loadPromos(params = {}) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/product-promos', {
        params: {
          'page[size]': 5,
          'filter[search]': this.filter.search,
          ...params
        },
      });

      if (!error) {
        this.productPromos = res;
      }

      this.loading = false;
    },
    onClick(promo) {
      this.$router.push(`promo/${promo.id}`);
    },
    onPageChanged(page) {
      this.loadPromos({
        'page[number]': page
      })
    },
    onSearch() {
      this.loadPromos()
    }
  },
  created() {
    this.loadPromos();
  },
};
</script>
