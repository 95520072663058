<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <Tabs
          :tabs-data="tabs"
          :tab-active="tabActive"
          @updateTabActive="changeTab"
        >
          <template v-slot:default="{ tabActive }">
            <template
              v-if="tabActive === 'Pajak'"
              class="transition duration-300 ease-in-out"
            >
              <div>
                <div class="my-4 flex items-center justify-center space-y-2">
                  <div class="space-y-2">
                    <div class="mb-2 flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </div>
                    <p class="text-center text-xs font-normal text-gray-400">
                      Pajak belum diatur
                    </p>
                    <button
                      @click="modal = 'setPajak'"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Atur Pajak
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="tabActive === 'Nilai Pengali'">
              <div class="space-y-8 divide-y divide-gray-200">
                <div class="flex justify-between">
                  <div>
                    <h3 class="text-lg font-medium text-gray-900">
                      Pengaturan Nilai Pengali
                    </h3>
                    <p class="text-sm text-gray-500">
                      Atur nilai pengali yang akan digunakan untuk deposit saldo
                      SC
                    </p>
                  </div>
                </div>
                <div class="gap-8 py-8 sm:grid sm:grid-cols-3">
                  <div class="px-4 py-5 sm:p-0">
                    <label
                      for="pengali"
                      class="block text-sm font-medium text-gray-700"
                      >Nilai Pengali</label
                    >
                    <div class="relative mt-1 inline-flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="pengali"
                        id="pengali"
                        v-model="setupMultiplier.setup_value"
                        class="block w-full rounded-md border-gray-300 pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        :class="{
                          'bg-gray-100': isDisabled,
                        }"
                        placeholder="Masukkan nilai pengali"
                        :disabled="isDisabled"
                      />
                      <div v-if="!isEditing" class="transition-all ease-in-out">
                        <button
                          @click="enableEditing"
                          class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3 text-gray-500 hover:text-blue-500"
                        >
                          <!-- Heroicon name: mini/question-mark-circle -->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="h-6 w-6"
                          >
                            <path
                              d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z"
                            />
                            <path
                              d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z"
                            />
                          </svg>
                        </button>
                      </div>
                      <transition name="slideLeft">
                        <div v-if="isEditing" class="ml-2 inline-flex">
                          <span
                            class="isolate inline-flex rounded-md shadow-sm"
                          >
                            <button
                              type="button"
                              @click="cancelEditing"
                              class="focus:outline-none relative inline-flex items-center rounded-l-md border border-gray-300 bg-red-50 p-1.5 text-sm font-medium text-red-700 hover:bg-red-200 focus:z-10 focus:border-red-500 focus:ring-1 focus:ring-red-500"
                            >
                              <span class="sr-only">Cancel</span>
                              <!-- Heroicon name: mini/chevron-left -->
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="h-6 w-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                            <button
                              type="button"
                              @click="saveEdit"
                              class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-green-50 p-1.5 text-sm font-medium text-green-700 hover:bg-green-200 focus:z-10 focus:border-green-500 focus:ring-1 focus:ring-green-500"
                            >
                              <span class="sr-only">Save</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="h-6 w-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"
                                />
                              </svg>
                            </button>
                          </span>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template
              v-if="tabActive === 'Metode Pembayaran'"
              class="transition duration-300 ease-in-out"
            >
              <div
                v-if="getBanksByOffice.data"
                class="space-y-8 divide-y divide-gray-200"
              >
                <div class="flex justify-between">
                  <div>
                    <h3 class="text-lg font-medium text-gray-900">
                      Pengaturan Bank
                    </h3>
                    <p class="text-sm text-gray-500">
                      Atur bank yang akan digunakan untuk pembayaran
                    </p>
                  </div>
                  <button
                    @click="modal = 'addMetodePembayaran'"
                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Tambah Metode Pembayaran
                  </button>
                </div>
                <div class="gap-8 py-8 sm:grid sm:grid-cols-3">
                  <div
                    v-for="bank in getBanksByOffice.data"
                    :key="bank.id"
                    class="flex items-start space-x-4"
                  >
                    <div>
                      <input
                        id="ekspedisi"
                        name="ekspedisi"
                        :checked="bank.attributes.is_active"
                        @change="changeActive(bank)"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600"
                      />
                    </div>
                    <div class="space-y-1">
                      <div class="mt-0.5 flex items-center space-x-4">
                        <h4 class="text-sm font-medium text-gray-900">
                          {{ bank.attributes.bank_name }}
                        </h4>
                        <button
                          v-on:click="onDeleteBank(bank.id)"
                          v-if="bank.attributes.office_bank_type === 'bank'"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4 text-red-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </div>
                      <p class="text-sm text-gray-500">
                        a.n
                        {{ bank.attributes.account_name }}
                      </p>
                      <p class="text-sm text-gray-500">
                        No Rek:
                        {{ bank.attributes.account_number ?? '-' }}
                      </p>
                      <p class="text-sm text-gray-500">
                        No GL:
                        {{ bank.attributes.no_account_gl ?? '-' }}
                      </p>
                      <p class="text-sm text-gray-500">
                        Cost Center:
                        {{ bank.attributes.cost_center ?? '-' }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template
              v-if="tabActive === 'Cashback'"
              class="transition duration-300 ease-in-out"
            >
              <div>
                <div class="flex justify-between">
                  <div>
                    <h3 class="text-lg font-medium text-gray-900">
                      Pengaturan Cashback
                    </h3>
                    <p class="text-sm text-gray-500">
                      Atur cashback pada setiap tipe kantor
                    </p>
                  </div>
                </div>
                <template v-for="(data, index) in officeCategory.data">
                  <section
                    v-if="
                      ['Pusat', 'Kantor Pelayanan', 'Stockist Center'].includes(
                        data.attributes.name
                      )
                    "
                    :key="data.id"
                  >
                    <div
                      class="mt-6 mb-4 flex items-center justify-between divide-y divide-gray-200"
                    >
                      <h1 class="text-lg font-medium text-gray-900">
                        Cashback {{ data.attributes.name }}
                      </h1>
                      <button
                        @click="openDetail(index)"
                        type="button"
                        class="inline-flex items-center rounded-full border border-gray-300 bg-white p-1.5 text-gray-600 shadow-sm hover:text-gray-900"
                      >
                        <svg
                          :class="[
                            index == openDetailOffice ? '' : '-rotate-90',
                          ]"
                          class="transform transition duration-200 ease-in-out"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                          />
                        </svg>
                      </button>
                    </div>

                    <div
                      ref="cashback"
                      class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6"
                    >
                      <div
                        class="field-inset-default relative flex w-full justify-between sm:col-span-3"
                      >
                        <div class="w-full">
                          <label
                            for="name"
                            class="block text-xs font-bold text-gray-700"
                          >
                            Cashback Jawa
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            v-model="data.attributes.cashback_jawa"
                            class="block w-full border-0 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                            placeholder="Masukkan Jumlah Cashback "
                          />
                        </div>
                      </div>

                      <div
                        class="field-inset-default relative flex w-full justify-between sm:col-span-3"
                      >
                        <div class="w-full">
                          <label
                            for="name"
                            class="block text-xs font-bold text-gray-700"
                          >
                            Cashback Minimal Order Jawa
                          </label>
                          <input
                            v-model="data.attributes.min_order_jawa"
                            type="text"
                            name="name"
                            id="name"
                            class="block w-full border-0 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                            placeholder="Masukkan Jumlah Cashback "
                          />
                        </div>
                      </div>

                      <div
                        class="field-inset-default relative flex w-full justify-between sm:col-span-3"
                      >
                        <div class="w-full">
                          <label
                            for="name"
                            class="block text-xs font-bold text-gray-700"
                          >
                            Cashback Luar Jawa
                          </label>
                          <input
                            v-model="data.attributes.cashback_luar_jawa"
                            type="text"
                            name="name"
                            id="name"
                            class="block w-full border-0 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                            placeholder="Masukkan Jumlah Cashback "
                          />
                        </div>
                      </div>

                      <div
                        class="field-inset-default relative flex w-full justify-between sm:col-span-3"
                      >
                        <div class="w-full">
                          <label
                            for="name"
                            class="block text-xs font-bold text-gray-700"
                          >
                            Cashback Minimal Order Luar Jawa
                          </label>
                          <input
                            type="text"
                            v-model="data.attributes.min_order_luar_jawa"
                            name="name"
                            id="name"
                            class="block w-full border-0 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                            placeholder="Masukkan Jumlah Cashback "
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          @click="updateCashback(index)"
                          type="submit"
                          id="simpan_stockist"
                          class="rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  </section>
                </template>
              </div>
            </template>

            <template v-if="tabActive === 'Setup Bank Mitra'">
              <admin-setup-bank-mitra-tab />
            </template>
          </template>
        </Tabs>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal @modal-action="closeModal" link="/gudang">
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Data berhasil disimpan
            </h3>
          </template>
        </modal>
      </div>
    </transition>

    <!-- Modal untuk konfirmasi pengaturan nilai pengali -->
    <BaseModal
      :showing="multiplierConfirmation"
      @close="multiplierConfirmation = false"
      :showClose="multiplierConfirmation"
      size="max-w-lg"
    >
      <div>
        <div
          class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100 text-yellow-700"
        >
          <!-- Heroicon name: outline/check -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3
            class="text-lg font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Konfirmasi Perubahan Nilai Pengali
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Nilai pengali yang diubah akan mempengaruhi saldo stockist secara
              keseluruhan. Apakah anda yakin ingin melanjutkan?
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
          <button
            @click="multiplierConfirmation = !multiplierConfirmation"
            type="button"
            class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Batalkan
          </button>
          <button
            type="button"
            @click="createMultiplierValue"
            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            Lanjutkan
          </button>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      :showing="success"
      @close="success = false"
      :showClose="success"
      size="max-w-lg"
    >
      <div>
        <div
          class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 text-green-700"
        >
          <!-- Heroicon name: outline/check -->
          <div
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
          >
            <svg
              class="h-6 w-6 text-green-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3
            class="text-lg font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Data Berhasil disimpan
          </h3>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
          <button
            @click="
              success = !success;
              cancelEditing();
            "
            type="button"
            class="-backdrop-hue-rotate-15shadow-sm focus:outline-none mt-3 inline-flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Tutup
          </button>
        </div>
      </div>
    </BaseModal>

    <transition name="fade">
      <div
        v-if="deleteBankModal.visible"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal :actions="false">
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Konfirmasi Penghapusan
            </h3>
          </template>
          <template v-slot:actions>
            <button
              @click="deleteBank"
              class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
            >
              Konfirmasi
            </button>
            <button
              @click="deleteBankModal.visible = false"
              class="focus:outline-none inline-flex w-full justify-center rounded-md border px-4 py-2 font-medium shadow-sm focus:ring-2 focus:ring-offset-2 sm:text-sm"
            >
              Batal
            </button>
          </template>
        </modal>
      </div>
    </transition>

    <!-- Modal untuk Metode Pembayaran -->
    <BaseModal
      :showing="modal === 'addMetodePembayaran'"
      @close="modal = ''"
      :showClose="modal === 'addMetodePembayaran'"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <form @submit.prevent="createPaymentMethod" id="modal-add">
        <div>
          <div
            class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
          >
            <div>
              <h3 class="text-lg font-semibold leading-6 text-gray-900">
                Atur Metode Pembayaran
              </h3>
            </div>
          </div>
          <div class="py-2 px-6">
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <div class="field-inset-default w-full">
                <label
                  for="categories"
                  class="block text-xs font-bold text-gray-700"
                >
                  Kelompok Produk
                </label>
                <select
                  v-model="dataMetodePembayaran.bank"
                  id="categories"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Stok Barang"
                >
                  <option :value="null">Pilih Bank</option>
                  <option
                    v-for="bank in getBanks.data.filter(
                      (it) =>
                        !it.attributes?.name?.includes('NATURAL NUSANTARA')
                    )"
                    :key="bank.id"
                    :value="bank.id"
                  >
                    {{ bank.attributes.name }}
                  </option>
                </select>
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="no_rekening"
                  class="block text-xs font-bold text-gray-700"
                >
                  No Rekening
                </label>
                <input
                  v-model="dataMetodePembayaran.no_rekening"
                  type="text"
                  name="no_rekening"
                  id="no_rekening"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="ex: 213153231"
                />
              </div>
            </div>
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <div class="field-inset-default w-full">
                <label
                  for="nama_penerima"
                  class="block text-xs font-bold text-gray-700"
                >
                  Nama Penerima
                </label>
                <input
                  v-model="dataMetodePembayaran.penerima"
                  type="text"
                  name="nama_penerima"
                  id="nama_penerima"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="ex: David"
                />
              </div>
            </div>
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <div v-if="withAccountGL" class="field-inset-default w-full">
                <label
                  for="no_account_gl"
                  class="block text-xs font-bold text-gray-700"
                >
                  No Account GL
                </label>
                <input
                  v-model="dataMetodePembayaran.no_account_gl"
                  type="text"
                  name="no_account_gl"
                  id="no_account_gl"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan No Account GL baru (contoh: 2342ABFDS)"
                />
              </div>
              <div v-if="withCostCenter" class="field-inset-default w-full">
                <label
                  for="cost_center"
                  class="block text-xs font-bold text-gray-700"
                >
                  Cost Center
                </label>
                <input
                  v-model="dataMetodePembayaran.cost_center"
                  type="text"
                  name="cost_center"
                  id="cost_center"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan No Cost Center (wajib)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <div class="flex justify-end">
            <base-button type="submit" id="simpan_supplier">
              Simpan
            </base-button>
            <base-button
              color="white"
              @click="modal = ''"
              id="selesai_supplier"
            >
              Kembali
            </base-button>
          </div>
        </div>
      </form>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { StorageService } from '@/services/storage.service';
import Modal from '@/components/Modal';
import BaseModal from '@/components/base/BaseModal';
import AdminSetupBankMitraTab from '@/components/admin/setup/AdminSetupBankMitraTab.vue';
import Tabs from '@/components/Tabs.vue';
import { StorageService } from '@/services/storage.service';
export default {
  name: 'Setup',
  props: {
    withAccountGL: {
      default: true,
    },
    withCostCenter: {
      default: true,
    },
    tabs: {
      type: Array,
      default: () => [
        // 'Pajak',
        'Metode Pembayaran',
        // 'Nomor Depan Faktur Pusat',
        // 'Minimal Order Stockist',
        // 'Minimal Order Free Ongkir',
        'Nilai Pengali',
        'Cashback',
        // 'Faktur Sementara',
        // 'Ekspedisi',
        'Setup Bank Mitra',
      ],
    },
  },
  components: {
    Modal,
    BaseModal,
    AdminSetupBankMitraTab,
    Tabs,
  },
  data() {
    return {
      tabActive: 'Metode Pembayaran',
      modal: null,
      openDetailOffice: -1,
      success: false,
      failed: false,
      dataMetodePembayaran: {
        bank: null,
        no_rekening: null,
        penerima: null,
        no_account_gl: null,
        cost_center: null,
      },
      deleteBankModal: {
        visible: false,
        id: null,
      },
      setupMultiplier: {
        id: null,
        setup_value: null,
      },
      isEditing: false,
      isDisabled: true,
      idMultiplier: null,
      multiplierConfirmation: false,
      officeCategory: { data: [] },
    };
  },
  async created() {
    await this.fetchBanks();
    this.fetchOfficeCategories().then((response) => {
      this.officeCategory = response.data;
    });
    this.loadOfficeBank();
    this.fetchMultiplier().then((response) => {
      this.setupMultiplier.id = response.data.data[0].id;
      this.setupMultiplier.setup_value =
        response.data.data[0].attributes.setup_value;
    });
  },
  computed: {
    ...mapGetters({
      getBanksByOffice: 'banks/getBanksByOffice',
      getBanks: 'banks/getBanks',
      getMultiplier: 'setups/getMultiplier',
      getError: 'setups/getError',
      getOfficeCategories: 'office_categories/getOfficeCategories',
    }),
  },
  methods: {
    ...mapActions({
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchBanks: 'banks/fetchBanks',
      createOfficeBank: 'banks/createOfficeBank',
      updateOfficeBank: 'banks/updateOfficeBank',
      deleteOfficeBank: 'banks/deleteOfficeBank',
      fetchMultiplier: 'setups/fetchMultiplier',
      updateMultiplier: 'setups/updateMultiplier',
      fetchOfficeCategories: 'office_categories/fetchOfficeCategories',
      updateOfficeCategory: 'office_categories/updateOfficeCategory',
    }),
    async loadOfficeBank() {
      return await this.fetchBanksByOffice({
        office_id: StorageService.getUser().office_id,
      });
    },
    openDetail(index) {
      this.$refs.cashback[index].classList.toggle('hidden');
      if (this.openDetailOffice == index) {
        return (this.openDetailOffice = -1);
      }
      this.openDetailOffice = index;
    },
    changeTab(tab) {
      this.tabActive = tab;
    },
    closeModal() {
      this.success = this.failed = false;
    },
    // Metode Pembayaran
    enableEditing() {
      (this.isEditing = true), (this.isDisabled = false);
    },
    cancelEditing() {
      (this.isEditing = false), (this.isDisabled = true);
    },
    saveEdit() {
      this.multiplierConfirmation = true;
    },
    createMultiplierValue() {
      const payload = {
        data: {
          type: 'setups',
          id: this.setupMultiplier.id,
          attributes: {
            setup_value: this.setupMultiplier.setup_value,
          },
        },
      };
      this.updateMultiplier(payload).then((response) => {
        if (response) {
          this.multiplierConfirmation = false;
          this.success = true;
          this.isEditing = false;
        } else {
          this.success = false;
          this.failed = true;
        }
      });
    },
    async createPaymentMethod() {
      const payload = {
        data: {
          type: 'office-banks',
          attributes: {
            account_name: this.dataMetodePembayaran.penerima,
            account_number: this.dataMetodePembayaran.no_rekening,
            no_account_gl: this.dataMetodePembayaran.no_account_gl,
            cost_center: this.dataMetodePembayaran.cost_center,
            is_active: true,
          },
          relationships: {
            bank: {
              data: {
                type: 'banks',
                id: this.dataMetodePembayaran.bank,
              },
            },
            office: {
              data: {
                type: 'offices',
                id: StorageService.getUser().office_id,
              },
            },
          },
        },
      };
      await this.createOfficeBank(payload).then((response) => {
        this.modal = null;
        if (response) {
          this.success = true;
        } else {
          this.failed = true;
        }
      });
      this.loadOfficeBank();
    },
    changeActive(bank) {
      const payload = {
        data: {
          id: bank.id,
          type: 'office-banks',
          attributes: {
            is_active: !bank.attributes.is_active,
          },
        },
      };
      this.updateOfficeBank(payload).then(() => {
        this.fetchBanksByOffice({
          office_id: StorageService.getUser().office_id,
        });
      });
    },
    onDeleteBank(id) {
      this.deleteBankModal.visible = true;
      this.deleteBankModal.id = id;
    },
    deleteBank() {
      this.deleteOfficeBank(this.deleteBankModal.id).then(() => {
        this.fetchBanksByOffice({
          office_id: StorageService.getUser().office_id,
        }).then(() => {
          this.deleteBankModal.visible = false;
          this.deleteBankModal.id = null;
        });
      });
    },
    updateCashback(index) {
      const payload = {
        data: {
          type: 'office-categories',
          id: this.officeCategory.data[index].id,
          attributes: {
            min_order_jawa: parseFloat(
              this.officeCategory.data[index].attributes.min_order_jawa
            ),
            cashback_jawa: parseFloat(
              this.officeCategory.data[index].attributes.cashback_jawa
            ),
            min_order_luar_jawa: parseFloat(
              this.officeCategory.data[index].attributes.min_order_luar_jawa
            ),
            cashback_luar_jawa: parseFloat(
              this.officeCategory.data[index].attributes.cashback_luar_jawa
            ),
          },
        },
      };

      this.updateOfficeCategory(payload);
    },
  },
};
</script>
