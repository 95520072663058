<template>
  <base-wrapper>
    <div class="space-y-5">
      <base-card title="Informasi Promo">
        <div class="grid grid-cols-2 gap-5">
          <div class="col-span-2">
            <base-input type="text" label="Nama Promo" placeholder="Promo 12.12" inset required v-model="form.name" />
          </div>
          <div class="col-span-2">
            <base-input label="Detail Promo" placeholder="Contoh: Promo Akhir Tahun" tag="textarea" rows="5" inset required v-model="form.description" />
          </div>
          <div class="col-span-2 space-y-4">
            <h4 class="font-medium text-gray-900">Tanggal Promo Stockist Center</h4>
            <div class="grid grid-cols-2 gap-5">
              <base-input label="Tanggal Dimulai Promo" type="datetime-local" inset required v-model="form.sc_start_at" />
              <base-input label="Tanggal Berakhir Promo" type="datetime-local" inset required v-model="form.sc_finish_at" />
            </div>
          </div>
          <div class="col-span-2 space-y-4">
            <h4 class="font-medium text-gray-900">Tanggal Promo Stockist / Mitra</h4>
            <div class="grid grid-cols-2 gap-5">
              <base-input label="Tanggal Dimulai Promo" type="datetime-local" inset required v-model="form.stockist_start_at" />
              <base-input label="Tanggal Berakhir Promo" type="datetime-local" inset required v-model="form.stockist_finish_at" />
            </div>
          </div>
          <div class="col-span-2 flex justify-end">
            <base-button @click="onSave">Simpan</base-button>
          </div>
        </div>
      </base-card>
      <base-card v-if="promo.data.id" title="Isi Promo" description="Klik pada baris untuk menambahkan/mengubah isi promo">
        <datatable :columns="columns" :scroll-x="false">
          <template #tbody="{ classes }">
            <tr
              v-for="(productBundle) in getIncludedByType(promo, 'product-bundles')"
              :key="productBundle.id"
              :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
              @click="onDetailProductBundle(productBundle)"
            >
              <td :class="classes.td">
                <span class="font-bold text-gray-900">
                  {{ productBundle.attributes.name }}
                </span>
              </td>
              <td :class="classes.td">
                {{ productBundle.attributes.bundle_type_description }}
              </td>
            </tr>
          </template>
          <template #tfoot="{ classes }">
            <tr v-if="!promo.data.attributes.is_active" :class="[classes.tr, 'cursor-pointer']" @click="onCreateBundle">
              <td :class="classes.td" colspan="2">
                <div class="flex items-center gap-x-2 font-medium text-gray-900">
                  <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
                  <span>Tambah Isi Promo</span>
                </div>
              </td>
            </tr>
          </template>
        </datatable>
      </base-card>
    </div>
    <product-bundle-form-modal :visible="productBundleFormModal.visible" :product-promo="promo" :product-bundle-id="productBundleFormModal.productBundleId" @close="productBundleFormModal.visible = false" @created="onCreatedBundle" />
    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { mapActions } from 'vuex';
import { requestMixin } from '@/mixins/request/request'
import ProductBundleFormModal from '@/components/promo/product-bundle/product-bundle-form-modal.vue'
import { formatDate, parseDate } from '@/utils/date';

export default {
  components: { ProductBundleFormModal },
  computed: {
    columns() {
      return [
        { id: 'name', name: 'Nama Bundle' },
        { id: 'type', name: 'Tipe Bundle' }
      ]
    },
    formParsed() {
      return {
        name: this.form.name,
        description: this.form.description,
        sc_start_at: parseDate(this.form.sc_start_at).format('YYYY-MM-DDTHH:mm:ss'),
        sc_finish_at: parseDate(this.form.sc_finish_at).format('YYYY-MM-DDTHH:mm:ss'),
        stockist_start_at: parseDate(this.form.stockist_start_at).format('YYYY-MM-DDTHH:mm:ss'),
        stockist_finish_at: parseDate(this.form.stockist_finish_at).format('YYYY-MM-DDTHH:mm:ss'),
      }
    }
  },
  data() {
    return {
      loading: false,
      form: {
        name: null,
        description: null,
        sc_start_at: null,
        sc_finish_at: null,
        stockist_start_at: null,
        stockist_finish_at: null
      },
      promo: {
        data: {
          id: null
        },
        included: []
      },
      productBundleFormModal: {
        visible: false,
        productBundleId: null
      }
    }
  },
  mixins: [requestMixin],
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert'
    }),
    async loadPromo(id) {
      this.loading = true

      const [res, error] = await this.request(`/api/v1/product-promos/${id}`, {
        params: {
          include: 'product-bundles'
        }
      })

      if (!error) {
        this.setPromo(res)
      }

      this.loading = false
    },
    onCreateBundle() {
      this.productBundleFormModal.visible = true
      this.productBundleFormModal.productBundleId = null
    },
    onCreatedBundle() {
      this.loadPromo(this.$route.params.id)
    },
    async onSave() {
      this.loading = true

      if (this.promo.data.id) {
        const [, error] = await this.request(`/api/v1/product-promos/${this.promo.data.id}`, {
          method: 'patch',
          data: {
            data: {
              type: 'product-promos',
              id: this.promo.data.id,
              attributes: this.formParsed
            }
          }
        })

        if (error) {
          this.createAlert({
            data: this.getRequestErrorMessage(error),
            status: 'error'
          })
        } else {
          this.createAlert({
            data: 'Promo berhasil disimpan',
            status: 'success'
          })
        }
      } else {
        const [res, error] = await this.request('/api/v1/product-promos', {
          method: 'post',
          data: {
            data: {
              type: 'product-promos',
              attributes: this.formParsed
            }
          }
        })

        if (error) {
          this.createAlert({
            data: this.getRequestErrorMessage(error),
            status: 'error'
          })
        } else {
          this.createAlert({
            data: 'Promo berhasil disimpan',
            status: 'success'
          })
          
          this.$router.push(res.data.id)

          this.setPromo(res)
        }
      }

      this.loading = false
    },
    onDetailProductBundle(productBundle) {
      this.productBundleFormModal.visible = true
      this.productBundleFormModal.productBundleId = productBundle.id
    },
    setPromo(promo) {
      this.form.name = promo.data.attributes.name
      this.form.description = promo.data.attributes.description
      this.form.sc_start_at = formatDate(promo.data.attributes.sc_start_at, 'YYYY-MM-DDTHH:mm')
      this.form.sc_finish_at = formatDate(promo.data.attributes.sc_finish_at, 'YYYY-MM-DDTHH:mm')
      this.form.stockist_start_at = formatDate(promo.data.attributes.stockist_start_at, 'YYYY-MM-DDTHH:mm')
      this.form.stockist_finish_at = formatDate(promo.data.attributes.stockist_finish_at, 'YYYY-MM-DDTHH:mm')

      this.promo = promo
    }
  },
  created() {
    if (this.$route.params.id) {
      this.loadPromo(this.$route.params.id)
    }
  }
}
</script>