<template>
  <base-qr-scan-modal
    :visible="showing"
    play-sound-on-scanned
    dynamic-qr-box
    :with-camera-scan="withCameraScan"
    @close="$emit('close')"
    @scanned="handleQrScaned"
  >
    <template #bottom>
      <div class="flex gap-x-4">
        <base-input
          type="text"
          placeholder="Kode Qr"
          fullwidth
          :shadow="false"
          v-model="code"
        />
        <base-button v-on:click="handleSave">Simpan</base-button>
      </div>
    </template>
  </base-qr-scan-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseQrScanModal from '@/components/base/BaseQrScanModal.vue';
import BaseInput from '@/components/base/BaseInput';
import BaseButton from '@/components/base/BaseButton';

export default {
  components: {
    BaseQrScanModal,
    BaseInput,
    BaseButton,
  },
  props: {
    showing: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    withCameraScan: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getProduct: 'products/getProduct',
    }),
  },
  emits: ['updated', 'close'],
  data() {
    return {
      code: null,
    };
  },
  watch: {
    showing(value) {
      if (value) {
        this.resetCode();
      }
    },
  },
  methods: {
    ...mapActions({
      updateProductWithParams: 'products/updateProductWithParams',
    }),
    resetCode() {
      this.code = this.getProduct.data.attributes.qrcode;
    },
    handleQrScaned(code) {
      this.code = code;
    },
    async handleSave() {
      const res = await this.updateProductWithParams({
        payload: {
          data: {
            id: this.product.data.id,
            type: 'products',
            attributes: {
              qrcode: this.code,
            },
          },
        },
        params: {
          'fields[product-prices]': 'price,area,buyer-type',
          include: 'product-prices',
        },
      });

      if (res) {
        this.$emit('close');
        this.$emit('updated', res.data);
      }
    },
    handleBarcodeScan(e) {
      if (this.showing) {
        this.code = e.detail.text
      }
    },
  },
  mounted() {
    document.addEventListener('barcode-scan', this.handleBarcodeScan)
  },
  destroyed() {
    document.removeEventListener('barcode-scan', this.handleBarcodeScan)
  }
};
</script>
