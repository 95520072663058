<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            placeholder="Cari Kode"
            v-model="search"
            v-on:input="onSearch"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getSimpleOffices.meta.page.total"
              :perPage="getSimpleOffices.meta.page.perPage"
              :currentPage="getSimpleOffices.meta.page.currentPage"
              @pagechanged="onPageChange"
              :meta="getSimpleOffices.meta"
              cursor
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Stockist Center
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Stockist Center
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No. Telp
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Alamat Stockist Center
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getSimpleOffices.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="onClickDetail(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ getOfficeIncluded(data).attributes.phone ?? '-' }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <div>
                        <p>
                          {{
                            getOfficeIncluded(data).attributes.address ?? '-'
                          }}
                        </p>
                        <p class="text-xs">
                          {{ getOfficeIncluded(data).attributes.email }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="getSimpleOfficeLoading || getOfficeLoading" />
    <office-detail-modal
      view-as="stockist-center"
      v-model="openDetail"
      @update="() => (openDetail = false)"
    >
      <div class="mb-4 px-6">
        <div class="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <div class="field-inset-default w-full">
              <label
                for="no_faktur"
                class="block text-xs font-bold text-gray-700"
              >
                No Depan Faktur
              </label>
              <div class="flex">
                <input
                  v-model="office.attributes.prefix_code"
                  type="text"
                  name="no_faktur"
                  id="no_faktur"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                />
                <button
                  @click="saveNoFaktur"
                  type="button"
                  class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </office-detail-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import BaseSearch from '@/components/base/Search.vue';
import OfficeDetailModal from '@/components/office/office-detail-modal.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
    OfficeDetailModal,
  },
  data() {
    return {
      search: null,
      openDetail: false,
      office: {
        attributes: {
          prefix_code: '',
        },
      },
    };
  },
  created() {
    this.loadStockistCenter();
    const handleEscape = () => {
      this.openDetail = false;
      this.search = '';
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getSimpleOfficeLoading: 'simple_offices/getLoading',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getOffice: 'offices/getOffice',
      getOfficeLoading: 'offices/getLoading',
    }),
  },
  methods: {
    ...mapActions({
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
      fetchUsersByOffice: 'users/fetchUsersByOffice',
      updateOffice: 'offices/updateOffice',
      updateUser: 'users/updateUser',
    }),
    async onPageChange(page) {
      await this.loadStockistCenter(page);
    },
    onSearch: debounce(function () {
      this.loadStockistCenter();
    }, 300),
    async onClickDetail(simpleOffice) {
      const office = this.getOfficeIncluded(simpleOffice);

      await Promise.all([
        this.fetchOfficeById({
          office_id: office.id,
          params: {
            include: 'addresses,area',
          },
        }),
        this.fetchUsersByOffice({
          officeId: office.id,
        }),
      ]);
      this.office.attributes.prefix_code =
        this.getOffice.data.attributes.prefix_code;
      console.log(
        this.office.attributes.prefix_code,
        this.getOffice.data.attributes.prefix_code
      );
      this.isLoading = false;
      this.openDetail = true;
    },
    async loadStockistCenter(params = {}) {
      await this.fetchSimpleOffices({
        ...params,
        'filter[office_category_id]': 3,
        'page[limit]': 5,
        include: 'office',
        'fields[simple-offices]': 'code,name,office',
        'fields[offices]': 'phone,address,email',
        'filter[search]': this.search,
      });
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.getSimpleOffices,
        simpleOffice.relationships.office.data.id
      );
    },
    onActiveUserChange(user) {
      const payload = {
        data: {
          type: 'users',
          id: user.id,
          attributes: {
            is_suspended: !user.attributes.is_suspended,
          },
        },
      };
      this.updateUser(payload);
    },
    onActiveChange() {
      const payload = {
        data: {
          type: 'offices',
          id: this.dataStockist.id,
          attributes: {
            is_suspended: !this.dataStockist.is_suspended,
          },
        },
      };
      this.updateOffice(payload).then(() => {
        this.fetchOffices({
          pageNumber: 1,
          pageSize: 5,
        });
      });
    },
    saveNoFaktur() {
      const payload = {
        data: {
          id: this.getOffice.data.id,
          type: 'offices',
          attributes: {
            prefix_order: this.office.attributes.prefix_order,
          },
        },
      };
      this.updateOffice(payload).then((res) => {
        this.$store.dispatch(
          'alert/createAlert',
          {
            data: res
              ? 'Berhasil mengubah nomor depan faktur'
              : 'No depan faktur gagal diubah ',
          },
          { root: true }
        );
      });
    },
  },
};
</script>
