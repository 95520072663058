<template>
  <Modal
    :showing="visible"
    @close="$emit('close')"
    :showClose="visible"
    :backgroundClose="true"
    size="max-w-6xl"
  >
    <div class="flex justify-between px-4 py-5 sm:px-6">
      <div>
        <h3 class="text-lg font-bold leading-6 text-gray-900">Daftar Barang</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Berdasarkan data barang yang telah dibuat
        </p>
      </div>
      <div>
        <img src="@/assets/images/logo.png" alt="" class="w-52" />
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div class="flex justify-end">
        <base-search
          placeholder="Cari Kode"
          v-model="search"
          @input="debounceSearchProduct"
        />
      </div>
      <div class="my-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Datatable
            :total="getProducts.meta?.page.total"
            :perPage="getProducts.meta?.page.perPage"
            :currentPage="getProducts.meta?.page.currentPage"
            @pagechanged="onPageChangeProducts"
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kode Barang
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Nama Barang
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kelompok Produk
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Unit
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody>
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getProducts.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="changeProduct(data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.name }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      getSingleIncludedType(
                        'product-category',
                        getProducts,
                        data
                      )?.attributes?.name ?? '-'
                    }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      getSingleIncludedType('unit', getProducts, data)
                        ?.attributes?.name ?? '-'
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <base-button @click="$emit('close')" type="button">
        Tutup
      </base-button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/base/BaseModal';
import Datatable from '@/components/base/Datatable';
import debounce from 'debounce';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'ProductModal',
  components: {
    Modal,
    Datatable,
    BaseSearch,
  },
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    ...mapGetters({
      getProducts: 'products/getProducts',
    }),
  },
  methods: {
    debounceSearchProduct: debounce(function () {
      this.$emit('search', this.search);
    }, 300),
    onPageChangeProducts(value) {
      this.$emit('page-change', value);
    },
    changeProduct(value) {
      this.$emit('change', value);
    },
    getRelationshipById(id) {
      let data = this.getProducts.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    getCategory(product) {
      return this.getSingleIncludedType(
        'product-category',
        this.getProducts,
        product
      );
    },
  },
};
</script>
