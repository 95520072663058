<template>
  <base-card title="KIT Barang">
    <template #action>
      <base-button size="sm" @click="onAddProduct">Tambah Barang</base-button>
    </template>
    <datatable :paginated="false" :columns="columns">
      <template v-slot:tbody="{ classes }">
        <tr
          v-for="(product) in requiredProducts.data"
          :key="product.id"
          :class="[
            classes.tr,
          ]"
        >
          <td :class="['whitespace-nowrap px-6 h-16 text-sm text-gray-500 font-normal']">
            <div class="flex items-center gap-x-2">
              <span class="font-bold text-gray-900">{{
                product.attributes.product_code
              }}</span>
              <icon
                v-if="product.attributes.is_active"
                class="h-5 w-5 text-blue-600"
                icon="heroicons:check-badge-20-solid"
              />
            </div>
          </td>
          <td :class="['whitespace-nowrap px-6 h-16 text-sm text-gray-500 font-normal']">
            <div class="space-x-2">
              <base-button size="sm" v-if="!product.attributes.is_active" @click="onSetDefault(product)">Jadikan Default</base-button>
              <base-button size="sm" color="danger" v-if="!product.attributes.is_active" @click="onDelete(product)">Hapus</base-button>
            </div>
          </td>
        </tr>
      </template>
    </datatable>

    <product-table-modal
      v-if="visibleProductModal"
      :visible="visibleProductModal"
      @close="visibleProductModal = false"
      v-on:click-item="onSelectProduct"
    />

    <loading v-if="loading || productLoading" />
  </base-card>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters, mapActions } from 'vuex';
import ProductTableModal from '@/components/product/ProductTableModal.vue';

export default {
  mixins: [requestMixin],
  components: {
    ProductTableModal,
  },
  data() {
    return {
      visibleProductModal: false,
      requiredProducts: {
        data: [],
        meta: {
          page: {
            total: 0,
            perPage: 10,
            currentPage: 1,
          },
        },
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      productLoading: 'products/getLoading',
    }),
    columns() {
      return [
        { id: 'code', name: 'Kode Barang' },
        { id: 'action', name: 'Aksi' }
      ]
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onAddProduct() {
      this.visibleProductModal = true;
    },
    async onSelectProduct(product) {
      this.loading = true

      const [, error] = await this.request('/api/v1/create-member-required-products', {
        method: 'post',
        data: {
          data: {
            type: 'create-member-required-products',
            attributes: {
              is_active: false
            },
            relationships: {
              product: {
                data: {
                  type: 'products',
                  id: product.id
                }
              }
            }
          }
        }
      });

      if (!error) {
        this.createAlert({
          data: 'Setup berhasil diperbarui',
          status: 'success',
        });

        await this.loadRequiredProducts()
      } else {
        this.createAlert({
          data: error.message,
          status: 'error',
        });

        this.loading = false
      }
    },
    async onSetDefault(product) {
      this.loading = true

      const [, error] = await this.request(`/api/v1/create-member-required-products/${product.id}`, {
        method: 'patch',
        data: {
          data: {
            type: 'create-member-required-products',
            id: `${product.id}`,
            attributes: {
              is_active: true
            }
          }
        }
      });

      if (!error) {
        this.createAlert({
          data: 'Setup berhasil diperbarui',
          status: 'success',
        });

        await this.loadRequiredProducts()
      } else {
        this.createAlert({
          data: error.message,
          status: 'error',
        });

        this.loading = false
      }
    },
    async onDelete(product) {
      this.loading = true

      const [, error] = await this.request(`/api/v1/create-member-required-products/${product.id}`, {
        method: 'delete',
      });

      if (!error) {
        this.createAlert({
          data: 'Setup berhasil diperbarui',
          status: 'success',
        });

        await this.loadRequiredProducts()
      } else {
        this.createAlert({
          data: error.message,
          status: 'error',
        });

        this.loading = false
      }
    },
    async loadRequiredProducts() {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/create-member-required-products');

      if (!error) {
        this.requiredProducts = res;
      }

      this.loading = false;
    },
  },
  created() {
    this.loadRequiredProducts();
  },
};
</script>
