<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Permission Menu {{ menu.data.attributes.name }}
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Daftar Permissions Menu {{ menu.data.attributes.name }} Yang Sudah
              Ditambahkan Ke Role {{ role.data.attributes.name }}
            </p>
          </div>
        </div>
      </div>
      <div class="mt-6 space-y-4 border-t border-gray-200 pt-6">
        <div :class="['flex items-center justify-end']">
          <div class="flex items-center gap-x-4">
            <div class="space-x-1">
              <base-button
                v-if="checkedPermissionIds.length !== permissions.data.length"
                size="sm"
                @click="onSave"
                >Simpan Perubahan</base-button
              >
              <base-button size="sm" @click="onAddPermission"
                >Tambah Permission</base-button
              >
            </div>
          </div>
        </div>
        <datatable
          :columns="columns"
          :scroll-x="false"
          :total="permissions.meta.page.total"
          :perPage="permissions.meta.page.perPage"
          :currentPage="permissions.meta.page.currentPage"
          @pagechanged="onPagechanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="permission in permissions.data"
              :key="permission.id"
              :class="[classes.tr, 'hover:bg-gray-50']"
            >
              <td :class="[classes.td]" style="width: 1px">
                <base-checkbox
                  :with-label="false"
                  :input-value="permission.id"
                  v-model="checkedPermissionIds"
                />
              </td>
              <td :class="[classes.td]">{{ permission.attributes.name }}</td>
              <td :class="[classes.td]">
                {{ permission.attributes.description }}
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';

export default {
  mixins: [requestMixin],
  components: { BaseModal, BaseCheckbox },
  props: {
    visible: Boolean,
    menuId: String,
    roleId: String,
    reload: Boolean,
  },
  emits: ['close', 'attach-permission'],
  data() {
    return {
      loading: false,
      permissions: {
        data: [],
        meta: {
          page: {},
        },
      },
      role: {
        data: {
          attributes: {
            name: null,
          },
        },
      },
      menu: {
        data: {
          attributes: {
            name: null,
          },
        },
      },
      checkedPermissionIds: [],
    };
  },
  computed: {
    columns() {
      return [
        { id: 'check', name: '' },
        { id: 'name', name: 'Nama' },
        { id: 'description', name: 'Deskripsi' },
      ];
    },
  },
  methods: {
    async onOpened() {
      if (this.reload) {
        this.loading = true;

        await Promise.all([
          this.loadRole(),
          this.loadMenu(),
          this.loadPermissions(),
        ]);

        this.loading = false;
      }
    },
    async loadRole() {
      const [res, error] = await this.request(`/api/v1/roles/${this.roleId}`, {
        params: {
          'fields[roles]': 'name',
        },
      });

      if (!error) {
        this.role = res;
      }

      return [res, error];
    },
    async loadMenu() {
      const [res, error] = await this.request(`/api/v1/menus/${this.menuId}`, {
        params: {
          'fields[menus]': 'name',
        },
      });

      if (!error) {
        this.menu = res;
      }

      return [res, error];
    },
    async loadPermissions(params) {
      const [res, error] = await this.request(
        `/api/v1/menus/${this.menuId}/permissions`,
        {
          params: {
            'page[size]': 5,
            'filter[owned_by_role_id]': this.roleId,
            ...params,
          },
        }
      );

      if (!error) {
        this.permissions = res;
        this.checkedPermissionIds = this.permissions.data.map(
          (permission) => permission.id
        );
      }

      return [res, error];
    },
    async onPagechanged(page) {
      this.loading = true;

      await this.loadPermissions({
        'page[number]': page,
      });

      this.loading = false;
    },
    onAddPermission() {
      this.$emit('attach-permission');
    },
    async onSave() {
      this.loading = true;

      await this.request(
        `/api/v1/roles/${this.roleId}/-actions/detach-permissions`,
        {
          method: 'post',
          data: {
            permission_uuids: this.permissions.data
              .filter(
                (permission) =>
                  !this.checkedPermissionIds.includes(permission.id)
              )
              .map((permission) => permission.id),
          },
        }
      );

      await this.loadPermissions();

      this.loading = false;
    },
  },
};
</script>
