<template>
  <div class="mb-5 rounded-lg bg-white py-4">
    <div class="space-y-4 divide-y px-4">
      <div class="py-2">
        <div class="mt-2 flex flex-col">
          <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <div
                class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="bg-green-500 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-green-100"
                      ></th>
                      <th
                        scope="col"
                        class="bg-green-500 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-green-100"
                      >
                        Harga Stockist
                      </th>
                      <th
                        scope="col"
                        class="bg-green-500 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-green-100"
                      >
                        Harga Mitra Usaha
                      </th>
                      <th
                        scope="col"
                        class="bg-green-500 px-4 py-3 text-left text-xs font-bold uppercase tracking-wider text-green-100"
                      >
                        Harga Konsumen
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm font-bold text-gray-900"
                      >
                        JAWA (0)
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <base-input
                          currency
                          v-model="value.harga_wil0_stockist"
                          type="text"
                          name="harga_wil0_stockist"
                          id="harga_wil0_stockist"
                          fullwidth
                          placeholder="Masukkan Harga Stockist"
                          :invalid="errors.harga_wil0_stockist"
                          :message="errors.harga_wil0_stockist"
                        />
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            v-model="value.harga_wil0_distributor"
                            :invalid="errors.harga_wil0_distributor"
                            :message="errors.harga_wil0_distributor"
                            type="text"
                            fullwidth
                            name="harga_wil0_distributor"
                            id="harga_wil0_distributor"
                            placeholder="Masukkan Harga Mitra Usaha"
                          />
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil0_konsumen"
                            :invalid="errors.harga_wil0_konsumen"
                            :message="errors.harga_wil0_konsumen"
                            type="text"
                            name="harga_wil0_konsumen"
                            id="harga_wil0_konsumen"
                            placeholder="Masukkan Harga Konsumen"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm font-bold text-gray-900"
                      >
                        Wilayah 1
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil1_stockist"
                            :invalid="errors.harga_wil1_stockist"
                            :message="errors.harga_wil1_stockist"
                            type="text"
                            name="harga_wil1_stockist"
                            id="harga_wil1_stockist"
                            placeholder="Masukkan Harga Stockist"
                          />
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil1_distributor"
                            :invalid="errors.harga_wil1_distributor"
                            :message="errors.harga_wil1_distributor"
                            type="text"
                            name="harga_wil1_distributor"
                            id="harga_wil1_distributor"
                            placeholder="Masukkan Harga Mitra Usaha"
                          />
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil1_konsumen"
                            :invalid="errors.harga_wil1_konsumen"
                            :message="errors.harga_wil1_konsumen"
                            type="text"
                            name="harga_wil1_konsumen"
                            id="harga_wil1_konsumen"
                            placeholder="Masukkan Harga Konsumen"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm font-bold text-gray-900"
                      >
                        Wilayah 2
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil2_stockist"
                            :invalid="errors.harga_wil2_stockist"
                            :message="errors.harga_wil2_stockist"
                            type="text"
                            name="harga_wil2_stockist"
                            id="harga_wil2_stockist"
                            placeholder="Masukkan Harga Stockist"
                          />
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil2_distributor"
                            :invalid="errors.harga_wil2_distributor"
                            :message="errors.harga_wil2_distributor"
                            type="text"
                            name="harga_wil2_distributor"
                            id="harga_wil2_distributor"
                            placeholder="Masukkan Harga Mitra Usaha"
                          />
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil2_konsumen"
                            :invalid="errors.harga_wil2_konsumen"
                            :message="errors.harga_wil2_konsumen"
                            type="text"
                            name="harga_wil2_konsumen"
                            id="harga_wil2_konsumen"
                            placeholder="Masukkan Harga Konsumen"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm font-bold text-gray-900"
                      >
                        Wilayah 3
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil3_stockist"
                            :invalid="errors.harga_wil3_stockist"
                            :message="errors.harga_wil3_stockist"
                            type="text"
                            name="harga_wil3_stockist"
                            id="harga_wil3_stockist"
                            placeholder="Masukkan Harga Stockist"
                          />
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil3_distributor"
                            :invalid="errors.harga_wil3_distributor"
                            :message="errors.harga_wil3_distributor"
                            type="text"
                            name="harga_wil3_distributor"
                            id="harga_wil3_distributor"
                            placeholder="Masukkan Harga Mitra Usaha"
                          />
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil3_konsumen"
                            :invalid="errors.harga_wil3_konsumen"
                            :message="errors.harga_wil3_konsumen"
                            type="text"
                            name="harga_wil3_konsumen"
                            id="harga_wil3_konsumen"
                            placeholder="Masukkan Harga Konsumen"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm font-bold text-gray-900"
                      >
                        Wilayah 4
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil4_stockist"
                            :invalid="errors.harga_wil4_stockist"
                            :message="errors.harga_wil4_stockist"
                            type="text"
                            name="harga_wil4_stockist"
                            id="harga_wil4_stockist"
                            placeholder="Masukkan Harga Stockist"
                          />
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil4_distributor"
                            :invalid="errors.harga_wil4_distributor"
                            :message="errors.harga_wil4_distributor"
                            type="text"
                            name="harga_wil4_distributor"
                            id="harga_wil4_distributor"
                            placeholder="Masukkan Harga Mitra Usaha"
                          />
                        </div>
                      </td>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_wil4_konsumen"
                            :invalid="errors.harga_wil4_konsumen"
                            :message="errors.harga_wil4_konsumen"
                            type="text"
                            name="harga_wil4_konsumen"
                            id="harga_wil4_konsumen"
                            placeholder="Masukkan Harga Konsumen"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm font-bold text-gray-900"
                      >
                        KARYAWAN
                      </td>
                      <td
                        colspan="3"
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_karyawan"
                            :invalid="errors.harga_karyawan"
                            :message="errors.harga_karyawan"
                            type="text"
                            name="harga_perusahaan"
                            id="harga_perusahaan"
                            placeholder="Masukkan Harga Perusahaan"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm font-bold text-gray-900"
                      >
                        KHUSUS
                      </td>
                      <td
                        colspan="3"
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_retur"
                            :invalid="errors.harga_retur"
                            :message="errors.harga_retur"
                            type="text"
                            name="harga_khusus"
                            id="harga_khusus"
                            placeholder="Masukkan Harga Retur"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-4 py-4 text-sm font-bold text-gray-900"
                      >
                        FREE
                      </td>
                      <td
                        colspan="3"
                        class="whitespace-nowrap px-4 py-4 text-sm text-gray-500"
                      >
                        <div>
                          <base-input
                            currency
                            fullwidth
                            v-model="value.harga_perusahaan"
                            :invalid="errors.harga_perusahaan"
                            :message="errors.harga_perusahaan"
                            type="text"
                            name="harga_perusahaan"
                            id="harga_perusahaan"
                            placeholder="Masukkan Harga Free"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@/components/base/BaseInput.vue';

export default {
  name: 'ProductPriceForm',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
};
</script>
