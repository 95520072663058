<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            placeholder="Cari Kode"
            v-model="search"
            v-on:input="debounceSearch"
          />
          <div>
            <base-button id="add-supplier" @click="openAddBank" type="button">
              <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
              Tambah Bank
            </base-button>
          </div>
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getBanks.meta.page.total"
              :perPage="getBanks.meta.page.perPage"
              :currentPage="getBanks.meta.page.currentPage"
              @pagechanged="onPageChangeProducts"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Sandi Bank
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Swift Code
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Bank
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Alias
                    </th>
                    <th
                      scope="col"
                      class="w-1/12 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    ></th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="parent bg-white hover:bg-gray-50"
                    v-for="(data, dataIdx) in getBanks.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td
                      @click="detail(data)"
                      class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.swiftcode }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name ?? '-' }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.alias_name ?? '-' }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <div class="flex space-x-2">
                        <base-button
                          for-icon
                          class="child text-yellow-400 hover:text-yellow-500"
                          @click="editBank(data)"
                          type="button"
                        >
                          <Icon
                            icon="heroicons:pencil-square-20-solid"
                            class="h-5 w-5"
                          />
                        </base-button>
                        <base-button
                          for-icon
                          class="child text-red-400 hover:text-red-600"
                          @click="removeBank(data)"
                          type="button"
                        >
                          <Icon
                            icon="heroicons:trash-20-solid"
                            class="h-5 w-5"
                          />
                        </base-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>

        <Modal
          :showing="modal === 'add'"
          @close="closeModal"
          :showClose="modal === 'add'"
          :backgroundClose="true"
          size="max-w-4xl"
        >
          <base-form
            @submit="addBank"
            id="modal-add"
            :form="dataBank"
            v-slot="{ errors }"
          >
            <div>
              <div
                class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
              >
                <div>
                  <h3 class="text-lg font-semibold leading-6 text-gray-900">
                    {{ dataBank.id ? 'Edit Bank' : 'Tambah Bank' }}
                  </h3>
                </div>
              </div>
              <div class="py-2 px-6">
                <div class="flex mt-4 sm:flex-row sm:gap-x-4">
                  <base-input
                    type="text"
                    label="Sandi Bank"
                    id="code"
                    placeholder="ex:002"
                    inset
                    with-label
                    fullwidth
                    :invalid="errors.code"
                    :message="errors.code"
                    v-model="dataBank.code"
                  />
                  <base-input
                    type="text"
                    label="Swift Code"
                    id="swiftcode"
                    placeholder="ex: BRINIDJA"
                    inset
                    with-label
                    fullwidth
                    :invalid="errors.swiftcode"
                    :message="errors.swiftcode"
                    v-model="dataBank.swiftcode"
                  />
                </div>
                <div class="flex mt-4 sm:flex-row sm:gap-x-4">
                  <base-input
                    type="text"
                    label="Alias"
                    id="alias"
                    placeholder="ex: PT. BANK RAKYAT INDONESIA (PERSERO)"
                    inset
                    with-label
                    fullwidth
                    :invalid="errors.alias"
                    :message="errors.alias"
                    v-model="dataBank.alias"
                  />
                  <base-input
                    type="text"
                    label="Nama Bank"
                    id="name"
                    placeholder="ex: PT. BANK RAKYAT INDONESIA (PERSERO)"
                    inset
                    with-label
                    fullwidth
                    :invalid="errors.name"
                    :message="errors.name"
                    v-model="dataBank.name"
                  />
                </div>
              </div>
            </div>
            <div class="pt-5">
              <div class="flex justify-end">
                <button
                  type="submit"
                  id="simpan_supplier"
                  class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
                >
                  Simpan
                </button>
                <button
                  @click="clearData"
                  id="batal_supplier"
                  type="button"
                  class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                >
                  Batal
                </button>
                <button
                  @click="closeModal"
                  id="selesai_supplier"
                  class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
                >
                  Selesai
                </button>
              </div>
            </div>
          </base-form>
        </Modal>

        <Modal
          :showing="modal === 'detail'"
          @close="closeModal"
          :showClose="modal === 'detail'"
          :backgroundClose="true"
          size="max-w-4xl"
        >
          <template v-if="dataBank.attributes">
            <div class="flex justify-between px-4 py-5 sm:px-6">
              <div>
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Detail Bank
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Berdasarkan data bank yang telah dibuat
                </p>
              </div>
              <div>
                <img src="@/assets/images/logo.png" alt="" class="w-52" />
              </div>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl
                class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6"
              >
                <div class="sm:col-span-3">
                  <dt class="text-sm font-bold text-gray-700">Sandi Bank</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataBank.attributes.code }}
                  </dd>
                </div>
                <div class="sm:col-span-3">
                  <dt class="text-sm font-bold text-gray-700">Swift Code</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataBank.attributes.name }}
                  </dd>
                </div>
                <div class="sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">Nama Bank</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataBank.attributes.name }}
                  </dd>
                </div>
                <div class="sm:col-span-3">
                  <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataBank.attributes.createdAt | formatDate }}
                  </dd>
                </div>
                <div class="sm:col-span-3">
                  <dt class="text-sm font-bold text-gray-700">
                    Terakhir Diperbarui
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataBank.attributes.updatedAt | formatDate }}
                  </dd>
                </div>
              </dl>
            </div>
            <div class="flex mx-8 justify-end">
              <button
                @click="modal = null"
                type="button"
                class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Tutup
              </button>
            </div>
          </template>
        </Modal>

        <transition name="fade">
          <div
            v-if="success"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            id="modal_success"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
                  >
                    <svg
                      class="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading"> Data Berhasil disimpan </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <button
                      @click.prevent="addMoreBank"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Tambah Lagi
                    </button>
                    <button
                      @click="closeModal"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="failed"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            id="modal_error"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-red-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading">
                      {{ getError }}
                    </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <base-button fullwidth @click.prevent="addMoreBank">
                      Ulangi
                    </base-button>
                    <base-button color="white" fullwidth @click="closeModal">
                      Selesai
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <loading v-if="getLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/base/BaseModal';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseForm from '@/components/base/BaseForm.vue';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: 'Users',
  components: {
    Datatable,
    Modal,
    BaseForm,
    BaseInput,
    BaseSearch,
    BaseButton,
  },
  data() {
    return {
      search: null,
      success: false,
      failed: false,
      modal: null,
      dataBank: {
        code: null,
        swiftcode: null,
        name: null,
        alias: null,
      },
    };
  },
  created() {
    this.fetchBanks({
      'page[number]': 1,
      'page[size]': 5,
    });
  },
  computed: {
    ...mapGetters({
      getBanks: 'banks/getBanks',
      getError: 'banks/getError',
      getLoading: 'banks/getLoading',
    }),
  },
  methods: {
    ...mapActions({
      fetchBanks: 'banks/fetchBanks',
      createBank: 'banks/createBank',
      updateBank: 'banks/updateBank',
    }),
    openModal(type) {
      this.modal = type;
    },
    closeModal() {
      this.success = this.failed = false;
      this.clearData();
      this.modal = null;
      this.fetchBanks({
        'page[number]': 1,
        'page[size]': 5,
      });
    },
    addMoreBank() {
      this.success = this.failed = false;
      this.clearData();
      this.fetchBanks({
        'page[number]': 1,
        'page[size]': 5,
      });
    },
    openAddBank() {
      this.modal = 'add';
      this.clearData();
    },
    debounceSearch: debounce(function () {
      this.fetchBanks({
        'page[number]': 1,
        'page[size]': 5,
        search: this.search,
      });
    }, 300),
    onPageChangeProducts(page) {
      this.fetchBanks({
        'page[number]': page,
        'page[size]': 5,
        search: this.search,
      });
    },
    addService() {
      if (this.dataBank.service) {
        this.dataBank.services.push(this.dataBank.service);
      }
    },
    addBank() {
      const payload = {
        data: {
          type: 'banks',
          attributes: {
            code: this.dataBank.code,
            swiftcode: this.dataBank.swiftcode,
            alias_name: this.dataBank.alias,
            name: this.dataBank.name,
          },
        },
      };
      if (this.dataBank.id) {
        payload.data.id = this.dataBank.id;
        this.updateBank(payload).then((response) => {
          if (response) {
            this.success = true;
          } else {
            this.failed = true;
          }
        });
      } else {
        this.createBank(payload).then((response) => {
          if (response) {
            this.success = true;
          } else {
            this.failed = true;
          }
        });
      }
    },
    detail(data) {
      this.dataBank = data;
      this.modal = 'detail';
    },
    editBank(data) {
      this.modal = 'add';
      this.dataBank.id = data.id;
      this.dataBank.code = data.attributes.code;
      this.dataBank.swiftcode = data.attributes.swiftcode;
      this.dataBank.name = data.attributes.name;
      this.dataBank.alias = data.attributes.alias_name;
    },
    removeBank(data) {
      const payload = {
        data: {
          type: 'banks',
          id: data.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };
      this.updateBank(payload).then((response) => {
        if (response) {
          this.fetchBanks({
            'page[number]': 1,
            'page[size]': 5,
          });
        } else {
          this.failed = true;
        }
      });
    },
    clearData() {
      this.dataBank = {
        code: null,
        swiftcode: null,
        name: null,
        alias: null,
      };
    },
  },
};
</script>
