<template>
  <div class="mb-5 rounded-lg bg-white py-4">
    <div class="space-y-4 divide-y px-4">
      <div class="py-2">
        <div class="mt-4 flex sm:flex-row sm:gap-x-4">
          <base-input
            type="text"
            label="Nomor Izin"
            placeholder="ex: 2022/bpom/vii"
            id="nomor_izin"
            with-label
            inset
            fullwidth
            :invalid="errors.nomor_izin"
            :message="errors.nomor_izin"
            v-model="value.nomor_izin"
          />
        </div>

        <div class="mt-4 flex sm:flex-row sm:gap-x-4">
          <base-input
            type="text"
            label="Dinas/Lembaga Izin"
            placeholder="ex: BPOM, Kementan"
            id="dinas"
            with-label
            inset
            fullwidth
            :invalid="errors.dinas"
            :message="errors.dinas"
            v-model="value.dinas"
          />
        </div>

        <div class="mt-4 flex sm:flex-row sm:gap-x-4">
          <base-input
            type="date"
            label="Mulai Izin"
            id="mulai_izin"
            with-label
            inset
            fullwidth
            :invalid="errors.mulai_izin"
            :message="errors.mulai_izin"
            v-model="value.mulai_izin"
          />
          <base-input
            type="date"
            label="Akhir Izin (Perkiraan)"
            id="akhir_izin"
            with-label
            inset
            fullwidth
            :invalid="errors.akhir_izin"
            :message="errors.akhir_izin"
            v-model="value.akhir_izin"
          />
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-12">
            <label for="status_izin" class="text-xs font-bold text-gray-700">
              Status Izin
            </label>
            <div class="my-3 text-sm text-gray-700">
              <input
                v-model="value.status_izin"
                type="radio"
                name="status_izin_lengkap"
                id="status_izin_lengkap"
                :value="true"
                class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              <label for="status_izin_lengkap"> Lengkap </label>
            </div>
            <div class="text-sm text-gray-700">
              <input
                v-model="value.status_izin"
                type="radio"
                name="status_izin_proses"
                id="status_izin_proses"
                :value="false"
                class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              <label for="status_izin_proses"> Sedang proses </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@/components/base/BaseInput.vue';

export default {
  name: 'ProductCertificationForm',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
};
</script>
