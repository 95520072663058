<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search v-model="search" v-on:input="debounceSearch" />
          <div>
            <base-button
              id="add-supplier"
              @click="openAddExpedition"
              type="button"
            >
              <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
              Tambah Ekspedisi
            </base-button>
          </div>
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <ExpeditionTable
              @click-row="detail"
              @click-edit="editExpedition"
              @page-change="onPageChange"
            />
          </div>
        </div>
      </div>
    </div>
    <loading v-if="getLoading || loading" />

    <CreateExpeditionModal
      v-if="modal == 'add'"
      :value="modal == 'add'"
      @close="() => (modal = null)"
    />

    <DetailExpeditionModal
      :value="dataExpedition"
      :modal-type="modal"
      @close="() => (modal = null)"
    />
    <!-- Modal kantor -->
    <KantorModal
      :openListKantor="openListKantor"
      @change-office="changeOffice"
      @onPageChangeKantor="onPageChangeKantor"
      @onSearchKantor="onSearchKantor"
      @closeKantorModal="closeKantorModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { debounce } from 'debounce';
import { StorageService } from '@/services/storage.service';
import KantorModal from '@/components/pembelian/KantorModal.vue';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import CreateExpeditionModal from '@/components/admin/expedition/create-expedition-modal.vue';
import DetailExpeditionModal from '@/components/admin/expedition/detail-expedition-modal.vue';
import ExpeditionTable from '@/components/expedition/ExpeditionTable.vue';

export default {
  name: 'Ekspedisi',
  components: {
    KantorModal,
    BaseSearch,
    BaseButton,
    CreateExpeditionModal,
    DetailExpeditionModal,
    ExpeditionTable,
  },
  data() {
    return {
      loading: false,
      search: null,
      openListKantor: false,
      success: false,
      failed: false,
      modal: null,
      page: {
        number: 1,
        size: 5,
      },
      dataExpedition: {
        code: null,
        name: null,
        description: null,
        service: null,
        services: [],
        officeCategory: {
          id: '',
          name: '',
        },
        office: {
          id: '',
          attributes: {
            code: '',
          },
        },
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
          area: '',
        },
      },
    };
  },
  created() {
    this.fetchExpeditions({
      params: this.expeditionParams,
    });
    this.fetchProvinces();
  },
  computed: {
    ...mapGetters({
      getExpeditions: 'expeditions/getExpeditions',
      getError: 'expeditions/getError',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getLoading: 'indonesia/getLoading',
      getOfficeExpeditions: 'office_expeditions/getOfficeExpeditions',
    }),
    expeditionParams() {
      return {
        'page[number]': this.page.number,
        'page[size]': this.page.size,
        'filter[search]': this.search, // todo: search blm working di BE
      };
    },
  },
  methods: {
    ...mapMutations({
      setExpedition: 'expeditions/setExpedition',
    }),
    ...mapActions({
      fetchOfficeExpeditionsByOffice:
        'office_expeditions/fetchOfficeExpeditionsByOffice',
      fetchOfficeExpeditions: 'office_expeditions/fetchOfficeExpeditions',
      fetchExpeditions: 'expeditions/fetchExpeditions',
      fetchExpedition: 'expeditions/fetchExpedition',
      fetchExpeditionDetailsByExpedition:
        'expedition_details/fetchExpeditionDetailsByExpedition',
      createExpedition: 'expeditions/createExpedition',
      updateExpedition: 'expeditions/updateExpedition',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchOfficesByOfficeCategory:
        'office_categories/fetchOfficesByOfficeCategory',
      fetchVillages: 'indonesia/fetchVillages',
    }),
    openModal(type) {
      this.modal = type;
    },
    closeModal() {
      this.success = this.failed = false;
      this.clearData();
      this.modal = null;
      this.fetchOfficeExpeditionsByOffice({
        params: this.expeditionParams,
        office_id: StorageService.getUser().office_id,
      });
    },
    changeOffice(data) {
      this.dataExpedition.office = data;
      this.openListKantor = !this.openListKantor;
    },
    closeKantorModal() {
      this.openListKantor = !this.openListKantor;
    },
    onPageChangeKantor(page) {
      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataExpedition.officeCategory.id,
        pageNumber: page,
        pageSize: 5,
      });
    },

    onSearchKantor(search) {
      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataExpedition.officeCategory.id,
        pageNumber: 1,
        pageSize: 5,
        search: search,
      });
    },
    openModalKantor() {
      this.openListKantor = true;

      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataExpedition.officeCategory.id,
        pageNumber: 1,
        pageSize: 5,
      });
    },
    addMoreExpedition() {
      this.success = this.failed = false;
      this.clearData();
      this.fetchOfficeExpeditionsByOffice({
        params: this.expeditionParams,
        office_id: StorageService.getUser().office_id,
      });
    },
    openAddExpedition() {
      this.setExpedition({});
      this.modal = 'add';
      this.clearData();
    },
    debounceSearch: debounce(function () {
      this.fetchOfficeExpeditionsByOffice({
        params: this.expeditionParams,
        office_id: StorageService.getUser().office_id,
      });
    }, 300),
    onPageChange(page) {
      this.page.number = page;
      this.fetchExpeditions({
        params: this.expeditionParams,
      });
    },
    addService() {
      if (this.dataExpedition.service) {
        this.dataExpedition.services.push(this.dataExpedition.service);
      }
    },
    async detail(data) {
      this.loading = true;
      const expedition = this.fetchExpedition({
        id: data.id,
        // params: {
        //   include: 'expedition-details'
        // }
      });
      const expeditionDetails = this.fetchExpeditionDetailsByExpedition({
        id: data.id,
        params: {
          'page[size]': 5,
          'page[number]': 1,
        },
      });
      await Promise.all([expedition, expeditionDetails]);
      this.dataExpedition = data;
      this.modal = 'detail';
      this.loading = false;
    },
    async editExpedition(data) {
      await this.fetchExpedition({
        id: data.id,
      });
      this.modal = 'add';
    },
    removeExpedition(data) {
      const payload = {
        data: {
          type: 'expeditions',
          id: data.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };
      this.updateExpedition(payload).then(() => {
        this.fetchExpeditions({
          params: this.expeditionParams,
        });
      });
    },
    searchProvince: debounce(function (value) {
      this.fetchProvinces({
        pageNumber: 1,
        search: value,
      });
    }, 300),
    changeProvince() {
      this.fetchCities({
        province_id: this.dataExpedition.provinsi.id,
        pageNumber: 1,
      });
      this.dataExpedition.kota =
        this.dataExpedition.kecamatan =
        this.dataExpedition.desa =
          {
            id: '',
            name: '',
            area: '',
          };
    },
    changeCity() {
      this.fetchDistricts({
        city_id: this.dataExpedition.kota.id,
        pageNumber: 1,
      });
      this.dataExpedition.kecamatan = this.dataExpedition.desa = {
        id: '',
        name: '',
      };
    },
    searchCity: debounce(function (value) {
      this.fetchCities({
        province_id: this.dataExpedition.province.id,
        pageNumber: 1,
        search: value,
      });
    }, 300),
    getRelationships(resource, id) {
      return resource.included.find((el) => el.id === id);
    },
    clearData() {
      this.dataExpedition = {
        code: null,
        name: null,
        description: null,
        service: null,
        services: [],
        address_1: null,
        address_2: null,
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
          area: '',
        },
        officeCategory: {
          id: '',
          name: '',
        },
        office: {
          id: '',
          attributes: {
            code: '',
          },
        },
      };
    },
  },
};
</script>
