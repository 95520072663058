<template>
  <Datatable
    :total="getSimpleOffices.meta.page.total"
    :perPage="getSimpleOffices.meta.page.perPage"
    :currentPage="getSimpleOffices.meta.page.currentPage"
    @pagechanged="onPageChange"
    :meta="getSimpleOffices.meta"
    :is-empty="!getSimpleOffices.data?.length"
    :columns="columns"
    cursor
  >
    <template v-slot:tbody>
      <tbody>
        <tr
          class="cursor-pointer bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getSimpleOffices.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          @click.stop="onClickRow(data)"
        >
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-700">
            <div>
              <p class="font-bold">
                {{ data.attributes.code }}
              </p>
              <p class="text-xs">
                {{ data.attributes.name }}
              </p>
            </div>
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{
              getSingleIncluded(
                getSimpleOffices,
                data.relationships.office.data.id
              ).attributes.pulau ?? '-'
            }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{
              getSingleIncluded(
                getSimpleOffices,
                data.relationships.office.data.id
              ).attributes.phone ?? '-'
            }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            <div>
              <p>
                {{
                  getSingleIncluded(
                    getSimpleOffices,
                    data.relationships.office.data.id
                  ).attributes.address ?? '-'
                }}
              </p>
              <p class="text-xs">
                {{
                  getSingleIncluded(
                    getSimpleOffices,
                    data.relationships.office.data.id
                  ).attributes.email ?? '-'
                }}
              </p>
            </div>
          </td>
          <td
            v-if="withPromote"
            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
          >
            <button
              v-if="
                !getSingleIncluded(
                  getSimpleOffices,
                  data.relationships.office.data.id
                ).attributes.is_promoted
              "
              @click.stop="onOpenPromote(data)"
              class="rounded-xl bg-green-100 px-4 py-1 text-sm text-green-800"
            >
              Promosi ke SC
            </button>
            <button
              v-else
              class="rounded-xl bg-blue-100 px-4 py-1 text-sm text-blue-800"
            >
              {{
                getSingleIncluded(
                  getSimpleOffices,
                  data.relationships.office.data.id
                ).attributes.promoted_by
              }}
            </button>
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StockistTable',
  props: {
    withPromote: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getSimpleOfficesLoading: 'simple_offices/getLoading',
      getOfficesLoading: 'offices/getLoading',
    }),
    columns() {
      let col = [
        {
          name: 'Kode Stockist',
        },
        {
          name: 'Wilayah Harga',
        },
        {
          name: 'No. Telp',
        },
        {
          name: 'Alamat Stockist',
        },
      ];
      if (this.withPromote) {
        col.push({
          name: 'Status',
        });
      }
      return col;
    },
  },
  components: {
    // Your child components here
  },
  methods: {
    onClickRow(val) {
      this.$emit('click-row', val);
    },
    onPageChange(val) {
      this.$emit('page-change', val);
    },
    onOpenPromote(val) {
      this.$emit('promote', val);
    },
  },
  created() {},
};
</script>

<style scoped>
/* Your component styles here */
</style>
