<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            placeholder="Cari Kode"
            v-model="filter.search"
            v-on:input="debounceSearch"
            :withThreeLetterValidation="false"
          />
          <div>
            <base-button
              @click="
                modalType = 'add';
                clearData();
              "
              id="add-education"
              type="button"
            >
              <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
              Tambah Pendidikan
            </base-button>
          </div>
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getEducations.meta?.page.total"
              :perPage="getEducations.meta?.page.perPage"
              :currentPage="getEducations.meta?.page.currentPage"
              @pagechanged="pageChanged"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Jenjang Pendidikan
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Deskripsi
                    </th>
                    <th
                      scope="col"
                      class="w-10 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Aksi
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getEducations.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td
                      @click="modalType = 'detail'"
                      class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.description }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <div class="flex space-x-2">
                        <button @click="openEditModal(data)" type="button">
                          <Icon
                            icon="heroicons:pencil-square"
                            class="h-5 w-5 text-yellow-400 hover:text-yellow-600"
                          />
                        </button>
                        <button @click="deleteEducation(data)" type="button">
                          <Icon
                            icon="heroicons:trash"
                            class="h-5 w-5 text-red-400 hover:text-red-600"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>

        <Modal
          :showing="modalType === 'add' || modalType === 'edit'"
          @close="onCloseModal"
          :showClose="modalType === 'add' || modalType === 'edit'"
          :backgroundClose="true"
          size="max-w-4xl"
        >
          <form @submit.prevent="addEducation" id="modal-add">
            <div>
              <div
                class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
              >
                <div>
                  <h3 class="text-lg font-semibold leading-6 text-gray-900">
                    {{
                      modalType == 'edit'
                        ? 'Edit Pendidikan'
                        : 'Tambah Pendidikan'
                    }}
                  </h3>
                </div>
              </div>
              <div class="py-2 px-6">
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="field-inset-default w-full">
                    <label
                      for="nama_jenjang"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Nama Pendidikan
                    </label>
                    <input
                      v-model="education.attributes.name"
                      type="text"
                      name="nama_jenjang"
                      id="nama_jenjang"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="ex:S1"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="deskripsi"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Deskripsi
                    </label>
                    <input
                      v-model="education.attributes.description"
                      @keypress.enter.prevent
                      type="text"
                      name="deskripsi"
                      id="deskripsi"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="ex: Sarjana"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-5">
              <div class="flex justify-end">
                <button
                  type="submit"
                  id="simpan"
                  class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
                >
                  Simpan
                </button>
                <button
                  @click="onCloseModal"
                  id="selesai"
                  class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
                >
                  Selesai
                </button>
              </div>
            </div>
          </form>
        </Modal>

        <!-- Error Modal -->
        <BaseErrorModal :message="getError" v-model="visibleErrorModal" />
      </div>
    </div>
    <loading v-if="getLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/base/BaseModal';
import BaseErrorModal from '@/components/base/BaseErrorModal';
import { education } from '@/store/modules/educations.module';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: 'Education',
  components: {
    Datatable,
    Modal,
    BaseErrorModal,
    BaseSearch,
    BaseButton,
  },
  data() {
    return {
      education: education,
      educations: {
        data: [education],
      },
      filter: {
        search: null,
        currentPage: 1,
      },
      modalType: null,
      visibleErrorModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getEducations: 'educations/getEducations',
      getLoading: 'educations/getLoading',
      getError: 'educations/getError',
    }),
    educationsParams() {
      return {
        'page[number]': this.filter.currentPage,
        'filter[search]': this.filter.search ? this.filter.search : null,
        'page[size]': 5,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchEducations: 'educations/fetchEducations',
      createEducation: 'educations/createEducation',
      updateEducation: 'educations/updateEducation',
      patchEducation: 'educations/updateEducation',
    }),
    debounceSearch: function () {
      this.fetchEducations({
        params: {
          ...this.educationsParams,
        },
      });
    },
    pageChanged(page) {
      this.filter.currentPage = page;
      this.fetchEducations({
        params: {
          ...this.educationsParams,
        },
      });
    },
    async addEducation() {
      try {
        const payload = {
          data: {
            id: this.education.id ? this.education.id : undefined,
            type: 'educations',
            attributes: {
              name: this.education.attributes.name,
              description: this.education.attributes.description,
            },
          },
        };
        if (this.modalType == 'add') {
          if (!(await this.createEducation({ payload }))) {
            throw null;
          }
        } else if (this.modalType == 'edit') {
          if (!(await this.patchEducation({ payload }))) {
            throw null;
          }
        }
        this.modalType = null;
        this.fetchEducations({
          params: {
            ...this.educationsParams,
          },
        });
      } catch (err) {
        this.visibleErrorModal = true;
      }
    },
    async deleteEducation(education) {
      const payload = {
        data: {
          type: 'educations',
          id: education.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };
      await this.patchEducation({ payload });
      this.fetchEducations({
        params: {
          ...this.educationsParams,
        },
      });
    },
    clearData() {
      this.education = JSON.parse(JSON.stringify(education));
    },
    onCloseModal() {
      this.modalType = null;
      this.education = education;
    },
    openEditModal(data) {
      this.modalType = 'edit';
      // jika ditulis `this.education = data;` akan menyebabkan bug krn pass by reference
      this.education = JSON.parse(JSON.stringify(data));
    },
  },
  created() {
    this.fetchEducations({
      params: {
        ...this.educationsParams,
      },
    });
  },
};
</script>
