<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            placeholder="Cari Kode"
            v-model="filter.search"
            v-on:input="debounceSearch"
          />
          <div>
            <base-button
              @click="
                modalType = 'add';
                clearData();
              "
              id="add-profession"
              type="button"
            >
              <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
              Tambah Pekerjaan
            </base-button>
          </div>
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getProfessions.meta.page.total"
              :perPage="getProfessions.meta.page.perPage"
              :currentPage="getProfessions.meta.page.currentPage"
              @pagechanged="pageChanged"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Pekerjaan
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Deskripsi
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Aksi
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getProfessions.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td
                      @click="modalType = 'detail'"
                      class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.description }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <div class="flex space-x-2">
                        <button @click="openEditModal(data)" type="button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 text-yellow-400 hover:text-yellow-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                        </button>
                        <button @click="deleteProfession(data)" type="button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 text-red-400 hover:text-red-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>

        <Modal
          :showing="modalType === 'add' || modalType === 'edit'"
          @close="onCloseModal"
          :showClose="modalType === 'add' || modalType === 'edit'"
          :backgroundClose="true"
          size="max-w-4xl"
        >
          <form @submit.prevent="addProfession" id="modal-add">
            <div>
              <div
                class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
              >
                <div>
                  <h3 class="text-lg font-semibold leading-6 text-gray-900">
                    {{
                      modalType == 'edit'
                        ? 'Edit Pekerjaan'
                        : 'Tambah Pekerjaan'
                    }}
                  </h3>
                </div>
              </div>
              <div class="py-2 px-6">
                <div class="flex mt-4 sm:flex-row sm:gap-x-4">
                  <div class="field-inset-default w-full">
                    <label
                      for="nama_jenjang"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Nama Pekerjaan
                    </label>
                    <input
                      v-model="profession.attributes.name"
                      type="text"
                      name="nama_jenjang"
                      id="nama_jenjang"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="ex:S1"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="deskripsi"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Deskripsi
                    </label>
                    <input
                      v-model="profession.attributes.description"
                      @keypress.enter.prevent
                      type="text"
                      name="deskripsi"
                      id="deskripsi"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="ex: Sarjana"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-5">
              <div class="flex justify-end">
                <button
                  type="submit"
                  id="simpan"
                  class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
                >
                  Simpan
                </button>
                <button
                  @click="onCloseModal"
                  id="selesai"
                  class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
                >
                  Selesai
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    </div>
    <loading v-if="getLoading" />

    <!-- Error Modal -->
    <BaseErrorModal :message="getError" v-model="visibleErrorModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/base/BaseModal';
import BaseErrorModal from '@/components/base/BaseErrorModal';
import { profession } from '@/store/modules/professions.module';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: 'Profession',
  components: {
    Datatable,
    Modal,
    BaseErrorModal,
    BaseSearch,
    BaseButton,
  },
  data() {
    return {
      profession: profession,
      professions: {
        data: [profession],
      },
      filter: {
        search: null,
        currentPage: 1,
      },
      modalType: null,
      visibleErrorModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getProfessions: 'professions/getProfessions',
      getLoading: 'professions/getLoading',
      getError: 'professions/getError',
    }),
    professionsParams() {
      return {
        'page[number]': this.filter.currentPage,
        'filter[search]': this.filter.search ? this.filter.search : undefined,
        'page[size]': 5,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchProfessions: 'professions/fetchProfessions',
      createProfession: 'professions/createProfession',
      updateProfession: 'professions/updateProfession',
      patchProfession: 'professions/updateProfession',
    }),
    debounceSearch: function () {
      this.refetchProfessions();
    },
    pageChanged(page) {
      this.filter.currentPage = page;
      this.refetchProfessions();
    },
    async addProfession() {
      try {
        const payload = {
          data: {
            type: 'professions',
            id: this.profession.id ? this.profession.id : undefined,
            attributes: {
              name: this.profession.attributes.name,
              description: this.profession.attributes.description,
            },
          },
        };
        if (this.modalType == 'add') {
          if (!(await this.createProfession({ payload }))) {
            throw null;
          }
        } else if (this.modalType == 'edit') {
          if (!(await this.patchProfession({ payload }))) {
            throw null;
          }
        }
        this.refetchProfessions();
        this.modalType = null;
      } catch (err) {
        this.visibleErrorModal = true;
      }
    },
    async deleteProfession(profession) {
      const payload = {
        data: {
          type: 'professions',
          id: profession.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };
      await this.patchProfession({ payload });
      this.refetchProfessions();
    },
    onCloseModal() {
      this.modalType = null;
      this.profession = JSON.parse(JSON.stringify(profession));
    },
    openEditModal(data) {
      this.modalType = 'edit';
      this.profession = JSON.parse(JSON.stringify(data));
    },
    refetchProfessions() {
      this.fetchProfessions({
        params: {
          ...this.professionsParams,
        },
      });
    },
    clearData() {
      this.profession = JSON.parse(JSON.stringify(profession));
    },
  },
  created() {
    this.refetchProfessions();
  },
};
</script>
