<template>
  <base-modal
    :showing="visible"
    @close="handleClose"
    :showClose="true"
    size="max-w-7xl"
  >
    <div class="space-y-5">
      <div class="flex items-center justify-between">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Daftar Barang
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Data semua barang</p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-32" />
        </div>
      </div>
      <product-table
        @change-page="handleChangePage"
        @search="handleSearch"
        @click-row="handleClickProduct"
      />
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal';
import ProductTable from './ProductTable.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    BaseModal,
    ProductTable,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'click-item'],
  data() {
    return {
      page: {
        size: 5,
        number: 1,
      },
      filter: {
        search: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getProducts: 'products/getProducts',
    }),
  },
  watch: {
    visible(value) {
      if (value) {
        this.loadProducts({ resetPage: true, resetFilter: true });
      }
    },
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
    }),
    handleClose() {
      this.$emit('close');
    },
    handleSearch(val) {
      this.filter.search = val;
      this.loadProducts({ resetPage: true });
    },
    handleChangePage(page) {
      this.page.number = page;

      this.loadProducts();
    },
    handleClickProduct(product) {
      this.$emit('click-item', product);
      this.$emit('close');
    },
    loadProducts({ resetPage, resetFilter } = {}) {
      if (resetPage) {
        this.page.number = 1;
      }

      if (resetFilter) {
        this.filter.search = null;
      }

      this.fetchProducts({
        'page[size]': this.page.size,
        'page[number]': this.page.number,
        'filter[search]': this.filter.search,
      });
    },
  },
  created() {
    this.loadProducts();
  },
};
</script>
