<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <base-form
      :form="dataProduct"
      :nullable="nullable"
      @submit="createOrUpdateProduct"
      id="tambah-barang"
      v-slot="{ errors }"
    >
      <product-information-form
        v-model="dataProduct"
        :errors="errors"
        @search-supplier="searchSupplier"
        @search-product="searchProduct"
        @open-modal="openModal"
        @open-modal-supplier="openModalSupplier"
      />

      <product-point-form v-model="dataProduct" :errors="errors" />

      <product-certification-form v-model="dataProduct" :errors="errors" />

      <product-status-form
        v-model="dataProduct"
        @change-custom-date="changeCustomDate"
        :errors="errors"
        :status-order-visible="statusOrderVisible"
      />

      <product-price-form v-model="dataProduct" :errors="errors" />
      <div class="pt-5">
        <div class="flex justify-end space-x-2">
          <base-button id="simpan_product" type="submit"> Simpan </base-button>
        </div>
      </div>
    </base-form>

    <ProductModal
      :visible="openListProduct"
      @page-change="onPageChangeProducts"
      @change="changeProduct"
      @search="debounceSearchProduct"
      @close="openListProduct = false"
    />

    <OfficeModal
      :isVisible="openListSupplier"
      @onSearchKantor="debounceSearchSupplier"
      @onPageChangeKantor="onPageChangeSupplier"
      @change-office="changeSupplier"
      @closeKantorModal="() => (openListSupplier = !openListSupplier)"
    />

    <success-modal
      v-model="success"
      @close="() => (success = !success)"
      @previous="() => this.$router.push('/admin/master/barang')"
    />
    <failed-modal
      v-model="failed"
      :errors="getError"
      @close="() => (failed = !failed)"
      @previous="() => (failed = !failed)"
    />
    <loading
      class="z-10"
      v-if="isProductLoading || isSupplierLoading || getSimpleOfficeLoading"
    ></loading>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import BaseForm from '@/components/base/BaseForm.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import SuccessModal from '@/components/modal/success-modal.vue';
import FailedModal from '@/components/modal/failed-modal.vue';
import ProductInformationForm from '@/components/product/create-form/product-information-form.vue';
import ProductPointForm from '@/components/product/create-form/product-point-form.vue';
import ProductStatusForm from '@/components/product/create-form/product-status-form.vue';
import ProductCertificationForm from '@/components/product/create-form/product-certification-form.vue';
import ProductPriceForm from '@/components/product/create-form/product-price-form.vue';

import ProductModal from '@/components/modal/product-modal.vue';
import OfficeModal from '@/components/office/office-modal.vue';

const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export default {
  name: 'TambahBarang',
  components: {
    BaseForm,
    BaseButton,
    SuccessModal,
    FailedModal,
    ProductInformationForm,
    ProductPointForm,
    ProductCertificationForm,
    ProductStatusForm,
    ProductPriceForm,
    ProductModal,
    OfficeModal,
  },
  data() {
    const savedProduct = JSON.parse(localStorage.getItem('products'));
    const preOrderLimit = {
      is_limit_pre_order: false,
      limit_pre_order_periodic: null,
      limit_pre_order_custom_date: null,
      is_custom_date_pre_order: false,
      current_limit_pre_order_periodic: null,
      custom_pre_order_start: null,
      custom_pre_order_end: null,
    };
    const orderLimit = {
      is_limit_order: false,
      is_orderable: true,
      is_custom_date_order: false,
      limit_order_custom_date: null,
      limit_order_periodic: null,
      current_limit_order_periodic: null,
      custom_order_start: null,
      custom_order_end: null,
      is_pre_orderable: false,
    };
    return {
      preOrderLimit,
      orderLimit,
      activeStep: 0,
      steps: [
        {
          label: 'Data Produk',
        },
        {
          label: 'Point Produk',
        },
        {
          label: 'Izin Produk',
        },
        {
          label: 'Status Produk',
        },
        {
          label: 'Harga Produk',
        },
      ],
      anyProduct: false,
      isSubmitted: false,
      success: false,
      failed: false,
      openListProduct: false,
      openListSupplier: false,
      statusOrderVisible: true,
      keywordProduct: '',
      keywordSupplier: '',
      listAreas: [],
      listBuyerTypes: [],
      dataProduct: savedProduct
        ? savedProduct
        : {
            id: '',
            kode_barang: '',
            nama_barang: '',
            hpp: '',
            supplier: {
              id_supplier: '',
              kode_supplier: '',
              nama_supplier: '',
            },
            unit: {
              id: '',
              name: '',
            },
            point_value: '',
            bonus_value: '',
            status_point: true,
            supplier_price: '',
            supplier_free_price: '',
            volume: '',
            berat: '',
            product_category: {
              id: '',
              name: '',
            },
            nomor_izin: '',
            dinas: '',
            mulai_izin: '',
            akhir_izin: '',
            status_izin: true,
            pp: {
              ...clone(orderLimit),
              stockist: clone(preOrderLimit),
              sc: clone(preOrderLimit),
              kp: clone(preOrderLimit),
            },
            kp: {
              ...clone(orderLimit),
              stockist: clone(preOrderLimit),
              sc: clone(preOrderLimit),
            },
            sc: {
              ...clone(orderLimit),
              stockist: clone(preOrderLimit),
            },
            harga_wil0_stockist: null,
            harga_wil0_distributor: null,
            harga_wil0_konsumen: null,
            harga_wil1_stockist: null,
            harga_wil1_distributor: null,
            harga_wil1_konsumen: null,
            harga_wil2_stockist: null,
            harga_wil2_distributor: null,
            harga_wil2_konsumen: null,
            harga_wil3_stockist: null,
            harga_wil3_distributor: null,
            harga_wil3_konsumen: null,
            harga_wil4_stockist: null,
            harga_wil4_distributor: null,
            harga_wil4_konsumen: null,
            harga_karyawan: null,
            harga_retur: null,
            harga_perusahaan: null,
          },
    };
  },
  created() {
    this.fetchUnits();
    this.fetchProductCategories();

    if (this.$route.query.code) {
      this.dataProduct.kode_barang = this.$route.query.code;
      this.searchProduct();
    }
    this.fetchAreas({
      keyword: null,
    }).then((response) => {
      this.listAreas = response.data.data;
    });
    this.fetchBuyerTypes({
      keyword: null,
    }).then((response) => {
      this.listBuyerTypes = response.data.data;
    });

    const handleEscape = () => {
      this.success =
        this.failed =
        this.openListProduct =
        this.openListSupplier =
          false;
      this.keywordProduct = this.keywordSupplier = '';
    };
    this.setEscape(handleEscape);
  },
  watch: {
    dataProduct: {
      handler(_, newValue) {
        if (!this.isSubmitted) {
          _, newValue;
          // localStorage.setItem('products', JSON.stringify(newValue));
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      isSupplierLoading: 'suppliers/getLoading',
      isProductLoading: 'products/getLoading',
      getProducts: 'products/getProducts',
      getUnits: 'units/getUnits',
      getProductCategories: 'product_categories/getProductCategories',
      getError: 'products/getError',
      getAvailableOfficeCategories:
        'office_categories/getAvailableOfficeCategories',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getSimpleOfficeLoading: 'simple_offices/getLoading',
    }),
    nullable: function () {
      return {
        id: null,
        point_value: this.dataProduct.is_point ? undefined : '',
        bonus_value: this.dataProduct.is_point ? undefined : '',
        nomor_izin: true,
        dinas: true,
        mulai_izin: true,
        akhir_izin: true,
        supplier: {
          id_supplier: '',
        },
        unit: {
          name: '',
        },
        product_category: {
          name: '',
        },
        pp: {
          is_limit_order: false,
          is_orderable: true,
          is_custom_date_order: false,
          limit_order_custom_date: null,
          limit_order_periodic: null,
          current_limit_order_periodic: null,
          custom_order_start: null,
          custom_order_end: null,
          is_pre_orderable: false,
          stockist: clone(this.preOrderLimit),
          sc: clone(this.preOrderLimit),
          kp: clone(this.preOrderLimit),
        },
        kp: {
          ...clone(this.orderLimit),
          stockist: clone(this.preOrderLimit),
          sc: clone(this.preOrderLimit),
        },
        sc: {
          ...clone(this.orderLimit),
          stockist: clone(this.preOrderLimit),
          sc: clone(this.preOrderLimit),
        },
      };
    },
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      fetchSupplier: 'suppliers/fetchSupplier',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
      createProduct: 'products/createProduct',
      updateProduct: 'products/updateProduct',
      fetchUnits: 'units/fetchUnits',
      fetchProductCategories: 'product_categories/fetchProductCategories',
      fetchProductLimitsByProduct: 'product_limits/fetchProductLimitsByProduct',
      fetchAreas: 'areas/fetchAreas',
      fetchBuyerTypes: 'buyer_types/fetchBuyerTypes',
    }),
    loadSuppliers(params = {}) {
      this.fetchSimpleOffices({
        ...params,
        'filter[office_category_id]': 6,
        'page[limit]': 5,
        include: 'office',
        'fields[simple-offices]': 'code,name,office',
        'fields[offices]': 'temp_code,code,name,address,phone',
      });
    },
    successModal() {
      this.$store.commit('products/setLoader', false);
      this.success = !this.success;
      this.$router.push('/admin/master/barang');
      this.clearDataProduct();
    },
    failedModal() {
      this.failed = !this.failed;
      this.$store.commit('products/setError', null);
      this.clearDataProduct();
    },
    openModal() {
      this.openListProduct = !this.openListProduct;
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
      });
    },
    openModalSupplier() {
      this.openListSupplier = !this.openListSupplier;
      this.loadSuppliers();
    },
    changeCustomDate(office) {
      if (office === 'pp') {
        this.dataProduct.pp.current_limit_order_periodic =
          this.dataProduct.pp.custom_order_start =
          this.dataProduct.pp.custom_order_end =
            null;
      } else if (office === 'pp-stockist') {
        this.dataProduct.pp.stockist.current_limit_pre_order_periodic =
          this.dataProduct.pp.stockist.custom_pre_order_start =
          this.dataProduct.pp.custom_pre_order_end =
            null;
      } else if (office === 'pp-sc') {
        this.dataProduct.pp.sc.current_limit_pre_order_periodic =
          this.dataProduct.pp.sc.custom_pre_order_start =
          this.dataProduct.pp.custom_pre_order_end =
            null;
      } else if (office === 'kp') {
        this.dataProduct.kp.current_limit_order_periodic =
          this.dataProduct.kp.custom_order_start =
          this.dataProduct.kp.custom_order_end =
            null;
      } else if (office === 'kp-stockist') {
        this.dataProduct.kp.stockist.current_limit_pre_order_periodic =
          this.dataProduct.kp.stockist.custom_pre_order_start =
          this.dataProduct.kp.custom_pre_order_end =
            null;
      } else if (office === 'kp-sc') {
        this.dataProduct.kp.sc.current_limit_pre_order_periodic =
          this.dataProduct.kp.sc.custom_pre_order_start =
          this.dataProduct.kp.custom_pre_order_end =
            null;
      } else if (office === 'sc') {
        this.dataProduct.sc.current_limit_order_periodic =
          this.dataProduct.sc.custom_order_start =
          this.dataProduct.sc.custom_order_end =
            null;
      } else if (office === 'sc-stockist') {
        this.dataProduct.sc.stockist.current_limit_pre_order_periodic =
          this.dataProduct.sc.stockist.custom_pre_order_start =
          this.dataProduct.sc.custom_pre_order_end =
            null;
      }
    },
    searchProduct() {
      if (this.dataProduct.kode_barang) {
        this.fetchProducts({
          keyword: this.dataProduct.kode_barang,
        }).then(() => {
          if (this.getProducts.data.length > 0) {
            this.anyProduct = true;
            this.dataProduct.id = this.getProducts.data[0].id;
            this.dataProduct.kode_barang =
              this.getProducts.data[0].attributes.code;
            this.dataProduct.nama_barang =
              this.getProducts.data[0].attributes.name;
            if (this.getProducts.data[0].relationships['office'].data?.id) {
              this.dataProduct.supplier.id_supplier = this.getSupplier(
                this.getProducts.data[0].relationships['office'].data.id
              ).id;
              this.dataProduct.supplier.kode_supplier = this.getSupplier(
                this.getProducts.data[0].relationships['office'].data.id
              ).attributes.code;
              this.dataProduct.supplier.nama_supplier = this.getSupplier(
                this.getProducts.data[0].relationships['office'].data.id
              ).attributes.name;
            }
            this.dataProduct.supplier_price =
              this.getProducts.data[0].attributes.supplier_price;
            this.dataProduct.supplier_free_price =
              this.getProducts.data[0].attributes.supplier_free_price;
            this.dataProduct.hpp = this.getProducts.data[0].attributes.hpp;
            this.dataProduct.point_value =
              this.getProducts.data[0].attributes.point;
            this.dataProduct.bonus_value =
              this.getProducts.data[0].attributes.bonus;
            this.dataProduct.berat = this.getProducts.data[0].attributes.weight;
            this.dataProduct.volume =
              this.getProducts.data[0].attributes.volume;
            this.dataProduct.status_point =
              !!this.getProducts.data[0].attributes.is_point;
            this.dataProduct.nomor_izin =
              this.getProducts.data[0].attributes.license_number;
            this.dataProduct.dinas =
              this.getProducts.data[0].attributes.license_institution;

            let mulai_izin = this.getProducts.data[0].attributes.license_start;
            let akhir_izin = this.getProducts.data[0].attributes.license_end;

            this.dataProduct.mulai_izin =
              mulai_izin == '0000-00-00' ? null : mulai_izin; // 0000-00-00 itu krn backendnya gk ngenull in datenya
            this.dataProduct.akhir_izin =
              akhir_izin == '0000-00-00' ? null : akhir_izin;
            this.dataProduct.product_category.id = this.getRelationshipById(
              this.getProducts.data[0].relationships['product-category'].data.id
            ).id;
            this.dataProduct.product_category.name = this.getRelationshipById(
              this.getProducts.data[0].relationships['product-category'].data.id
            ).attributes.name;
            this.dataProduct.unit.id = this.getRelationshipById(
              this.getProducts.data[0].relationships['unit'].data.id
            ).id;
            this.dataProduct.unit.name = this.getRelationshipById(
              this.getProducts.data[0].relationships['unit'].data.id
            ).attributes.name;
            // Product Limit Pusat - Stockist
            const ppStockist = this.getProductLimit('Pusat', 'Stockist');
            if (ppStockist) {
              this.dataProduct.pp.is_limit_order =
                ppStockist.attributes.limit_order_periodic !== 0;
              this.dataProduct.pp.is_orderable =
                !!ppStockist.attributes.is_orderable;
              this.dataProduct.pp.is_custom_date_order =
                ppStockist.attributes.is_custom_date_order;
              this.dataProduct.pp.limit_order_custom_date =
                ppStockist.attributes.limit_order_custom_date;
              this.dataProduct.pp.limit_order_periodic =
                ppStockist.attributes.limit_order_periodic;
              this.dataProduct.pp.current_limit_order_periodic =
                ppStockist.attributes.current_limit_order_periodic;
              this.dataProduct.pp.custom_order_start =
                ppStockist.attributes.custom_order_start;
              this.dataProduct.pp.custom_order_end =
                ppStockist.attributes.custom_order_end;
              this.dataProduct.pp.is_pre_orderable =
                ppStockist.attributes.is_pre_orderable;
              this.dataProduct.pp.stockist.is_limit_pre_order =
                ppStockist.attributes.limit_pre_order_periodic !== 0;
              this.dataProduct.pp.stockist.limit_pre_order_periodic =
                ppStockist.attributes.limit_pre_order_periodic;
              this.dataProduct.pp.stockist.limit_pre_order_custom_date =
                ppStockist.attributes.limit_pre_order_custom_date;
              this.dataProduct.pp.stockist.is_custom_date_pre_order =
                ppStockist.attributes.is_custom_date_pre_order;
              this.dataProduct.pp.stockist.current_limit_pre_order_periodic =
                ppStockist.attributes.current_limit_pre_order_periodic;
              this.dataProduct.pp.stockist.custom_pre_order_start =
                ppStockist.attributes.custom_pre_order_start;
              this.dataProduct.pp.stockist.custom_pre_order_end =
                ppStockist.attributes.custom_pre_order_end;
            }
            // Product Limit Pusat - Kantor Pelayanan
            const ppKp = this.getProductLimit('Pusat', 'Kantor Pelayanan');
            if (ppKp) {
              this.dataProduct.pp.kp.is_limit_pre_order =
                ppKp.attributes.limit_pre_order_periodic !== 0;
              this.dataProduct.pp.kp.limit_pre_order_periodic =
                ppKp.attributes.limit_pre_order_periodic;
              this.dataProduct.pp.kp.limit_pre_order_custom_date =
                ppKp.attributes.limit_pre_order_custom_date;
              this.dataProduct.pp.kp.is_custom_date_pre_order =
                ppKp.attributes.is_custom_date_pre_order;
              this.dataProduct.pp.kp.current_limit_pre_order_periodic =
                ppKp.attributes.current_limit_pre_order_periodic;
              this.dataProduct.pp.kp.custom_pre_order_start =
                ppKp.attributes.custom_pre_order_start;
              this.dataProduct.pp.kp.custom_pre_order_end =
                ppKp.attributes.custom_pre_order_end;
            }
            // Product Limit Pusat - Stockist Center
            const ppSc = this.getProductLimit('Pusat', 'Stockist Center');
            if (ppSc) {
              this.dataProduct.pp.sc.is_limit_pre_order =
                ppSc.attributes.limit_pre_order_periodic !== 0;
              this.dataProduct.pp.sc.limit_pre_order_periodic =
                ppSc.attributes.limit_pre_order_periodic;
              this.dataProduct.pp.sc.limit_pre_order_custom_date =
                ppSc.attributes.limit_pre_order_custom_date;
              this.dataProduct.pp.sc.is_custom_date_pre_order =
                ppSc.attributes.is_custom_date_pre_order;
              this.dataProduct.pp.sc.current_limit_pre_order_periodic =
                ppSc.attributes.current_limit_pre_order_periodic;
              this.dataProduct.pp.sc.custom_pre_order_start =
                ppSc.attributes.custom_pre_order_start;
              this.dataProduct.pp.sc.custom_pre_order_end =
                ppSc.attributes.custom_pre_order_end;
            }
            // Product Limit Kantor Pelayanan - Stockist
            const kpStockist = this.getProductLimit(
              'Kantor Pelayanan',
              'Stockist'
            );
            if (kpStockist) {
              this.dataProduct.kp.is_limit_order =
                kpStockist.attributes.limit_order_periodic !== 0;
              this.dataProduct.kp.is_orderable =
                !!kpStockist.attributes.is_orderable;
              this.dataProduct.kp.is_custom_date_order =
                kpStockist.attributes.is_custom_date_order;
              this.dataProduct.kp.limit_order_custom_date =
                kpStockist.attributes.limit_order_custom_date;
              this.dataProduct.kp.limit_order_periodic =
                kpStockist.attributes.limit_order_periodic;
              this.dataProduct.kp.current_limit_order_periodic =
                kpStockist.attributes.current_limit_order_periodic;
              this.dataProduct.kp.custom_order_start =
                kpStockist.attributes.custom_order_start;
              this.dataProduct.kp.custom_order_end =
                kpStockist.attributes.custom_order_end;
              this.dataProduct.kp.is_pre_orderable =
                kpStockist.attributes.is_pre_orderable;
              this.dataProduct.kp.stockist.is_limit_pre_order =
                kpStockist.attributes.limit_pre_order_periodic !== 0;
              this.dataProduct.kp.stockist.limit_pre_order_periodic =
                kpStockist.attributes.limit_pre_order_periodic;
              this.dataProduct.kp.stockist.limit_pre_order_custom_date =
                kpStockist.attributes.limit_pre_order_custom_date;
              this.dataProduct.kp.stockist.is_custom_date_pre_order =
                kpStockist.attributes.is_custom_date_pre_order;
              this.dataProduct.kp.stockist.current_limit_pre_order_periodic =
                kpStockist.attributes.current_limit_pre_order_periodic;
              this.dataProduct.kp.stockist.custom_pre_order_start =
                kpStockist.attributes.custom_pre_order_start;
              this.dataProduct.kp.stockist.custom_pre_order_end =
                kpStockist.attributes.custom_pre_order_end;
            }
            // Product Limit Pusat - Stockist Center
            const kpSc = this.getProductLimit(
              'Kantor Pelayanan',
              'Stockist Center'
            );
            if (kpSc) {
              this.dataProduct.kp.sc.is_limit_pre_order =
                kpSc.attributes.limit_pre_order_periodic !== 0;
              this.dataProduct.kp.sc.limit_pre_order_periodic =
                kpSc.attributes.limit_pre_order_periodic;
              this.dataProduct.kp.sc.limit_pre_order_custom_date =
                kpSc.attributes.limit_pre_order_custom_date;
              this.dataProduct.kp.sc.is_custom_date_pre_order =
                kpSc.attributes.is_custom_date_pre_order;
              this.dataProduct.kp.sc.current_limit_pre_order_periodic =
                kpSc.attributes.current_limit_pre_order_periodic;
              this.dataProduct.kp.sc.custom_pre_order_start =
                kpSc.attributes.custom_pre_order_start;
              this.dataProduct.kp.sc.custom_pre_order_end =
                kpSc.attributes.custom_pre_order_end;
            }
            // Product Limit Stockist Center - Stockist
            const scStockist = this.getProductLimit(
              'Stockist Center',
              'Stockist'
            );
            if (scStockist) {
              this.dataProduct.sc.is_limit_order =
                scStockist.attributes.limit_order_periodic !== 0;
              this.dataProduct.sc.is_orderable =
                !!scStockist.attributes.is_orderable;
              this.dataProduct.sc.is_custom_date_order =
                scStockist.attributes.is_custom_date_order;
              this.dataProduct.sc.limit_order_custom_date =
                scStockist.attributes.limit_order_custom_date;
              this.dataProduct.sc.limit_order_periodic =
                scStockist.attributes.limit_order_periodic;
              this.dataProduct.sc.current_limit_order_periodic =
                scStockist.attributes.current_limit_order_periodic;
              this.dataProduct.sc.custom_order_start =
                scStockist.attributes.custom_order_start;
              this.dataProduct.sc.custom_order_end =
                scStockist.attributes.custom_order_end;
              this.dataProduct.sc.is_pre_orderable =
                scStockist.attributes.is_pre_orderable;
              this.dataProduct.sc.stockist.is_limit_pre_order =
                scStockist.attributes.limit_pre_order_periodic !== 0;
              this.dataProduct.sc.stockist.limit_pre_order_periodic =
                scStockist.attributes.limit_pre_order_periodic;
              this.dataProduct.sc.stockist.limit_pre_order_custom_date =
                scStockist.attributes.limit_pre_order_custom_date;
              this.dataProduct.sc.stockist.is_custom_date_pre_order =
                scStockist.attributes.is_custom_date_pre_order;
              this.dataProduct.sc.stockist.current_limit_pre_order_periodic =
                scStockist.attributes.current_limit_pre_order_periodic;
              this.dataProduct.sc.stockist.custom_pre_order_start =
                scStockist.attributes.custom_pre_order_start;
              this.dataProduct.sc.stockist.custom_pre_order_end =
                scStockist.attributes.custom_pre_order_end;
            }
            // Product Price
            // Stockist
            this.dataProduct.harga_wil0_stockist = this.getProductPrice(
              'Wilayah Jawa',
              'Stockist'
            )?.attributes?.price;
            this.dataProduct.harga_wil1_stockist = this.getProductPrice(
              'Wilayah I',
              'Stockist'
            )?.attributes?.price;
            this.dataProduct.harga_wil2_stockist = this.getProductPrice(
              'Wilayah II',
              'Stockist'
            )?.attributes?.price;
            this.dataProduct.harga_wil3_stockist = this.getProductPrice(
              'Wilayah III',
              'Stockist'
            )?.attributes?.price;
            this.dataProduct.harga_wil4_stockist = this.getProductPrice(
              'Wilayah IV',
              'Stockist'
            )?.attributes?.price;

            // Distributor
            this.dataProduct.harga_wil0_distributor = this.getProductPrice(
              'Wilayah Jawa',
              'Mitra Usaha'
            )?.attributes?.price;
            this.dataProduct.harga_wil1_distributor = this.getProductPrice(
              'Wilayah I',
              'Mitra Usaha'
            )?.attributes?.price;
            this.dataProduct.harga_wil2_distributor = this.getProductPrice(
              'Wilayah II',
              'Mitra Usaha'
            )?.attributes?.price;
            this.dataProduct.harga_wil3_distributor = this.getProductPrice(
              'Wilayah III',
              'Mitra Usaha'
            )?.attributes?.price;
            this.dataProduct.harga_wil4_distributor = this.getProductPrice(
              'Wilayah IV',
              'Mitra Usaha'
            )?.attributes?.price;

            // Konsumen
            this.dataProduct.harga_wil0_konsumen = this.getProductPrice(
              'Wilayah Jawa',
              'Konsumen'
            )?.attributes?.price;
            this.dataProduct.harga_wil1_konsumen = this.getProductPrice(
              'Wilayah I',
              'Konsumen'
            )?.attributes?.price;
            this.dataProduct.harga_wil2_konsumen = this.getProductPrice(
              'Wilayah II',
              'Konsumen'
            )?.attributes?.price;
            this.dataProduct.harga_wil3_konsumen = this.getProductPrice(
              'Wilayah III',
              'Konsumen'
            )?.attributes?.price;
            this.dataProduct.harga_wil4_konsumen = this.getProductPrice(
              'Wilayah IV',
              'Konsumen'
            )?.attributes?.price;

            // Karyawan diganti jd PRSH
            // update: PRSH diganti karyawan
            this.dataProduct.harga_karyawan = this.getProductPrice(
              'Wilayah Jawa',
              'Karyawan'
            )?.attributes?.price;
            // Retur diganti jd Khusus
            this.dataProduct.harga_retur = this.getProductPrice(
              'Wilayah Jawa',
              'Khusus'
            )?.attributes?.price;
            // Perusahaan diganti jd Free
            this.dataProduct.harga_perusahaan = this.getProductPrice(
              'Wilayah Jawa',
              'Free'
            )?.attributes?.price;
          } else {
            this.anyProduct = false;
            const kode_barang = this.dataProduct.kode_barang;
            this.clearDataProduct();
            this.dataProduct.kode_barang = kode_barang;
          }
        });
      }
    },
    searchSupplier() {
      if (this.dataProduct.supplier.kode_supplier) {
        this.fetchSimpleOffices({
          'filter[code]': this.dataProduct.supplier.kode_supplier,
          'filter[office_category_id]': 6,
          'page[limit]': 1,
          include: 'office',
          'fields[simple-offices]': 'office',
          'fields[offices]': 'code,name',
        }).then(() => {
          if (this.getSimpleOffices.data.length > 0) {
            const simpleOffice = this.getSimpleOffices.data[0];
            const office = this.getSingleIncluded(
              this.getSimpleOffices,
              simpleOffice.relationships.office.data.id
            );

            this.dataProduct.supplier.id_supplier = office.id;
            this.dataProduct.supplier.kode_supplier = office.attributes.code;
            this.dataProduct.supplier.nama_supplier = office.attributes.name;
          } else {
            this.dataProduct.supplier.id_supplier =
              this.dataProduct.supplier.kode_supplier =
              this.dataProduct.supplier.nama_supplier =
                '';
          }
        });
      }
    },
    debounceSearchSupplier(value) {
      this.keywordSupplier = value;
      this.loadSuppliers({
        'filter[search]': this.keywordSupplier,
      });
    },
    onPageChangeSupplier(page) {
      this.loadSuppliers(page);
    },
    parseNominal(strOrNumber) {
      if (typeof strOrNumber == 'string') {
        return parseInt(strOrNumber.replace('.', ''));
      }
      return strOrNumber;
    },
    createOrUpdateProduct: async function () {
      const payload = {
        data: {
          type: 'products',
          attributes: {
            // Detail Barang
            name: this.dataProduct.nama_barang,
            code: this.dataProduct.kode_barang,
            supplier_price: parseInt(typeof this.dataProduct.supplier_price === 'string' ? this.dataProduct.supplier_price.replace(/\D/g, '') : this.dataProduct.supplier_price),
            supplier_free_price: parseInt(typeof this.dataProduct.supplier_free_price === 'string' ? this.dataProduct.supplier_free_price.replace(/\D/g, '') : this.dataProduct.supplier_free_price),
            point:
              this.dataProduct.point_value && this.dataProduct.status_point
                ? parseInt(this.dataProduct.point_value)
                : undefined,
            bonus:
              this.dataProduct.bonus_value && this.dataProduct.status_point
                ? parseInt(this.dataProduct.bonus_value)
                : undefined,
            is_point: this.dataProduct.status_point,
            weight: parseFloat(this.dataProduct.berat),
            volume: parseFloat(this.dataProduct.volume),
            // Izin Status
            license_number: this.dataProduct.nomor_izin,
            license_institution: this.dataProduct.dinas,
            license_start: this.dataProduct.mulai_izin,
            license_end: this.dataProduct.akhir_izin,
            license_status: this.dataProduct.akhir_izin ? true : false,
            product_prices: [
              // Stockist
              {
                buyer_type_id: this.getBuyerTypeByName('Stockist').id,
                area_id: this.getAreaByCode(0).id,
                price: this.parseNominal(this.dataProduct.harga_wil0_stockist),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Stockist').id,
                area_id: this.getAreaByCode(1).id,
                price: this.parseNominal(this.dataProduct.harga_wil1_stockist),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Stockist').id,
                area_id: this.getAreaByCode(2).id,
                price: this.parseNominal(this.dataProduct.harga_wil2_stockist),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Stockist').id,
                area_id: this.getAreaByCode(3).id,
                price: this.parseNominal(this.dataProduct.harga_wil3_stockist),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Stockist').id,
                area_id: this.getAreaByCode(4).id,
                price: this.parseNominal(this.dataProduct.harga_wil4_stockist),
              },
              // Distributor
              {
                buyer_type_id: this.getBuyerTypeByName('Mitra Usaha').id,
                area_id: this.getAreaByCode(0).id,
                price: this.parseNominal(
                  this.dataProduct.harga_wil0_distributor
                ),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Mitra Usaha').id,
                area_id: this.getAreaByCode(1).id,
                price: this.parseNominal(
                  this.dataProduct.harga_wil1_distributor
                ),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Mitra Usaha').id,
                area_id: this.getAreaByCode(2).id,
                price: this.parseNominal(
                  this.dataProduct.harga_wil2_distributor
                ),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Mitra Usaha').id,
                area_id: this.getAreaByCode(3).id,
                price: this.parseNominal(
                  this.dataProduct.harga_wil3_distributor
                ),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Mitra Usaha').id,
                area_id: this.getAreaByCode(4).id,
                price: this.parseNominal(
                  this.dataProduct.harga_wil4_distributor
                ),
              },
              // Konsumen
              {
                buyer_type_id: this.getBuyerTypeByName('Konsumen').id,
                area_id: this.getAreaByCode(0).id,
                price: this.parseNominal(this.dataProduct.harga_wil0_konsumen),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Konsumen').id,
                area_id: this.getAreaByCode(1).id,
                price: this.parseNominal(this.dataProduct.harga_wil1_konsumen),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Konsumen').id,
                area_id: this.getAreaByCode(2).id,
                price: this.parseNominal(this.dataProduct.harga_wil2_konsumen),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Konsumen').id,
                area_id: this.getAreaByCode(3).id,
                price: this.parseNominal(this.dataProduct.harga_wil3_konsumen),
              },
              {
                buyer_type_id: this.getBuyerTypeByName('Konsumen').id,
                area_id: this.getAreaByCode(4).id,
                price: this.parseNominal(this.dataProduct.harga_wil4_konsumen),
              },
              // Karyawan diganti PRSH
              // update: PRSH diganti karyawan lagi
              {
                buyer_type_id: this.getBuyerTypeByName('Karyawan').id,
                area_id: this.getAreaByCode(0).id,
                price: this.parseNominal(this.dataProduct.harga_karyawan),
              },
              // Retur diganti Khusus
              {
                buyer_type_id: this.getBuyerTypeByName('Khusus').id,
                area_id: this.getAreaByCode(0).id,
                price: this.parseNominal(this.dataProduct.harga_retur),
              },
              // Perusahaan diganti Free
              {
                buyer_type_id: this.getBuyerTypeByName('Free').id,
                area_id: this.getAreaByCode(0).id,
                price: this.dataProduct.harga_perusahaan
                  ? this.parseNominal(this.dataProduct.harga_perusahaan)
                  : 0,
              },
            ],
            product_limits: [
              {
                origin_office_category_id:
                  this.getOfficeCategoryByName('Pusat').id,
                destination_office_category_id:
                  this.getOfficeCategoryByName('Stockist').id,
                is_orderable: this.dataProduct.pp.is_orderable,
                is_custom_date_order: this.dataProduct.pp.is_custom_date_order,
                limit_order_custom_date: null,
                limit_order_periodic: this.dataProduct.pp.is_limit_order
                  ? parseInt(this.dataProduct.pp.limit_order_periodic)
                  : 0,
                current_limit_order_periodic:
                  this.dataProduct.pp.current_limit_order_periodic,
                custom_order_start: this.dataProduct.pp.custom_order_start,
                custom_order_end: this.dataProduct.pp.custom_order_end,
                is_pre_orderable: this.dataProduct.pp.is_pre_orderable,
                is_custom_date_pre_order:
                  this.dataProduct.pp.stockist.is_custom_date_pre_order,
                limit_pre_order_custom_date: null,
                limit_pre_order_periodic: this.dataProduct.pp.stockist
                  .is_limit_pre_order
                  ? parseInt(
                      this.dataProduct.pp.stockist.limit_pre_order_periodic
                    )
                  : 0,
                current_limit_pre_order_periodic:
                  this.dataProduct.pp.stockist.current_limit_pre_order_periodic,
                custom_pre_order_start:
                  this.dataProduct.pp.stockist.custom_pre_order_start,
                custom_pre_order_end:
                  this.dataProduct.pp.stockist.custom_pre_order_end,
              },
              {
                origin_office_category_id:
                  this.getOfficeCategoryByName('Pusat').id,
                destination_office_category_id:
                  this.getOfficeCategoryByName('Stockist Center').id,
                is_orderable: this.dataProduct.pp.is_orderable,
                is_custom_date_order: this.dataProduct.pp.is_custom_date_order,
                limit_order_custom_date: null,
                limit_order_periodic: this.dataProduct.pp.is_limit_order
                  ? parseInt(this.dataProduct.pp.limit_order_periodic)
                  : 0,
                current_limit_order_periodic:
                  this.dataProduct.pp.current_limit_order_periodic,
                custom_order_start: this.dataProduct.pp.custom_order_start,
                custom_order_end: this.dataProduct.pp.custom_order_end,
                is_pre_orderable: this.dataProduct.pp.is_pre_orderable,
                is_custom_date_pre_order:
                  this.dataProduct.pp.sc.is_custom_date_pre_order,
                limit_pre_order_custom_date: null,
                limit_pre_order_periodic: this.dataProduct.pp.sc
                  .is_limit_pre_order
                  ? parseInt(this.dataProduct.pp.sc.limit_pre_order_periodic)
                  : 0,
                current_limit_pre_order_periodic:
                  this.dataProduct.pp.sc.current_limit_pre_order_periodic,
                custom_pre_order_start:
                  this.dataProduct.pp.sc.custom_pre_order_start,
                custom_pre_order_end:
                  this.dataProduct.pp.sc.custom_pre_order_end,
              },
              {
                origin_office_category_id:
                  this.getOfficeCategoryByName('Pusat').id,
                destination_office_category_id:
                  this.getOfficeCategoryByName('Kantor Pelayanan').id,
                is_orderable: this.dataProduct.pp.is_orderable,
                is_custom_date_order: this.dataProduct.pp.is_custom_date_order,
                limit_order_custom_date: null,
                limit_order_periodic: this.dataProduct.pp.is_limit_order
                  ? parseInt(this.dataProduct.pp.limit_order_periodic)
                  : 0,
                current_limit_order_periodic:
                  this.dataProduct.pp.current_limit_order_periodic,
                custom_order_start: this.dataProduct.pp.custom_order_start,
                custom_order_end: this.dataProduct.pp.custom_order_end,
                is_pre_orderable: this.dataProduct.pp.is_pre_orderable,
                is_custom_date_pre_order:
                  this.dataProduct.pp.kp.is_custom_date_pre_order,
                limit_pre_order_custom_date: null,
                limit_pre_order_periodic: this.dataProduct.pp.kp
                  .is_limit_pre_order
                  ? parseInt(this.dataProduct.pp.kp.limit_pre_order_periodic)
                  : 0,
                current_limit_pre_order_periodic:
                  this.dataProduct.pp.kp.current_limit_pre_order_periodic,
                custom_pre_order_start:
                  this.dataProduct.pp.kp.custom_pre_order_start,
                custom_pre_order_end:
                  this.dataProduct.pp.kp.custom_pre_order_end,
              },
              {
                origin_office_category_id:
                  this.getOfficeCategoryByName('Kantor Pelayanan').id,
                destination_office_category_id:
                  this.getOfficeCategoryByName('Stockist').id,
                is_orderable: this.dataProduct.kp.is_orderable,
                is_custom_date_order: this.dataProduct.kp.is_custom_date_order,
                limit_order_custom_date: null,
                limit_order_periodic: this.dataProduct.kp.is_limit_order
                  ? parseInt(this.dataProduct.kp.limit_order_periodic)
                  : 0,
                current_limit_order_periodic:
                  this.dataProduct.kp.current_limit_order_periodic,
                custom_order_start: this.dataProduct.kp.custom_order_start,
                custom_order_end: this.dataProduct.kp.custom_order_end,
                is_pre_orderable: this.dataProduct.kp.is_pre_orderable,
                is_custom_date_pre_order:
                  this.dataProduct.kp.stockist.is_custom_date_pre_order,
                limit_pre_order_custom_date: null,
                limit_pre_order_periodic: this.dataProduct.kp.stockist
                  .is_limit_pre_order
                  ? parseInt(
                      this.dataProduct.kp.stockist.limit_pre_order_periodic
                    )
                  : 0,
                current_limit_pre_order_periodic:
                  this.dataProduct.kp.stockist.current_limit_pre_order_periodic,
                custom_pre_order_start:
                  this.dataProduct.kp.stockist.custom_pre_order_start,
                custom_pre_order_end:
                  this.dataProduct.kp.stockist.custom_pre_order_end,
              },
              {
                origin_office_category_id:
                  this.getOfficeCategoryByName('Kantor Pelayanan').id,
                destination_office_category_id:
                  this.getOfficeCategoryByName('Stockist Center').id,
                is_orderable: this.dataProduct.kp.is_orderable,
                is_custom_date_order: this.dataProduct.kp.is_custom_date_order,
                limit_order_custom_date: null,
                limit_order_periodic: this.dataProduct.kp.is_limit_order
                  ? parseInt(this.dataProduct.kp.limit_order_periodic)
                  : 0,
                current_limit_order_periodic:
                  this.dataProduct.kp.current_limit_order_periodic,
                custom_order_start: this.dataProduct.kp.custom_order_start,
                custom_order_end: this.dataProduct.kp.custom_order_end,
                is_pre_orderable: this.dataProduct.kp.is_pre_orderable,
                is_custom_date_pre_order:
                  this.dataProduct.kp.sc.is_custom_date_pre_order,
                limit_pre_order_custom_date: null,
                limit_pre_order_periodic: this.dataProduct.kp.sc
                  .is_limit_pre_order
                  ? parseInt(this.dataProduct.kp.sc.limit_pre_order_periodic)
                  : 0,
                current_limit_pre_order_periodic:
                  this.dataProduct.kp.sc.current_limit_pre_order_periodic,
                custom_pre_order_start:
                  this.dataProduct.kp.sc.custom_pre_order_start,
                custom_pre_order_end:
                  this.dataProduct.kp.sc.custom_pre_order_end,
              },
              {
                origin_office_category_id:
                  this.getOfficeCategoryByName('Stockist Center').id,
                destination_office_category_id:
                  this.getOfficeCategoryByName('Stockist').id,
                is_orderable: this.dataProduct.sc.is_orderable,
                is_custom_date_order: this.dataProduct.sc.is_custom_date_order,
                limit_order_custom_date: null,
                limit_order_periodic: this.dataProduct.sc.is_limit_order
                  ? parseInt(this.dataProduct.sc.limit_order_periodic)
                  : 0,
                current_limit_order_periodic:
                  this.dataProduct.sc.current_limit_order_periodic,
                custom_order_start: this.dataProduct.sc.custom_order_start,
                custom_order_end: this.dataProduct.sc.custom_order_end,
                is_pre_orderable: this.dataProduct.sc.is_pre_orderable,
                is_custom_date_pre_order:
                  this.dataProduct.sc.stockist.is_custom_date_pre_order,
                limit_pre_order_custom_date: null,
                limit_pre_order_periodic: this.dataProduct.sc.stockist
                  .is_limit_pre_order
                  ? parseInt(
                      this.dataProduct.sc.stockist.limit_pre_order_periodic
                    )
                  : 0,
                current_limit_pre_order_periodic:
                  this.dataProduct.sc.stockist.current_limit_pre_order_periodic,
                custom_pre_order_start:
                  this.dataProduct.sc.stockist.custom_pre_order_start,
                custom_pre_order_end:
                  this.dataProduct.sc.stockist.custom_pre_order_end,
              },
            ],
          },
          relationships: {
            'product-category': {
              data: {
                type: 'product-categories',
                id: this.dataProduct.product_category.id,
              },
            },
            unit: {
              data: {
                type: 'units',
                id: this.dataProduct.unit.id,
              },
            },
            office: {
              data: {
                type: 'offices',
                id: this.dataProduct.supplier.id_supplier,
              },
            },
          },
        },
      };
      if (!this.anyProduct && !this.dataProduct.id) {
        await this.createProduct(payload).then((response) => {
          if (response) {
            this.success = true;
            localStorage.removeItem('products');
          } else {
            this.failed = true;
          }
        });
      } else {
        payload.data.id = this.dataProduct.id;
        await this.updateProduct(payload).then((response) => {
          if (response) {
            this.success = true;
            localStorage.removeItem('products');
          } else {
            this.failed = true;
          }
        });
      }

      this.isSubmitted = true;
    },
    changeProduct(data) {
      this.dataProduct.kode_barang = data.attributes.code;
      this.searchProduct();
      this.keywordProduct = '';
      this.openListProduct = !this.openListProduct;
    },
    changeSupplier(data) {
      this.dataProduct.supplier.kode_supplier = data.attributes.code;
      this.searchSupplier();
      this.keywordSupplier = '';
      this.openListSupplier = !this.openListSupplier;
    },
    onPageChangeProducts(page) {
      this.fetchProducts({
        pageNumber: page,
        pageSize: 5,
        search: this.keywordProduct,
      });
    },
    getRelationshipById(id) {
      let data = this.getProducts.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    getAreaByCode(code) {
      let area = this.listAreas.filter(function (el) {
        return el.attributes.code === code;
      });
      return area[0];
    },
    getBuyerTypeByName(name) {
      let buyerType = this.listBuyerTypes.filter(function (el) {
        return el.attributes.name === name;
      });
      return buyerType[0];
    },
    getOfficeCategoryByName(name) {
      let officeCategory = this.getAvailableOfficeCategories.filter(function (
        el
      ) {
        return el.attributes.name === name;
      });
      return officeCategory[0];
    },
    getSupplier(id) {
      let supplier = this.getProducts.included.filter(function (el) {
        return el.id === id;
      });
      return supplier[0];
    },
    getProductLimit(origin, destination) {
      let productLimit = this.getProducts.included.filter(function (el) {
        return (
          el.attributes.origin_office_category === origin &&
          el.attributes.destination_office_category === destination
        );
      });
      return productLimit[0];
    },
    getProductPrice(areaName, buyerType) {
      let productPrice = this.getProducts.included.filter(function (el) {
        return (
          el.attributes.area === areaName &&
          el.attributes['buyer-type'] === buyerType
        );
      });
      return productPrice[0];
    },
    debounceSearchProduct(value) {
      this.keywordProduct = value;
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        search: this.keywordProduct,
      });
    },
    clearDataProduct() {
      this.isSubmitted = false;
      this.dataProduct = {
        id: '',
        kode_barang: '',
        nama_barang: '',
        volume: '',
        supplier: {
          id_supplier: '',
          kode_supplier: '',
          nama_supplier: '',
        },
        unit: {
          id: '',
          name: '',
        },
        supplier_price: '',
        supplier_free_price: '',
        point_value: '',
        bonus_value: '',
        status_point: false,
        berat: '',
        product_category: {
          id: '',
          name: '',
        },
        nomor_izin: '',
        dinas: '',
        mulai_izin: '',
        akhir_izin: '',
        status_izin: true,
        pp: {
          ...clone(this.orderLimit),
          stockist: clone(this.preOrderLimit),
          kp: clone(this.preOrderLimit),
          sc: clone(this.preOrderLimit),
        },
        kp: {
          ...clone(this.orderLimit),
          stockist: clone(this.preOrderLimit),
          sc: clone(this.preOrderLimit),
        },
        sc: {
          ...clone(this.orderLimit),
          stockist: clone(this.preOrderLimit),
          sc: clone(this.preOrderLimit),
        },
        harga_wil0_stockist: null,
        harga_wil0_distributor: null,
        harga_wil0_konsumen: null,
        harga_wil1_stockist: null,
        harga_wil1_distributor: null,
        harga_wil1_konsumen: null,
        harga_wil2_stockist: null,
        harga_wil2_distributor: null,
        harga_wil2_konsumen: null,
        harga_wil3_stockist: null,
        harga_wil3_distributor: null,
        harga_wil3_konsumen: null,
        harga_wil4_stockist: null,
        harga_wil4_distributor: null,
        harga_wil4_konsumen: null,
        harga_karyawan: null,
        harga_retur: null,
      };
    },
    onPrevStep() {
      this.activeStep = this.activeStep - 1;
    },
    onNextStep({ validate }) {
      const response = validate();
      if (typeof response === 'object') {
        new Error('Form belum lengkap!');
      } else {
        this.activeStep = this.activeStep + 1;
      }
    },
  },
};
</script>
