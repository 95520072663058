<template>
  <div>
    <datatable :columns="columns" :scroll-x="false">
      <template #tbody="{ classes }">
        <tr v-for="(productBonus, index) in productBonuses" :key="index" :class="[classes.tr]">
          <td
            :class="[classes.td, 'relative']"
          >
            <input
              type="text"
              class="block border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Kode barang"
              @change="onSearchProduct(index)"
              v-model="productBonuses[index].searchProduct"
            />
            <button
              type="button"
              class="absolute inset-y-0 top-0 right-0 flex cursor-pointer items-center pl-3"
              @click="onOpenProductModal(index)"
            >
              <Icon
                class="h-5 w-5 text-gray-500 hover:text-gray-700"
                icon="heroicons:magnifying-glass-circle-20-solid"
              />
            </button>
          </td>
          <td :class="classes.td">
            {{ productBonus.productName }}
          </td>
          <td :class="[classes.td, 'w-52']">
            <input
              v-if="productBonus.id"
              type="text"
              name="kode_barang"
              class="block w-full border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Jumlah"
              v-model="productBonuses[index].inputQty"
              @change="onChangeQty(index)"
            />
          </td>
          <td :class="[classes.td, 'w-10']">
            <base-button
              v-if="productBonus.id"
              type="button"
              class="text-red-300 hover:text-red-600"
              tabindex="-1"
              for-icon
              @click="onDelete(index)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </base-button>
          </td>
        </tr>
      </template>
    </datatable>
    <teleport to="body">
      <product-modal
        :visible="productModal.visible"
        @page-change="onProductModalChangePage"
        @change="onProductModalChangeProduct"
        @search="onProductModalSearchProduct"
        @close="productModal.visible = false"
      />
    </teleport>
    <teleport to="body">
      <loading v-if="loading" />
    </teleport>
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request'
import { mapActions } from 'vuex';
import ProductModal from '@/components/modal/product-modal.vue';
import Teleport from 'vue2-teleport';

export default {
  mixins: [requestMixin],
  components: { ProductModal, Teleport },
  props: {
    productBundle: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      productModal: {
        visible: false,
        search: null
      },
      productBonuses: [
        {
          searchProduct: null,
          productName: null
        }
      ]
    }
  },
  computed: {
    columns() {
      return [
        { id: 'code', name: 'Kode Barang' },
        { id: 'name', name: 'Nama Barang' },
        { id: 'qty', name: 'Jumlah Bonus' },
        { id: 'action', name: 'Aksi' }
      ];
    },
    multipleProduct() {
      return this.productBundle.data.attributes.bonus_type === 'multiple' || this.productBundle.data.attributes.bonus_type === 'choose'
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
      fetchProducts: 'products/fetchProducts',
    }),
    addEmptyProduct() {
      this.productBonuses.push({     
        id: null,   
        productCode: null,
        productName: null,
        qty: null,
        searchProduct: null,
        inputQty: null
      })
    },
    async createProductBonus(product) {
      return await this.request('/api/v1/product-bundle-bonuses', {
        method: 'post',
        data: {
          data: {
            type: 'product-bundle-bonuses',
            attributes: {
              qty: 1
            },
            relationships: {
              'product-bundle': {
                data: {
                  type: 'product-bundles',
                  id: this.productBundle.data.id
                }
              },
              product: {
                data: {
                  type: 'products',
                  id: product.id
                }
              }
            }
          }
        }
      })
    },
    async loadProductModal(params = {}) {
      this.loading = true

      await this.fetchProducts({
        'page[size]': 5,
        'filter[search]': this.productModal.search,
        'include': 'product-category,unit',
        ...params
      })

      this.loading = false
    },
    async onChangeQty(index) {
      this.loading = true

      const productBonus = this.productBonuses[index]

      const [, error] = await this.request(`/api/v1/product-bundle-bonuses/${productBonus.id}`, {
        method: 'patch',
        data: {
          data: {
            type: 'product-bundle-bonuses',
            id: productBonus.id,
            attributes: {
              qty: parseInt(productBonus.inputQty)
            }
          }
        }
      })

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
        });

        this.productBonuses[index].inputQty = productBonus.qty
      } else {
        this.productBonuses[index].qty = productBonus.inputQty
      }

      this.loading = false
    },
    async onDelete(index) {
      this.loading = true

      const productBonus = this.productBonuses[index]

      const [, error] = await this.request(`/api/v1/product-bundle-bonuses/${productBonus.id}`, {
        method: 'delete'
      })

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
        });
      } else {
        this.productBonuses.splice(index, 1)

        if (!this.multipleProduct) {
          this.addEmptyProduct()
        }
      }

      this.loading = false
    },
    onOpenProductModal(index) {
      this.productModal.search = null
      this.productModal.index = index
      this.productModal.visible = true

      this.loadProductModal()
    },
    onProductModalChangePage(page) {
      this.loadProductModal({
        'page[number]': page
      })
    },
    onProductModalSearchProduct(q) {
      this.productModal.search = q

      this.loadProductModal()
    },
    async onProductModalChangeProduct(product) {
      this.productBonuses[this.productModal.index].searchProduct = product.attributes.code

      const [res] = await this.storeProduct(product, this.productModal.index)

      if (res) {
        this.productModal.visible = false
      }
    },
    async onSearchProduct(index) {
      this.loading = true

      const products = await this.fetchProducts({
        'page[size]': 1,
        'filter[search]': this.productBonuses[index].searchProduct,
        'include': ''
      })

      if (products && products.data.data.length) {
        const product = products.data.data[0]

        await this.storeProduct(product, index)
      } else {
        this.productBonuses[index].searchProduct = this.productBonuses[index].productCode
      }

      this.loading = false
    },
    setProductBonuses() {
      this.productBonuses = this.getIncludedByType(this.productBundle, 'product-bundle-bonuses')
        .map(bonus => ({
          id: bonus.id,
          productCode: bonus.attributes.product_code,
          productName: bonus.attributes.product_name,
          qty: bonus.attributes.qty,
          searchProduct: bonus.attributes.product_code,
          inputQty: bonus.attributes.qty,
        }))
      
      if (this.multipleProduct || !this.productBonuses.length) {
        this.addEmptyProduct()
      }
    },
    setProductBonus(product, index) {
      this.productBonuses[index].id = product.data.id
      this.productBonuses[index].productCode = this.productBonuses[index].searchProduct
      this.productBonuses[index].productName = product.data.attributes.product_name
    },
    async storeProduct(product, index) {
      this.loading = true

      const productBonus = this.productBonuses[index]

      const [res, error] = productBonus.id ? await this.updateProductBonus(product, index) : await this.createProductBonus(product, index)

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
        });

        this.productBonuses[index].searchProduct = productBonus.productCode
      } else {
        this.setProductBonus(res, index)
        
        if (this.multipleProduct && index === this.productBonuses.length - 1) {
          this.addEmptyProduct()
        }
      }

      this.loading = false

      return [res, error]
    },
    async updateProductBonus(product, index) {
      const productBonus = this.productBonuses[index]

      return await this.request(`/api/v1/product-bundle-bonuses/${productBonus.id}`, {
        method: 'patch',
        data: {
          data: {
            type: 'product-bundle-bonuses',
            id: productBonus.id,
            attributes: {},
            relationships: {
              product: {
                data: {
                  type: 'products',
                  id: product.id
                }
              }
            }
          }
        }
      })
    },
  },
  created() {
    this.setProductBonuses()
  }
}
</script>
