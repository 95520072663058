<template>
  <base-modal size="max-w-xl" :showing="visible" @close="$emit('close')">
    <base-qr-scan
      v-if="withCameraScan"
      :config="config"
      :play-sound-on-scanned="playSoundOnScanned"
      :dynamic-qr-box="dynamicQrBox"
      v-on:scanned="handleQrScaned"
    />
    <div class="mt-4">
      <slot name="bottom" />
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal';
import BaseQrScan from '@/components/base/BaseQrScan';

export default {
  components: {
    BaseModal,
    BaseQrScan,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    config: Object,
    playSoundOnScanned: Boolean,
    dynamicQrBox: Boolean,
    withCameraScan: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['scanned', 'close'],
  methods: {
    handleQrScaned(code) {
      this.$emit('scanned', code);
    },
  },
};
</script>
