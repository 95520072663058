<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            placeholder="Cari Kode"
            v-model="search"
            v-on:input="onSearchStockist"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <StockistTable
              @page-change="onPageChangeStockist"
              @click-row="onClickDetailStockist"
              @promote="onOpenPromote"
            />
          </div>
        </div>

        <office-detail-modal
          v-model="openDetail"
          @update="() => (openDetail = !openDetail)"
        />
      </div>
    </div>

    <Modal
      :showing="modalType === 'promoteToSC'"
      @close="modalType = null"
      :showClose="modalType === 'promoteToSC'"
      :backgroundClose="true"
      size="max-w-xl"
    >
      <form @submit.prevent="onPromoteStockistToSC">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg font-medium leading-6 text-gray-900"
              id="modal-title"
            >
              Promosi Kantor
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Lengkapi form dibawah ini untuk melakukan promosi
              </p>
            </div>
          </div>
          <div class="my-4 space-y-3">
            <div class="space-y-3">
              <div class="field-inset-default w-full">
                <label for="code" class="block text-xs font-bold text-gray-700">
                  Kode Kantor
                </label>
                <input
                  v-model="promote.code"
                  type="text"
                  name="code"
                  id="code"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan kode kantor (kode kantor harus berbeda)"
                />
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="email"
                  class="block text-xs font-bold text-gray-700"
                >
                  Email
                </label>
                <input
                  v-model="promote.email"
                  type="email"
                  name="email"
                  id="email"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan email baru (contoh: memberbaru@naturalnusantara.co.id)"
                />
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="no_account_gl"
                  class="block text-xs font-bold text-gray-700"
                >
                  No Account GL
                </label>
                <input
                  v-model="promote.no_account_gl"
                  type="text"
                  name="no_account_gl"
                  id="no_account_gl"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan No Account GL baru (contoh: 2342ABFDS"
                />
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="cost_center"
                  class="block text-xs font-bold text-gray-700"
                >
                  Cost Center
                </label>
                <input
                  v-model="promote.cost_center"
                  type="text"
                  name="cost_center"
                  id="cost_center"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan No Cost Center (wajib)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
          <base-button type="submit" fullwidth> Promosi </base-button>
          <base-button
            @click="modalType = null"
            fullwidth
            color="white"
            type="button"
          >
            Tutup
          </base-button>
        </div>
      </form>
    </Modal>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Modal from '@/components/base/BaseModal';
import BaseSearch from '@/components/base/Search.vue';
import OfficeDetailModal from '@/components/office/office-detail-modal.vue';
import StockistTable from '@/components/stockist/StockistTable.vue';

export default {
  name: 'Home',
  components: {
    Modal,
    BaseSearch,
    OfficeDetailModal,
    StockistTable,
  },
  data() {
    return {
      modalType: '',
      selectedStockistToPromoteId: null,
      search: null,
      openDetail: false,
      promote: {
        code: null,
        email: null,
        no_account_gl: null,
        cost_center: null,
      },
      detailStockistId: null,
    };
  },
  created() {
    this.loadStockist();

    const handleEscape = () => {
      this.openDetail = false;
      this.search = '';
    };

    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getSimpleOfficesLoading: 'simple_offices/getLoading',
      getOfficesLoading: 'offices/getLoading',
    }),
    isLoading() {
      return this.getSimpleOfficesLoading || this.getOfficesLoading;
    },
  },
  methods: {
    ...mapActions({
      fetchUsersByOffice: 'users/fetchUsersByOffice',
      fetchOfficeById: 'offices/fetchOfficeById',
      promoteToSC: 'offices/promoteToSC',
      updateUser: 'users/updateUser',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
    }),
    onActiveUserChange(user) {
      const payload = {
        data: {
          type: 'users',
          id: user.id,
          attributes: {
            is_suspended: !user.attributes.is_suspended,
          },
        },
      };
      this.updateUser(payload);
    },
    onSearchStockist: debounce(function () {
      this.loadStockist();
    }, 300),
    onPageChangeStockist(page) {
      this.loadStockist(page);
    },
    async onClickDetailStockist(office) {
      this.detailStockistId = office.attributes.office_id;

      await Promise.all([
        this.fetchOfficeById({
          office_id: this.detailStockistId,
          params: {
            include: 'addresses,area',
          },
        }),
        this.fetchUsersByOffice({
          officeId: this.detailStockistId,
        }),
      ]);

      this.openDetail = !this.openDetail;
    },
    onPromoteStockistToSC() {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            code: this.promote.code,
            email: this.promote.email,
            no_account_gl: this.promote.no_account_gl,
            cost_center: this.promote.cost_center,
          },
        },
      };
      this.promoteToSC({
        payload,
        id: this.selectedStockistToPromoteId,
      }).then((response) => {
        if (response) {
          this.modalType = null;
          this.search = null;

          this.loadStockist();
        } else {
          this.modal = 'error';
        }
      });
    },
    onOpenPromote(data) {
      this.modalType = 'promoteToSC';
      this.isModalOpen = true;
      this.selectedStockistToPromoteId = data.attributes.office_id;
    },
    async loadStockist(params) {
      await this.fetchSimpleOffices({
        ...params,
        'fields[simple-offices]': 'code,name,office,office_id',
        'fields[offices]':
          'bank_account_number,phone,address,email,is_promoted,promoted_by',
        include: 'office',
        'filter[search]': this.search,
        'filter[office_category_id]': 4,
        'page[limit]': 5,
      });
    },
  },
};
</script>
