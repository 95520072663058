<template>
  <section class="transition duration-300 ease-in-out">
    <loading v-if="getLoading" />
    <div v-if="banks" class="space-y-8 divide-y divide-gray-200">
      <div class="flex justify-between">
        <div>
          <h3 class="text-lg font-medium text-gray-900">
            Pengaturan Bank Mitra
          </h3>
          <p class="text-sm text-gray-500">
            Atur bank yang akan digunakan untuk pencarian bonus
          </p>
        </div>
        <button
          v-on:click="handleShowAddBankModal"
          class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Tambah Bank Mitra
        </button>
      </div>
      <div class="gap-8 py-8 sm:grid sm:grid-cols-3">
        <div
          v-for="bank in banks"
          :key="bank.id"
          class="flex items-start space-x-4"
        >
          <div>
            <input
              :checked="true"
              disabled
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600"
            />
          </div>
          <div class="space-y-1">
            <div class="mt-0.5 flex items-start space-x-4">
              <h4 class="text-sm font-medium text-gray-900">
                {{ bank.attributes.name }}
              </h4>
              <button @click="handleDelete(bank.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="my-4 flex items-center justify-center space-y-2">
        <div class="space-y-2">
          <div class="mb-2 flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          </div>
          <p class="text-center text-xs font-normal text-gray-400">
            Metode Pembayaran belum diatur
          </p>
          <button
            @click="modal = 'addMetodePembayaran'"
            class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Atur Metode Pembayaran
          </button>
        </div>
      </div>
    </div>

    <!-- Modal Add Bank -->
    <BaseModal
      :showing="addBankModal.visible"
      @close="handleCloseAddBankModal"
      :showClose="addBankModal.visible"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <form @submit.prevent="handleSubmitAddBankModalForm" id="modal-add">
        <div>
          <div class="flex justify-between border-b border-gray-200 pb-5">
            <div>
              <h3 class="text-lg font-semibold leading-6 text-gray-900">
                Tambah Bank Mitra
              </h3>
            </div>
          </div>
          <div class="pt-5">
            <base-input label="Pilih Bank" inset with-label fullwidth>
              <base-select
                :options="selectBankOptions"
                inset
                expand
                custom-height="auto"
                v-model="addBankModal.form.bank_id"
              />
            </base-input>
          </div>
        </div>
        <div class="pt-5">
          <div class="flex justify-end">
            <button
              type="submit"
              id="simpan_supplier"
              class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
            >
              Simpan
            </button>
            <button
              @click="handleClearAddBankModalForm"
              id="batal_supplier"
              type="button"
              class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
            >
              Batal
            </button>
            <button
              @click="handleCloseAddBankModal"
              id="selesai_supplier"
              class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
            >
              Selesai
            </button>
          </div>
        </div>
      </form>
    </BaseModal>

    <!-- Error Modal -->
    <BaseErrorModal :message="getError" v-model="addBankModal.visibleError" />

    <!-- Delete COnfirm -->
    <transition name="fade">
      <div
        v-if="deleteBankModal.visible"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal :actions="false">
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Konfirmasi Penghapusan
            </h3>
          </template>
          <template v-slot:actions>
            <button
              @click="handleConfirmDelete"
              class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
            >
              Konfirmasi
            </button>
            <button
              @click="deleteBankModal.visible = false"
              class="focus:outline-none inline-flex w-full justify-center rounded-md border px-4 py-2 font-medium shadow-sm focus:ring-2 focus:ring-offset-2 sm:text-sm"
            >
              Batal
            </button>
          </template>
        </modal>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseErrorModal from '@/components/base/BaseErrorModal.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import Modal from '@/components/Modal';

export default {
  components: {
    Loading,
    BaseModal,
    BaseErrorModal,
    BaseInput,
    BaseSelect,
    Modal
  },
  data: function () {
    return {
      banks: [],
      addBankModal: {
        visible: false,
        visibleError: false,
        form: {
          bank_id: null
        },
      },
      deleteBankModal: {
        visible: false,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getBanks: 'banks/getBanks',
      getLoading: 'banks/getLoading',
      getError: 'banks/getError',
    }),
    selectBankOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Bank',
          value: null,
        },
        ...this.getBanks.data.map((bank) => ({
          key: bank.id,
          label: bank.attributes.name,
          value: bank.id,
        })),
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchBanks: 'banks/fetchBanks',
      updateBank: 'banks/updateBank',
    }),
    loadBanksFromState: function () {
      this.banks = this.getBanks.data;
    },
    loadBanks: function () {
      this.fetchBanks({
        'filter[is_mitra_bank]': true,
      }).then(() => {
        this.loadBanksFromState();
      });
    },
    handleDelete(id) {
      this.deleteBankModal.visible = true;
      this.deleteBankModal.id = id;
    },
    handleConfirmDelete() {
      this.updateBank({
        data: {
          type: 'banks',
          id: this.deleteBankModal.id,
          attributes: {
            is_mitra_bank: false,
          },
        },
      }).then(() => {
        this.loadBanks()

        this.deleteBankModal.visible = false;
        this.deleteBankModal.id = null;
      });
    },
    handleShowAddBankModal: function () {
      this.handleClearAddBankModalForm();

      this.fetchBanks({
        'filter[is_mitra_bank]': false,
      });

      this.addBankModal.visible = true;
    },
    handleCloseAddBankModal: function () {
      this.loadBanks();

      this.addBankModal.visible = false;
    },
    handleClearAddBankModalForm: function () {
      for (const prop in this.addBankModal.form) {
        this.addBankModal.form[prop] = null;
      }
    },
    handleSubmitAddBankModalForm: function () {
      this.updateBank({
        data: {
          type: 'banks',
          id: this.addBankModal.form.bank_id,
          attributes: {
            is_mitra_bank: true,
          },
        },
      }).then((res) => {
        if (res) {
          this.handleCloseAddBankModal();
        } else {
          this.addBankModal.visibleError = true;
        }
      });
    },
  },
  mounted: function () {
    this.loadBanksFromState();
    this.loadBanks();
  },
};
</script>
