<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Tambah Permission
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Daftar Permission Yang Belum Ditambahkan
            </p>
          </div>
        </div>
      </div>
      <div class="mt-6 space-y-4 border-t border-gray-200 pt-6">
        <div :class="['flex items-center justify-end']">
          <div
            v-if="checkedPermissionIds.length"
            class="flex items-center gap-x-4"
          >
            <span class="text-xs text-gray-400"
              >{{ checkedPermissionIds.length }} Dipilih</span
            >
            <base-button size="sm" @click="onSave">Simpan</base-button>
          </div>
        </div>
        <datatable
          :columns="columns"
          :scroll-x="false"
          :total="permissions.meta.page.total"
          :perPage="permissions.meta.page.perPage"
          :currentPage="permissions.meta.page.currentPage"
          @pagechanged="onPagechanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="permission in permissions.data"
              :key="permission.id"
              :class="[classes.tr, 'hover:bg-gray-50']"
            >
              <td :class="[classes.td]" style="width: 1px">
                <base-checkbox
                  :with-label="false"
                  :input-value="permission.id"
                  v-model="checkedPermissionIds"
                />
              </td>
              <td :class="[classes.td]">{{ permission.attributes.name }}</td>
              <td :class="[classes.td]">
                {{ permission.attributes.description }}
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';

export default {
  mixins: [requestMixin],
  components: { BaseModal, BaseCheckbox },
  props: {
    visible: Boolean,
    menuId: String,
    roleId: String,
  },
  emits: ['close', 'attach-permission'],
  data() {
    return {
      loading: false,
      permissions: {
        data: [],
        meta: {
          page: {},
        },
      },
      checkedPermissionIds: [],
    };
  },
  computed: {
    columns() {
      return [
        { id: 'check', name: '' },
        { id: 'name', name: 'Nama' },
        { id: 'description', name: 'Deskripsi' },
      ];
    },
  },
  methods: {
    async onOpened() {
      this.loading = true;

      await this.loadPermissions();

      this.loading = false;
    },
    async onSave() {
      this.loading = true;

      await this.request(
        `/api/v1/roles/${this.roleId}/-actions/attach-permissions`,
        {
          method: 'post',
          data: {
            permission_uuids: this.checkedPermissionIds,
          },
        }
      );

      this.$emit('close', { reload: true });

      this.loading = false;
    },
    async loadPermissions(params) {
      const [res, error] = await this.request(
        `/api/v1/menus/${this.menuId}/permissions`,
        {
          params: {
            'page[size]': 5,
            'filter[not_owned_by_role_id]': this.roleId,
            ...params,
          },
        }
      );

      if (!error) {
        this.permissions = res;
        this.checkedPermissionIds = [];
      }

      return [res, error];
    },
    async onPagechanged(page) {
      this.loading = true;

      await this.loadPermissions({
        'page[number]': page,
      });

      this.loading = false;
    },
  },
};
</script>
