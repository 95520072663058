<template>
  <div>
    <section id="laporan-penjualan-pusat" class="space-y-4 px-8">
      <div class="space-y-6 rounded-lg bg-white p-6 shadow">
        <div v-for="setup in setups" :key="setup.key" class="flex gap-x-2">
          <base-input
            type="text"
            :label="setup.name"
            :placeholder="setup.name"
            with-label
            fullwidth
            :shadow="false"
            disabled
            v-model="setup.value"
            currency
          />
          <base-button @click="handleClickEditSetup(setup)">Edit</base-button>
        </div>
      </div>
    </section>

    <base-modal :showing="editModal.visible" @close="handleCloseEditModal">
      <div class="space-y-6">
        <div class="flex items-center space-x-4 border-b pb-4">
          <h3 class="text-lg font-bold leading-6 text-gray-900">Edit Setup</h3>
        </div>
        <form class="space-y-4" @submit.prevent="handleSubmitEdit">
          <base-input
            type="text"
            :label="editModal.setup.label"
            :placeholder="editModal.setup.label"
            with-label
            fullwidth
            :shadow="false"
            v-model="editModal.setup.value"
            currency
          />
          <div class="flex justify-end gap-x-2">
            <base-button
              type="button"
              color="danger"
              @click="handleCloseEditModal"
              >Batal</base-button
            >
            <base-button>Simpan</base-button>
          </div>
        </form>
      </div>
    </base-modal>
    <loading v-if="getLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseModal from '@/components/base/BaseModal.vue';

export default {
  components: { BaseInput, BaseButton, BaseModal },
  data() {
    return {
      setups: [],
      editModal: {
        visible: false,
        setup: {
          id: null,
          label: null,
          value: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getSetups: 'setups/getSetups',
      getLoading: 'setups/getLoading',
    }),
    setupKeysAvailable() {
      return [
        { key: 'ppn', label: 'PPN' },
        { key: 'min_order_spend_for_sc', label: 'Min Penjualan ke SC' },
        {
          key: 'min_order_spend_for_stockist',
          label: 'Min Penjualan ke Stockist',
        },
        {
          key: 'max_order_for_kp_and_stockist',
          label: 'Max Penjualan ke KP dan Stockist',
        },
        {
          key: 'max_payment_cash_for_kp_and_stockist',
          label: 'Max Pembayaran Cash Untuk KP dan Stockist',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchSetups: 'setups/fetchSetups',
      updateSetup: 'setups/updateSetup',
      createAlert: 'alert/createAlert',
    }),
    async loadSetups() {
      await this.fetchSetups();

      this.setups = this.getSetups.data
        .filter(
          (setup) =>
            this.setupKeysAvailable.findIndex(
              (setupAvailable) =>
                setupAvailable.key === setup.attributes.setup_key
            ) !== -1
        )
        .map((setup) => ({
          id: setup.id,
          key: setup.attributes.setup_key,
          value: setup.attributes.setup_value,
          name: this.setupKeysAvailable.find(
            (setupAvailable) =>
              setupAvailable.key === setup.attributes.setup_key
          ).label,
        }));
    },
    resetEditModalSetup() {
      this.editModal.setup.id = null;
      this.editModal.setup.label = null;
      this.editModal.setup.value = null;
    },
    handleCloseEditModal() {
      this.resetEditModalSetup();

      this.editModal.visible = false;
    },
    handleClickEditSetup(setup) {
      this.editModal.setup.id = setup.id;
      this.editModal.setup.label = setup.name;
      this.editModal.setup.value = setup.value;

      this.editModal.visible = true;
    },
    async handleSubmitEdit() {
      const res = await this.updateSetup({
        data: {
          type: 'setups',
          id: this.editModal.setup.id,
          attributes: {
            setup_value: this.editModal.setup.value.replace(/\D/g, ''),
          },
        },
      });

      if (res) {
        this.resetEditModalSetup();

        this.editModal.visible = false;

        this.createAlert({ data: 'Setup diperbarui' });
        this.loadSetups();
      }
    },
  },
  created() {
    this.loadSetups();
  },
};
</script>
