<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="flex flex-col">
      <div class="flex items-center justify-end space-x-2">
        <base-search v-model="search" @input="onSearch" />
        <base-button @click="onCreate" type="button" id="add_KantorPelayanan">
          <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
          Tambah Kantor Pelayanan
        </base-button>
      </div>
      <div class="my-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Datatable
            :total="getSimpleOffices.meta.page.total"
            :perPage="getSimpleOffices.meta.page.perPage"
            :currentPage="getSimpleOffices.meta.page.currentPage"
            @pagechanged="onPageChange"
            :meta="getSimpleOffices.meta"
            cursor
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kode Kantor Pelayanan
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Nama Kantor Pelayanan
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Area
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    No. Rekening
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Alamat / No. Telp
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody v-if="getSimpleOffices.data?.length > 0">
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getSimpleOffices.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="onClickDetail(data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <div class="font-medium text-gray-900">
                      {{ data.attributes.name }}
                    </div>
                    <div class="text-gray-500">
                      {{ getOfficeIncluded(data).attributes.email }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.area_name ?? '-' }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      getOfficeIncluded(data).attributes.bank_account_number ??
                      '-'
                    }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <div>
                      <p>
                        {{ getOfficeIncluded(data).attributes.address ?? '-' }}
                      </p>
                      <p class="text-xs">
                        {{ getOfficeIncluded(data).attributes.phone ?? '-' }}
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="bg-white">
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    colspan="6"
                  >
                    Data tidak ditemukan
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
    <loading v-if="getLoading || isLoading || getSimpleOfficeLoading" />
    <MasterKpFormModal
      :visible="visibleCreateModal"
      @close="visibleCreateModal = false"
      @success="onRefresh"
    />
    <office-detail-modal
      view-as="kantor-pelayanan"
      v-model="openDetail"
      @update="() => (openDetail = false)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import OfficeDetailModal from '@/components/office/office-detail-modal.vue';
import MasterKpFormModal from '@/components/admin/master/kp/MasterKpFormModal.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
    BaseButton,
    OfficeDetailModal,
    MasterKpFormModal,
  },
  data() {
    return {
      search: null,
      isLoading: false,
      openDetail: false,
      visibleCreateModal: false,
    };
  },
  created() {
    this.loadKantorPelayanan();

    const handleEscape = () => {
      this.openDetail = false;
      this.search = '';
    };

    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getLoading: 'kantor_pelayanan/getLoading',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getSimpleOfficeLoading: 'simple_offices/getLoading',
    }),
  },
  methods: {
    ...mapActions({
      fetchUsersByOffice: 'users/fetchUsersByOffice',
      fetchOfficeById: 'offices/fetchOfficeById',
      updateOffice: 'offices/updateOffice',
      updateUser: 'users/updateUser',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
    }),
    onSearch: debounce(function () {
      this.loadKantorPelayanan();
    }, 300),
    onPageChange(page) {
      this.loadKantorPelayanan(page);
    },
    async onClickDetail(simpleOffice) {
      this.isLoading = true;

      const office = this.getOfficeIncluded(simpleOffice);

      await Promise.all([
        this.fetchOfficeById({
          office_id: office.id,
          params: {
            include: 'addresses,area',
          },
        }),
        this.fetchUsersByOffice({
          officeId: office.id,
        }),
      ]);

      this.isLoading = false;

      this.openDetail = !this.openDetail;
    },
    onRefresh() {
      this.search = '';
      this.loadKantorPelayanan();
    },
    onActiveUserChange(user) {
      const payload = {
        data: {
          type: 'users',
          id: user.id,
          attributes: {
            is_suspended: !user.attributes.is_suspended,
          },
        },
      };
      this.updateUser(payload);
    },
    onCreate() {
      this.visibleCreateModal = true;
    },
    async loadKantorPelayanan(params) {
      await this.fetchSimpleOffices({
        ...params,
        'page[limit]': 5,
        'filter[office_category_id]': 2,
        include: 'office',
        'fields[simple-offices]': 'code,name,office',
        'fields[offices]': 'email,bank_account_number,area,address,phone',
        'filter[search]': this.search,
      });
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.getSimpleOffices,
        simpleOffice.relationships.office.data.id
      );
    },
  },
};
</script>
