<template>
  <div class="mb-5 rounded-lg bg-white py-4 transition-all duration-500">
    <div class="space-y-4 divide-y px-4">
      <div
        v-if="statusOrderVisible"
        class="divide-y transition-all duration-500"
      >
        <div class="py-5">
          <div class="flex items-center justify-between">
            <h1 class="mt-2 text-base font-bold text-gray-700">
              Penjualan Pusat
            </h1>
            <toggle-button
              v-model="value.pp.is_orderable"
              :labels="{
                checked: 'Open',
                unchecked: 'Close',
              }"
              :width="60"
            />
          </div>
          <div v-if="value.pp.is_orderable" class="py-4">
            <p class="text-sm">Limit Order Barang</p>
            <div class="sm:col-span-12">
              <div class="my-3 block text-sm text-gray-700">
                <input
                  v-model="value.pp.is_limit_order"
                  @change="changeLimitOrder('pp')"
                  type="radio"
                  name="pp_limit"
                  :value="false"
                  class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                />
                Unlimited
              </div>
              <div class="block text-sm text-gray-700">
                <input
                  v-model="value.pp.is_limit_order"
                  @change="changeLimitOrder('pp')"
                  type="radio"
                  name="pp_limit"
                  :value="true"
                  class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                />
                <input
                  type="text"
                  v-model="value.pp.limit_order_periodic"
                  ref="ppLimitOrder"
                  class="border-0 px-1 pt-0 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Batas"
                  v-currency
                />
              </div>
              <div v-if="value.pp.is_limit_order" class="pt-4">
                <div>
                  <input
                    v-model="value.pp.is_custom_date_order"
                    @change="changeCustomDate('pp')"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                  />
                  <label class="px-2 text-sm text-gray-700"
                    >Periode Manual</label
                  >
                </div>
                <div
                  v-if="value.pp.is_custom_date_order"
                  class="flex mt-4 sm:flex-row sm:gap-x-4"
                >
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Awal
                    </label>
                    <input
                      v-model="value.pp.custom_order_start"
                      type="date"
                      name="name"
                      id="name"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Akhir
                    </label>
                    <input
                      v-model="value.pp.custom_order_end"
                      type="date"
                      name="name"
                      id="name"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </div>
                </div>
                <div
                  class="field-inset-default my-4 w-full"
                  :class="value.pp.is_custom_date_order ? 'bg-gray-200' : null"
                >
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Limit Periodik
                  </label>
                  <select
                    v-model="value.pp.current_limit_order_periodic"
                    :disabled="value.pp.is_custom_date_order"
                    class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    :class="
                      value.pp.is_custom_date_order ? 'bg-gray-200' : null
                    "
                    placeholder="Masukkan Stok Barang"
                  >
                    <option :value="null">Pilih Periode</option>
                    <option value="daily">Harian</option>
                    <option value="weekly">Mingguan</option>
                    <option value="monthly">Bulanan</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex space-x-4 pt-4">
              <p class="text-gray-700">Pre-Order</p>
              <toggle-button
                v-model="value.pp.is_pre_orderable"
                :labels="{
                  checked: 'Open',
                  unchecked: 'Close',
                }"
                :width="60"
              />
            </div>
            <div
              v-if="value.pp.is_pre_orderable"
              class="flex mt-4 sm:flex-row sm:gap-x-4"
            >
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  Stockist
                </label>
                <p class="text-sm">Limit Order Barang</p>
                <div class="sm:col-span-12">
                  <div class="my-3 block text-sm text-gray-700">
                    <input
                      v-model="value.pp.stockist.is_limit_pre_order"
                      @change="changeLimitOrder('pp-stockist')"
                      type="radio"
                      name="pp_stockist_limit"
                      :value="false"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    Unlimited
                  </div>
                  <div class="block text-sm text-gray-700">
                    <input
                      v-model="value.pp.stockist.is_limit_pre_order"
                      @change="changeLimitOrder('pp-stockist')"
                      type="radio"
                      name="pp_stockist_limit"
                      :value="true"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    <input
                      type="text"
                      v-model="value.pp.stockist.limit_pre_order_periodic"
                      ref="ppStockistLimitOrder"
                      class="border-0 px-1 pt-0 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Batas"
                      v-currency
                    />
                  </div>
                  <div v-if="value.pp.stockist.is_limit_pre_order" class="pt-4">
                    <div>
                      <input
                        v-model="value.pp.stockist.is_custom_date_pre_order"
                        @change="changeCustomDate('pp-stockist')"
                        name="remember-me"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                      />
                      <label class="px-2 text-sm text-gray-700"
                        >Periode Manual</label
                      >
                    </div>
                    <div
                      v-if="value.pp.stockist.is_custom_date_pre_order"
                      class="flex mt-4 sm:flex-row sm:gap-x-4"
                    >
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Awal
                        </label>
                        <input
                          v-model="value.pp.stockist.custom_pre_order_start"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Akhir
                        </label>
                        <input
                          v-model="value.pp.stockist.custom_pre_order_end"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div
                      class="field-inset-default my-4 w-full"
                      :class="
                        value.pp.stockist.is_custom_date_pre_order
                          ? 'bg-gray-200'
                          : null
                      "
                    >
                      <label
                        for="name"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Limit Periodik
                      </label>
                      <select
                        v-model="
                          value.pp.stockist.current_limit_pre_order_periodic
                        "
                        :disabled="value.pp.stockist.is_custom_date_pre_order"
                        class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        :class="
                          value.pp.stockist.is_custom_date_pre_order
                            ? 'bg-gray-200'
                            : null
                        "
                        placeholder="Masukkan Stok Barang"
                      >
                        <option :value="null">Pilih Periode</option>
                        <option value="daily">Harian</option>
                        <option value="weekly">Mingguan</option>
                        <option value="monthly">Bulanan</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  Stockist Center
                </label>
                <p class="text-sm">Limit Order Barang</p>
                <div class="sm:col-span-12">
                  <div class="my-3 block text-sm text-gray-700">
                    <input
                      v-model="value.pp.sc.is_limit_pre_order"
                      @change="changeLimitOrder('pp-sc')"
                      type="radio"
                      name="pp_sc_limit"
                      :value="false"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    Unlimited
                  </div>
                  <div class="block text-sm text-gray-700">
                    <input
                      v-model="value.pp.sc.is_limit_pre_order"
                      @change="changeLimitOrder('pp-sc')"
                      type="radio"
                      name="pp_sc_limit"
                      :value="true"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    <input
                      type="text"
                      v-model="value.pp.sc.limit_pre_order_periodic"
                      ref="ppscLimitOrder"
                      class="border-0 px-1 pt-0 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Batas"
                    />
                  </div>
                  <div v-if="value.pp.sc.is_limit_pre_order" class="pt-4">
                    <div>
                      <input
                        v-model="value.pp.sc.is_custom_date_pre_order"
                        @change="changeCustomDate('pp-sc')"
                        name="remember-me"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                      />
                      <label class="px-2 text-sm text-gray-700"
                        >Periode Manual</label
                      >
                    </div>
                    <div
                      v-if="value.pp.sc.is_custom_date_pre_order"
                      class="flex mt-4 sm:flex-row sm:gap-x-4"
                    >
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Awal
                        </label>
                        <input
                          v-model="value.pp.sc.custom_pre_order_start"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Akhir
                        </label>
                        <input
                          v-model="value.pp.sc.custom_pre_order_end"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div
                      class="field-inset-default my-4 w-full"
                      :class="
                        value.pp.sc.is_custom_date_pre_order
                          ? 'bg-gray-200'
                          : null
                      "
                    >
                      <label
                        for="name"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Limit Periodik
                      </label>
                      <select
                        v-model="value.pp.sc.current_limit_pre_order_periodic"
                        :disabled="value.pp.sc.is_custom_date_pre_order"
                        class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        :class="
                          value.pp.sc.is_custom_date_pre_order
                            ? 'bg-gray-200'
                            : null
                        "
                        placeholder="Masukkan Stok Barang"
                      >
                        <option :value="null">Pilih Periode</option>
                        <option value="daily">Harian</option>
                        <option value="weekly">Mingguan</option>
                        <option value="monthly">Bulanan</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-5">
          <div class="flex items-center justify-between">
            <h1 class="mt-2 text-base font-bold text-gray-700">
              Kantor Pelayanan
            </h1>
            <toggle-button
              v-model="value.kp.is_orderable"
              :labels="{
                checked: 'Open',
                unchecked: 'Close',
              }"
              :width="60"
            />
          </div>
          <div v-if="value.kp.is_orderable" class="py-4">
            <p class="text-sm">Limit Order Barang</p>
            <div class="sm:col-span-12">
              <div class="my-3 block text-sm text-gray-700">
                <input
                  v-model="value.kp.is_limit_order"
                  @change="changeLimitOrder('kp')"
                  type="radio"
                  name="kp_limit"
                  :value="false"
                  class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                />
                Unlimited
              </div>
              <div class="block text-sm text-gray-700">
                <input
                  v-model="value.kp.is_limit_order"
                  @change="changeLimitOrder('kp')"
                  type="radio"
                  name="kp_limit"
                  :value="true"
                  class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                />
                <input
                  type="text"
                  v-model="value.kp.limit_order_periodic"
                  ref="kpLimitOrder"
                  class="border-0 px-1 pt-0 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Batas"
                />
              </div>
              <div v-if="value.kp.is_limit_order" class="pt-4">
                <div>
                  <input
                    v-model="value.kp.is_custom_date_order"
                    @change="changeCustomDate('kp')"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                  />
                  <label class="px-2 text-sm text-gray-700"
                    >Periode Manual</label
                  >
                </div>
                <div
                  v-if="value.kp.is_custom_date_order"
                  class="flex mt-4 sm:flex-row sm:gap-x-4"
                >
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Awal
                    </label>
                    <input
                      v-model="value.kp.custom_order_start"
                      type="date"
                      name="name"
                      id="name"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Akhir
                    </label>
                    <input
                      v-model="value.kp.custom_order_end"
                      type="date"
                      name="name"
                      id="name"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </div>
                </div>
                <div
                  class="field-inset-default my-4 w-full"
                  :class="value.kp.is_custom_date_order ? 'bg-gray-200' : null"
                >
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Limit Periodik
                  </label>
                  <select
                    v-model="value.kp.current_limit_order_periodic"
                    :disabled="value.kp.is_custom_date_order"
                    class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    :class="
                      value.kp.is_custom_date_order ? 'bg-gray-200' : null
                    "
                    placeholder="Masukkan Stok Barang"
                  >
                    <option :value="null">Pilih Periode</option>
                    <option value="daily">Harian</option>
                    <option value="weekly">Mingguan</option>
                    <option value="monthly">Bulanan</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex space-x-4 pt-4">
              <p class="text-gray-700">Pre-Order</p>
              <toggle-button
                v-model="value.kp.is_pre_orderable"
                :labels="{
                  checked: 'Open',
                  unchecked: 'Close',
                }"
                :width="60"
              />
            </div>
            <div
              v-if="value.kp.is_pre_orderable"
              class="flex mt-4 sm:flex-row sm:gap-x-4"
            >
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  Stockist
                </label>
                <p class="text-sm">Limit Order Barang</p>
                <div class="sm:col-span-12">
                  <div class="my-3 block text-sm text-gray-700">
                    <input
                      v-model="value.kp.stockist.is_limit_pre_order"
                      @change="changeLimitOrder('kp-stockist')"
                      type="radio"
                      name="kp_stockist_limit"
                      :value="false"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    Unlimited
                  </div>
                  <div class="block text-sm text-gray-700">
                    <input
                      v-model="value.kp.stockist.is_limit_pre_order"
                      @change="changeLimitOrder('kp-stockist')"
                      type="radio"
                      name="kp_stockist_limit"
                      :value="true"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    <input
                      type="text"
                      v-model="value.kp.stockist.limit_pre_order_periodic"
                      ref="kpStockistLimitOrder"
                      class="border-0 px-1 pt-0 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Batas"
                    />
                  </div>
                  <div v-if="value.kp.stockist.is_limit_pre_order" class="pt-4">
                    <div>
                      <input
                        v-model="value.kp.stockist.is_custom_date_pre_order"
                        @change="changeCustomDate('kp-stockist')"
                        name="remember-me"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                      />
                      <label class="px-2 text-sm text-gray-700"
                        >Periode Manual</label
                      >
                    </div>
                    <div
                      v-if="value.kp.stockist.is_custom_date_pre_order"
                      class="flex mt-4 sm:flex-row sm:gap-x-4"
                    >
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Awal
                        </label>
                        <input
                          v-model="value.kp.stockist.custom_pre_order_start"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Akhir
                        </label>
                        <input
                          v-model="value.kp.stockist.custom_pre_order_end"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div
                      class="field-inset-default my-4 w-full"
                      :class="
                        value.kp.stockist.is_custom_date_pre_order
                          ? 'bg-gray-200'
                          : null
                      "
                    >
                      <label
                        for="name"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Limit Periodik
                      </label>
                      <select
                        v-model="
                          value.kp.stockist.current_limit_pre_order_periodic
                        "
                        :disabled="value.kp.stockist.is_custom_date_pre_order"
                        class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        :class="
                          value.kp.stockist.is_custom_date_pre_order
                            ? 'bg-gray-200'
                            : null
                        "
                        placeholder="Masukkan Stok Barang"
                      >
                        <option :value="null">Pilih Periode</option>
                        <option value="daily">Harian</option>
                        <option value="weekly">Mingguan</option>
                        <option value="monthly">Bulanan</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  Stockist Center
                </label>
                <p class="text-sm">Limit Order Barang</p>
                <div class="sm:col-span-12">
                  <div class="my-3 block text-sm text-gray-700">
                    <input
                      v-model="value.kp.sc.is_limit_pre_order"
                      @change="changeLimitOrder('kp-sc')"
                      type="radio"
                      name="kp_sc_limit"
                      :value="false"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    Unlimited
                  </div>
                  <div class="block text-sm text-gray-700">
                    <input
                      v-model="value.kp.sc.is_limit_pre_order"
                      @change="changeLimitOrder('kp-sc')"
                      type="radio"
                      name="kp_sc_limit"
                      :value="true"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    <input
                      type="text"
                      v-model="value.kp.sc.limit_pre_order_periodic"
                      ref="kpscLimitOrder"
                      class="border-0 px-1 pt-0 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Batas"
                    />
                  </div>
                  <div v-if="value.kp.sc.is_limit_pre_order" class="pt-4">
                    <div>
                      <input
                        v-model="value.kp.sc.is_custom_date_pre_order"
                        @change="changeCustomDate('kp-sc')"
                        name="remember-me"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                      />
                      <label class="px-2 text-sm text-gray-700"
                        >Periode Manual</label
                      >
                    </div>
                    <div
                      v-if="value.kp.sc.is_custom_date_pre_order"
                      class="flex mt-4 sm:flex-row sm:gap-x-4"
                    >
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Awal
                        </label>
                        <input
                          v-model="value.kp.sc.custom_pre_order_start"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Akhir
                        </label>
                        <input
                          v-model="value.kp.sc.custom_pre_order_end"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div
                      class="field-inset-default my-4 w-full"
                      :class="
                        value.kp.sc.is_custom_date_pre_order
                          ? 'bg-gray-200'
                          : null
                      "
                    >
                      <label
                        for="name"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Limit Periodik
                      </label>
                      <select
                        v-model="value.kp.sc.current_limit_pre_order_periodic"
                        :disabled="value.kp.sc.is_custom_date_pre_order"
                        class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        :class="
                          value.kp.sc.is_custom_date_pre_order
                            ? 'bg-gray-200'
                            : null
                        "
                        placeholder="Masukkan Stok Barang"
                      >
                        <option :value="null">Pilih Periode</option>
                        <option value="daily">Harian</option>
                        <option value="weekly">Mingguan</option>
                        <option value="monthly">Bulanan</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-5">
          <div class="flex items-center justify-between">
            <h1 class="mt-2 text-base font-bold text-gray-700">
              Stockist Center
            </h1>
            <toggle-button
              v-model="value.sc.is_orderable"
              :labels="{
                checked: 'Open',
                unchecked: 'Close',
              }"
              :width="60"
            />
          </div>
          <div v-if="value.sc.is_orderable" class="py-4">
            <p class="text-sm">Limit Order Barang</p>
            <div class="sm:col-span-12">
              <div class="my-3 block text-sm text-gray-700">
                <input
                  v-model="value.sc.is_limit_order"
                  @change="changeLimitOrder('sc')"
                  type="radio"
                  name="sc_limit"
                  :value="false"
                  class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                />
                Unlimited
              </div>
              <div class="block text-sm text-gray-700">
                <input
                  v-model="value.sc.is_limit_order"
                  @change="changeLimitOrder('sc')"
                  type="radio"
                  name="sc_limit"
                  :value="true"
                  class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                  aria-labelledby="privacy-setting-1-label"
                  aria-describedby="privacy-setting-1-description"
                />
                <input
                  type="text"
                  v-model="value.sc.limit_order_periodic"
                  ref="scLimitOrder"
                  class="border-0 px-1 pt-0 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Batas"
                />
              </div>
              <div v-if="value.sc.is_limit_order" class="pt-4">
                <div>
                  <input
                    v-model="value.sc.is_custom_date_order"
                    @change="changeCustomDate('sc')"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                  />
                  <label class="px-2 text-sm text-gray-700"
                    >Periode Manual</label
                  >
                </div>
                <div
                  v-if="value.sc.is_custom_date_order"
                  class="flex mt-4 sm:flex-row sm:gap-x-4"
                >
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Awal
                    </label>
                    <input
                      v-model="value.sc.custom_order_start"
                      type="date"
                      name="name"
                      id="name"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Akhir
                    </label>
                    <input
                      v-model="value.sc.custom_order_end"
                      type="date"
                      name="name"
                      id="name"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    />
                  </div>
                </div>
                <div
                  class="field-inset-default my-4 w-full"
                  :class="value.sc.is_custom_date_order ? 'bg-gray-200' : null"
                >
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Limit Periodik
                  </label>
                  <select
                    v-model="value.sc.current_limit_order_periodic"
                    :disabled="value.sc.is_custom_date_order"
                    class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    :class="
                      value.sc.is_custom_date_order ? 'bg-gray-200' : null
                    "
                    placeholder="Masukkan Stok Barang"
                  >
                    <option :value="null">Pilih Periode</option>
                    <option value="daily">Harian</option>
                    <option value="weekly">Mingguan</option>
                    <option value="monthly">Bulanan</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex space-x-4 pt-4">
              <p class="text-gray-700">Pre-Order</p>
              <toggle-button
                v-model="value.sc.is_pre_orderable"
                :labels="{
                  checked: 'Open',
                  unchecked: 'Close',
                }"
                :width="60"
              />
            </div>
            <div
              v-if="value.sc.is_pre_orderable"
              class="flex mt-4 sm:flex-row sm:gap-x-4"
            >
              <div class="w-full">
                <label
                  for="name"
                  class="mb-4 block text-sm font-bold text-gray-700"
                >
                  Stockist
                </label>
                <p class="text-sm">Limit Order Barang</p>
                <div class="sm:col-span-12">
                  <div class="my-3 block text-sm text-gray-700">
                    <input
                      v-model="value.sc.stockist.is_limit_pre_order"
                      @change="changeLimitOrder('sc-stockist')"
                      type="radio"
                      name="sc_stockist_limit"
                      :value="false"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    Unlimited
                  </div>
                  <div class="block text-sm text-gray-700">
                    <input
                      v-model="value.sc.stockist.is_limit_pre_order"
                      @change="changeLimitOrder('sc-stockist')"
                      type="radio"
                      name="sc_stockist_limit"
                      :value="true"
                      class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                      aria-labelledby="privacy-setting-1-label"
                      aria-describedby="privacy-setting-1-description"
                    />
                    <input
                      type="text"
                      v-model="value.sc.stockist.limit_pre_order_periodic"
                      ref="scStockistLimitOrder"
                      class="border-0 px-1 pt-0 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Batas"
                    />
                  </div>
                  <div v-if="value.sc.stockist.is_limit_pre_order" class="pt-4">
                    <div>
                      <input
                        v-model="value.sc.stockist.is_custom_date_pre_order"
                        @change="changeCustomDate('sc-stockist')"
                        name="remember-me"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                      />
                      <label class="px-2 text-sm text-gray-700"
                        >Periode Manual</label
                      >
                    </div>
                    <div
                      v-if="value.sc.stockist.is_custom_date_pre_order"
                      class="flex mt-4 sm:flex-row sm:gap-x-4"
                    >
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Awal
                        </label>
                        <input
                          v-model="value.sc.stockist.custom_pre_order_start"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                      <div class="field-inset-default w-full">
                        <label
                          for="name"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Akhir
                        </label>
                        <input
                          v-model="value.sc.stockist.custom_pre_order_end"
                          type="date"
                          name="name"
                          id="name"
                          class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div
                      class="field-inset-default my-4 w-full"
                      :class="
                        value.sc.stockist.is_custom_date_pre_order
                          ? 'bg-gray-200'
                          : null
                      "
                    >
                      <label
                        for="name"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Limit Periodik
                      </label>
                      <select
                        v-model="
                          value.sc.stockist.current_limit_pre_order_periodic
                        "
                        :disabled="value.sc.stockist.is_custom_date_pre_order"
                        class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        :class="
                          value.sc.stockist.is_custom_date_pre_order
                            ? 'bg-gray-200'
                            : null
                        "
                        placeholder="Masukkan Stok Barang"
                      >
                        <option :value="null">Pilih Periode</option>
                        <option value="daily">Harian</option>
                        <option value="weekly">Mingguan</option>
                        <option value="monthly">Bulanan</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ToggleButton } from 'vue-js-toggle-button';

// import BaseInput from '@/components/base/BaseInput.vue';

export default {
  name: 'ProductStatusForm',
  components: {
    // BaseInput,
    ToggleButton,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    statusOrderVisible: {
      type: Boolean,
    },
  },
  methods: {
    changeLimitOrder(office) {
      if (office === 'pp') {
        if (!this.value.pp.is_limit_order) {
          this.value.pp.limit_order_periodic = null;
        } else {
          this.$refs.ppLimitOrder.focus();
        }
      } else if (office === 'pp-stockist') {
        if (!this.value.pp.stockist.is_limit_pre_order) {
          this.value.pp.stockist.limit_pre_order_periodic = null;
        } else {
          this.$refs.ppStockistLimitOrder.focus();
        }
      } else if (office === 'pp-sc') {
        if (!this.value.pp.sc.is_limit_pre_order) {
          this.value.pp.sc.limit_pre_order_periodic = null;
        } else {
          this.$refs.ppscLimitOrder.focus();
        }
      } else if (office === 'kp') {
        if (!this.value.kp.is_limit_order) {
          this.value.kp.limit_order_periodic = null;
        } else {
          this.$refs.kpLimitOrder.focus();
        }
      } else if (office === 'kp-stockist') {
        if (!this.value.kp.stockist.is_limit_pre_order) {
          this.value.kp.stockist.limit_pre_order_periodic = null;
        } else {
          this.$refs.kpStockistLimitOrder.focus();
        }
      } else if (office === 'kp-sc') {
        if (!this.value.kp.sc.is_limit_pre_order) {
          this.value.kp.sc.limit_pre_order_periodic = null;
        } else {
          this.$refs.kpscLimitOrder.focus();
        }
      } else if (office === 'sc') {
        if (!this.value.sc.is_limit_order) {
          this.value.sc.limit_order_periodic = null;
        } else {
          this.$refs.scLimitOrder.focus();
        }
      } else if (office === 'sc-stockist') {
        if (!this.value.sc.stockist.is_limit_pre_order) {
          this.value.sc.stockist.limit_pre_order_periodic = null;
        } else {
          this.$refs.scStockistLimitOrder.focus();
        }
      }
    },
    changeCustomDate(value) {
      this.$emit('change-custom-date', value);
    },
  },
};
</script>
