<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <div class="mb-5 rounded-lg bg-white p-8">
      <div class="space-y-4 divide-y px-4">
        <h1 class="mt-2 text-lg font-bold text-gray-900">Tambah User Baru</h1>
        <form @submit.prevent="addUser">
          <div>
            <div class="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <div class="field-inset-default">
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Nama
                  </label>
                  <input
                    v-model="dataUser.nama"
                    type="text"
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="contoh: Ujang"
                  />
                </div>
              </div>
              <div class="sm:col-span-6">
                <div class="field-inset-default">
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    v-model="dataUser.email"
                    type="text"
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="contoh=admin@nasa.com"
                  />
                </div>
              </div>
              <div class="sm:col-span-6">
                <div class="field-inset-default">
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Password
                  </label>
                  <input
                    v-model="dataUser.password"
                    type="password"
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div class="sm:col-span-6">
                <div class="field-inset-default">
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Konfirmasi Password
                  </label>
                  <input
                    v-model="dataUser.konfirmasi_pass"
                    type="password"
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="Konfirmasi Password"
                  />
                </div>
              </div>
              <div class="sm:col-span-6">
                <div class="field-inset-default relative">
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Kategori Kantor
                  </label>
                  <select
                    v-model="dataUser.office_category"
                    class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="Pilih Kategori Kantor"
                  >
                    <option disabled value="">Pilih Kategori Kantor</option>
                    <option
                      v-for="category in getOfficeCategories.data"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.attributes.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="sm:col-span-6">
                <div class="field-inset-default relative">
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Kantor
                  </label>
                  <button
                    type="button"
                    @click="openModal"
                    class="flex absolute inset-y-0 top-4 right-4 cursor-pointer items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-500 hover:text-gray-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  <input
                    v-model="dataUser.kantor.kode"
                    ref="focusNamaKantor"
                    type="text"
                    name="offices"
                    id="offices"
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="contoh: Gudang Pusat"
                  />
                </div>
              </div>
              <div class="sm:col-span-6">
                <div class="field-inset-default relative">
                  <label
                    for="name"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Role
                  </label>
                  <button
                    type="button"
                    @click="openModalRole"
                    class="flex absolute inset-y-0 top-4 right-4 cursor-pointer items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-500 hover:text-gray-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  <input
                    v-model="dataUser.role.nama"
                    ref="focusNamaKantor"
                    @blur="searchRole"
                    type="text"
                    name="offices"
                    id="offices"
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="contoh: Staff Gudang Pusat"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="pt-5">
            <div class="flex justify-end">
              <button
                type="submit"
                class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
              >
                Simpan
              </button>
              <button
                @click="clearDataUser"
                type="button"
                class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
              >
                Batal
              </button>
              <router-link
                to="/admin/master/users"
                class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
              >
                Selesai
              </router-link>
            </div>
          </div>
        </form>

        <transition
          name="customFade"
          enter-active-class="fadeIn"
          leave-active-class="fadeOut"
        >
          <div
            v-if="openListOffices"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
              >
                <div class="flex justify-between px-4 py-5 sm:px-6">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Daftar Kantor
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Daftar Data Kantor yang tersedia
                    </p>
                  </div>
                  <div>
                    <img src="@/assets/images/logo.png" alt="" class="w-52" />
                  </div>
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div class="flex justify-end">
                    <base-search
                      v-model="search"
                      @input="debounceSearch"
                      placeholder="Cari kode atau nama"
                    />
                  </div>
                  <div class="flex my-2 sm:-mx-6 lg:-mx-8">
                    <div
                      class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                    >
                      <Datatable
                        :total="getOfficeByOfficesCategories.meta.page.total"
                        :perPage="
                          getOfficeByOfficesCategories.meta.page.perPage
                        "
                        :currentPage="
                          getOfficeByOfficesCategories.meta.page.currentPage
                        "
                        @pagechanged="onPageChangeOffices"
                      >
                        <template v-slot:thead>
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Kode Office
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Nama Office
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Alamat
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                No. Telp
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:tbody>
                          <tbody>
                            <tr
                              class="cursor-pointer bg-white hover:bg-green-100"
                              v-for="(
                                data, dataIdx
                              ) in getOfficeByOfficesCategories.data"
                              :key="data.id"
                              :class="
                                dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              "
                              @click="changeOffice(data)"
                            >
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                              >
                                {{ data.attributes.code }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.name }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.address }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.phone }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </Datatable>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    @click="openListOffices = !openListOffices"
                    type="button"
                    class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition
          name="customFade"
          enter-active-class="fadeIn"
          leave-active-class="fadeOut"
        >
          <div
            v-if="openListRoles"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
              >
                <div class="flex justify-between px-4 py-5 sm:px-6">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Daftar Role
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Daftar Data role yang tersedia
                    </p>
                  </div>
                  <div>
                    <img src="@/assets/images/logo.png" alt="" class="w-52" />
                  </div>
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div class="flex justify-end">
                    <base-search
                      v-model="searchRole"
                      @input="debounceSearchRoles"
                      placeholder="Cari kode atau nama"
                    />
                  </div>
                  <div class="flex my-2 sm:-mx-6 lg:-mx-8">
                    <div
                      class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                    >
                      <Datatable
                        :total="getRoles.meta.page.total"
                        :perPage="getRoles.meta.page.perPage"
                        :currentPage="getRoles.meta.page.currentPage"
                        @pagechanged="onPageChangeRoles"
                      >
                        <template v-slot:thead>
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Nama Role
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:tbody>
                          <tbody>
                            <tr
                              class="cursor-pointer bg-white hover:bg-green-100"
                              v-for="(data, dataIdx) in getRoles.data"
                              :key="data.id"
                              :class="
                                dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              "
                              @click="changeRole(data)"
                            >
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-700"
                              >
                                {{ data.attributes.name }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </Datatable>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    @click="openListRoles = !openListRoles"
                    type="button"
                    class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="success"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <modal @modal-action="successModal" link="/admin/master/users">
              <template v-slot:svg>
                <div
                  class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
                >
                  <svg
                    class="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </template>
              <template v-slot:heading>
                <h3
                  class="text-lg font-bold leading-6 text-gray-900"
                  id="modal-title"
                >
                  Data Berhasil disimpan
                </h3>
              </template>
            </modal>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="failed"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <modal @modal-action="failedModal" link="/master/barang">
              <template v-slot:svg>
                <div
                  class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
                >
                  <!-- Heroicon name: outline/check -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </template>
              <template v-slot:heading>
                <h3
                  class="text-lg font-bold leading-6 text-gray-900"
                  id="modal-title"
                >
                  {{ getError[0].detail }}
                </h3>
              </template>
            </modal>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Modal from '@/components/Modal.vue';
import Loading from '@/components/Loading.vue';
import BaseSearch from '@/components/base/Search.vue';
import Datatable from '@/components/base/Datatable';

export default {
  name: 'TambahUser',
  components: {
    Loading,
    Datatable,
    Modal,
    BaseSearch,
  },
  data() {
    return {
      success: false,
      failed: false,
      openListOffices: false,
      openListRoles: false,
      search: null,
      searchRole: '',
      dataUser: {
        id: '',
        nama: '',
        password: '',
        konfirmasi_pass: '',
        office_category: '',
        kantor: {
          id: '',
          kode: '',
        },
        role: {
          id: '',
          nama: '',
        },
        email: '',
        status: 1,
      },
    };
  },
  created() {
    const handleEscape = () => {
      this.success = false;
    };
    this.setEscape(handleEscape);
    this.fetchOfficeCategories();
  },
  computed: {
    ...mapGetters({
      isLoading: 'users/getLoading',
      getError: 'users/getError',
      getOffices: 'offices/getOffices',
      getOfficeByOfficesCategories:
        'office_categories/getOfficeByOfficesCategories',
      getOfficeCategories: 'office_categories/getOfficeCategories',
      getRoles: 'roles/getRoles',
    }),
  },
  methods: {
    ...mapActions({
      createUser: 'users/createUser',
      fetchOffices: 'offices/fetchOffices',
      fetchOfficeCategories: 'office_categories/fetchOfficeCategories',
      fetchOfficesByOfficeCategory:
        'office_categories/fetchOfficesByOfficeCategory',
      fetchRoles: 'roles/fetchRoles',
    }),
    successModal() {
      this.success = !this.success;
      this.clearDataUser();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearDataUser();
    },
    openModal() {
      this.openListOffices = !this.openListOffices;
      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataUser.office_category,
        pageNumber: 1,
        pageSize: 5,
      });
    },
    openModalRole() {
      this.openListRoles = !this.openListRoles;
      this.fetchRoles({
        pageNumber: 1,
        pageSize: 5,
      });
    },
    debounceSearch: debounce(function () {
      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataUser.office_category,
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
      });
    }, 300),
    debounceSearchRoles: debounce(function () {
      this.fetchRoles({
        pageNumber: 1,
        pageSize: 5,
        search: this.searchRole,
      });
    }, 300),
    onPageChangeOffices(page) {
      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataUser.office_category,
        pageNumber: page,
        pageSize: 5,
        search: this.search,
      });
    },
    onPageChangeRoles(page) {
      this.fetchRoles({
        pageNumber: page,
        pageSize: 5,
        search: this.searchRole,
      });
    },
    changeOffice(data) {
      this.dataUser.kantor.id = data.id;
      this.dataUser.kantor.kode = data.attributes.code;
      this.openListOffices = !this.openListOffices;
      this.search = '';
    },
    changeRole(data) {
      this.dataUser.role.id = data.id;
      this.dataUser.role.nama = data.attributes.name;
      this.openListRoles = !this.openListRoles;
      this.searchRole = '';
    },
    addUser() {
      const payload = {
        data: {
          type: 'users',
          attributes: {
            name: this.dataUser.nama,
            email: this.dataUser.email,
            password: this.dataUser.password,
            password_confirmation: this.dataUser.konfirmasi_pass,
          },
          relationships: {
            office: {
              data: {
                type: 'offices',
                id: this.dataUser.kantor.id,
              },
            },
            roles: {
              data: [
                {
                  type: 'roles',
                  id: this.dataUser.role.id,
                },
              ],
            },
          },
        },
      };
      this.createUser(payload).then((response) => {
        if (response) {
          this.success = true;
        } else {
          this.failed = true;
        }
      });
    },
    clearDataUser() {
      this.dataUser = {
        id: '',
        nama: '',
        password: '',
        konfirmasi_pass: '',
        office_category: '',
        kantor: {
          id: '',
          kode: '',
        },
        role: {
          id: '',
          nama: '',
        },
        email: '',
        status: 1,
      };
    },
  },
};
</script>
