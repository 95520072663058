<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Tambah Menu Untuk Role {{ role.data.attributes.name }}
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Daftar Menu Yang Tersedia
            </p>
          </div>
        </div>
      </div>
      <div class="mt-6 space-y-4 border-t border-gray-200 pt-6">
        <div
          :class="[
            'flex items-center',
            breadcrumbs.length ? 'justify-between' : 'justify-end',
          ]"
        >
          <ul v-if="breadcrumbs.length" class="flex items-center space-x-4">
            <li>
              <div>
                <a
                  href="#"
                  class="text-gray-400 hover:text-gray-500"
                  @click="onResetBreadcrumb"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                </a>
              </div>
            </li>
            <li v-for="breadcrumb in breadcrumbs" :key="breadcrumb.id">
              <div class="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 flex-shrink-0 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <a
                  href="#"
                  class="text-sm font-medium text-gray-500 hover:text-gray-700"
                  @click="onClickBreadcrumb(breadcrumb)"
                  >{{ breadcrumb.attributes.name }}</a
                >
              </div>
            </li>
          </ul>
          <div class="flex items-center gap-x-4">
            <span class="text-xs text-gray-400"
              >{{ checkedMenuIds.length }} Dipilih</span
            >
            <base-button size="sm" @click="onSave">Simpan</base-button>
          </div>
        </div>
        <datatable
          :columns="columns"
          :scroll-x="false"
          :total="menus.meta.page.total"
          :perPage="menus.meta.page.perPage"
          :currentPage="menus.meta.page.currentPage"
          @pagechanged="onPageChanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="menu in menus.data"
              :key="menu.id"
              :class="[classes.tr, 'hover:bg-gray-50']"
            >
              <td :class="[classes.td]" style="width: 1px">
                <base-checkbox
                  v-if="alreadyChecked(menu)"
                  :with-label="false"
                  :value="true"
                  disabled
                />
                <base-checkbox
                  v-else
                  :with-label="false"
                  :input-value="menu.id"
                  v-model="checkedMenuIds"
                />
              </td>
              <td
                :colspan="menu.attributes.type === 'group' ? 1 : 2"
                :class="[
                  classes.td,
                  menu.attributes.type === 'group' ? 'cursor-pointer' : '',
                ]"
                @click="onClickDetail(menu)"
              >
                {{ menu.attributes.name }}
              </td>
              <td
                v-if="menu.attributes.type === 'group'"
                :class="[classes.td, 'cursor-pointer text-right']"
                @click="onClickDetail(menu)"
              >
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';

export default {
  mixins: [requestMixin],
  components: { BaseModal, BaseCheckbox },
  props: {
    visible: Boolean,
    roleId: String,
  },
  emits: ['close', 'attach-menu'],
  data() {
    return {
      loading: false,
      menus: {
        data: [],
        meta: {
          page: {},
        },
      },
      breadcrumbs: [],
      checkedMenuIds: [],
      role: {
        data: {
          attributes: {
            name: null,
          },
        },
      },
    };
  },
  computed: {
    columns() {
      return [
        { id: 'check', name: '' },
        { id: 'name', name: 'Nama' },
        { id: 'actions', name: '' },
      ];
    },
    activeMenu() {
      return this.breadcrumbs.length
        ? this.breadcrumbs[this.breadcrumbs.length - 1]
        : null;
    },
  },
  methods: {
    async onOpened() {
      this.loading = true;

      this.breadcrumbs = [];

      await this.loadRole();
      await this.loadMenus();

      this.loading = false;
    },
    async onClickDetail(menu) {
      if (menu.attributes.type === 'group') {
        this.loading = true;

        await this.loadMenus({
          'filter[parent_id]': menu.id,
          'filter[is_parent]': null,
        });

        this.breadcrumbs.push(menu);

        this.loading = false;
      }
    },
    async onClickBreadcrumb(menu) {
      this.loading = true;

      const breadcrumbIndex = this.breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.id === menu.id
      );

      this.breadcrumbs.splice(
        breadcrumbIndex + 1,
        this.breadcrumbs.length - breadcrumbIndex + 1
      );

      await this.loadMenus({
        'filter[parent_id]': menu.id,
        'filter[is_parent]': null,
      });

      this.loading = false;
    },
    async onResetBreadcrumb() {
      this.loading = true;

      this.breadcrumbs = [];

      await this.loadMenus();

      this.loading = false;
    },
    async onSave() {
      this.loading = true;

      await this.request(`/api/v1/roles/${this.roleId}/-actions/attach-menus`, {
        method: 'post',
        data: {
          menu_uuids: [
            ...this.checkedMenuIds,
            ...this.breadcrumbs.map((menu) => menu.id),
          ],
        },
      });

      this.loading = false;

      this.$emit('close', { reload: true });
    },
    async onPageChanged(page) {
      this.loading = true;

      await this.loadMenus({
        'page[number]': page,
        'filter[parent_id]': this.activeMenu ? this.activeMenu.id : null,
        'filter[is_parent]': this.activeMenu ? null : true,
      });

      this.loading = false;
    },
    alreadyChecked(menu) {
      if (!Array.isArray(this.role.data.attributes.menu_ids)) {
        return false;
      }

      return this.role.data.attributes.menu_ids.includes(menu.id);
    },
    async loadRole() {
      const [res, error] = await this.request(`/api/v1/roles/${this.roleId}`, {
        params: {
          'fields[roles]': 'menu_ids,name',
        },
      });

      if (!error) {
        this.role = res;
      }

      return [res, error];
    },
    async loadMenus(params) {
      const [res, error] = await this.request(`/api/v1/menus`, {
        params: {
          'page[size]': 5,
          'filter[is_parent]': true,
          ...params,
        },
      });

      if (!error) {
        this.menus = res;
        this.checkedMenuIds = [];
      }

      return [res, error];
    },
  },
};
</script>
