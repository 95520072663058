<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-4">
          <base-select
            :options="[
              { key: 'product', value: true, label: 'Produk' },
              { key: 'box', value: false, label: 'Box' },
            ]"
            :expand="false"
            :shadow="false"
            v-model="filterProducts.is_product"
            v-on:change="handleFilter"
          />
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
          <div>
            <base-button to="barang/tambah-barang" id="add_barang">
              <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
              Tambah Barang
            </base-button>
            <button
              @click="downloadProduct"
              v-if="!isWaitForDownload"
              class="focus:outline-none ml-2 inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              <!-- Heroicon name: solid/mail -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </button>
            <button
              v-else
              class="focus:outline-none ml-2 inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="fill-blue-600 w-5 animate-spin text-gray-200 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getProducts.meta.page.total"
              :perPage="getProducts.meta.page.perPage"
              :currentPage="getProducts.meta.page.currentPage"
              @pagechanged="onPageChangeProducts"
              :is-empty="!getProducts.data.length"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      @click="sortData('code')"
                      scope="col"
                      class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Barang
                    </th>
                    <th
                      @click="sortData('name')"
                      scope="col"
                      class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Supplier
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kelompok Produk
                    </th>
                    <!-- <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Aksi
                    </th> -->
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getProducts.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="detailBarang(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.supplier_code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        data.attributes['product_category_name']
                          ? data.attributes['product_category_name']
                          : '-'
                      }}
                    </td>
                    <!-- <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-indigo-600"
                    >
                      <button type="button" @click="openModalDeleteData(data)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 text-red-400 hover:text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </td> -->
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>

        <product-detail-modal
          :visible="openDetail"
          :with-attributes="false"
          @close="openDetail = false"
        >
          <template #content>
            <toggle-button
              v-model="dataProduct.attributes.is_active"
              :labels="{
                checked: 'Aktif',
                unchecked: 'Non Aktif',
              }"
              :width="dataProduct.attributes.is_active ? 60 : 80"
              class="mb-8"
              v-on:change="onActiveChange"
            />

            <product-detail-attributes
              :product="dataProduct"
              @edit-qr="onEditQr"
            />
          </template>
        </product-detail-modal>

        <transition name="fade">
          <div
            v-if="success"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                  >
                    <svg
                      class="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading"> Data Berhasil disimpan </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <button
                      @click="closeModal"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="failed"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-red-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading">
                      {{ getError }}
                    </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <button
                      @click.prevent="failedModal"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Ulangi
                    </button>
                    <button
                      @click="closeModal"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <template v-if="isLoading">
      <loading />
    </template>

    <!-- Modal untuk konfirmasi pengaturan nilai pengali -->
    <Modal
      :showing="modalDeleteData.visible"
      @close="modalDeleteData.visible = false"
      :showClose="modalDeleteData.visible"
      size="max-w-lg"
    >
      <div>
        <div
          class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100 text-red-700"
        >
          <!-- Heroicon name: outline/check -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3
            class="text-lg font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Konfirmasi Hapus Barang
          </h3>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
          <button
            @click="modalDeleteData.visible = !modalDeleteData.visible"
            type="button"
            class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Batalkan
          </button>
          <button
            type="button"
            @click="deleteData"
            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            Hapus
          </button>
        </div>
      </div>
    </Modal>

    <product-qr-modal
      :product="getProduct"
      :showing="productQrModal.visible"
      :with-camera-scan="false"
      @updated="onUpdated"
      @close="productQrModal.visible = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/base/BaseModal';
import BaseSelect from '@/components/base/BaseSelect';
import { ToggleButton } from 'vue-js-toggle-button';
import ProductQrModal from '@/components/product/ProductQrModal.vue';
import ProductDetailModal from '@/components/product/ProductDetailModal.vue';
import ProductDetailAttributes from '@/components/product/ProductDetailAttributes.vue';
import BaseSearch from '@/components/base/Search.vue';
import { downloadFileUrl } from '@/services/utils.service';

export default {
  name: 'Barang',
  components: {
    Datatable,
    Modal,
    ToggleButton,
    BaseSearch,
    BaseSelect,
    ProductQrModal,
    ProductDetailModal,
    ProductDetailAttributes,
  },
  data() {
    return {
      search: null,
      openDetail: false,
      success: false,
      failed: false,
      retries: 10,
      maxRetries: 10,
      dataProduct: {},
      isWaitForDownload: false,
      sort: {
        name: 'createdAt',
        direction: 'desc',
      },
      modalDeleteData: {
        visible: false,
        id: null,
      },
      filterProducts: {
        is_product: true,
      },
      productQrModal: {
        visible: false,
      },
      exportProductChannelName: null
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'products/getLoading',
      getProducts: 'products/getProducts',
      getProduct: 'products/getProduct',
      getUser: 'auth/getUser'
    }),
    fetchProductsParams() {
      return {
        'filter[is_product]': this.filterProducts.is_product,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      exportProducts: 'products/exportProducts',
      downloadProducts: 'products/downloadProducts',
      deleteProduct: 'products/deleteProduct',
      updateProduct: 'products/updateProduct',
      fetchProductById: 'products/fetchProductById',
      createAlert: 'alert/createAlert',
    }),
    closeModal() {
      this.success = this.failed = false;
      this.openDetail = !this.openDetail;
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
        ...this.fetchProductsParams,
      });
    },
    failedModal() {
      this.failed = !this.failed;
    },
    getProductCategory(id) {
      let category = this.getProducts.included.filter(function (el) {
        return el.id === id;
      });
      return category[0].attributes.name;
    },
    getProductPrice(areaName, buyerType, dataProduct) {
      let productPrice = this.getProduct.included.find(function (include) {
        const result = dataProduct.relationships['product-prices'].data.find(
          (item) => {
            return (
              item.id === include.id &&
              include.attributes.area === areaName &&
              include.attributes['buyer-type'] === buyerType
            );
          }
        );
        if (result?.id) {
          return result.id === include.id;
        }
        return false;
      });
      return productPrice;
    },
    onPageChangeProducts(page) {
      this.fetchProducts({
        pageNumber: page,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
        ...this.fetchProductsParams,
      });
    },
    sortData(sortName) {
      if (this.sort.name === sortName) {
        this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort.name = sortName;
        this.sort.direction = 'asc';
      }
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
        ...this.fetchProductsParams,
      });
    },
    debounceSearch: debounce(function () {
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
        ...this.fetchProductsParams,
      });
    }, 300),
    handleFilter: function () {
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
        ...this.fetchProductsParams,
      });
    },
    detailBarang(product) {
      this.fetchProductById({
        id: product.id,
        include: 'product-prices,product',
        fields: { 'product-prices': 'price,area,buyer-type' },
      }).then(() => {
        this.dataProduct = this.getProduct.data;
        this.openDetail = !this.openDetail;
      });
    },
    onUpdated(product) {
      this.dataProduct = product.data;
    },
    async downloadProduct() {
      this.isWaitForDownload = true;

      await this.exportProducts();
    },
    openModalDeleteData(data) {
      this.modalDeleteData.id = data.id;
      this.modalDeleteData.visible = true;
    },
    deleteData() {
      this.deleteProduct({
        id: this.modalDeleteData.id,
      }).then((response) => {
        this.modalDeleteData.id = null;
        this.modalDeleteData.visible = false;
        this.openDetail = false;

        this.fetchProducts({
          pageNumber: 1,
          pageSize: 5,
          sortName: this.sort.name,
          sortDirection: this.sort.direction,
          fields: {
            products: 'product_category_name,code,supplier_code,name',
          },
          ...this.fetchProductsParams,
        });
        if (!response) {
          this.failed = false;
        }
      });
    },
    onActiveChange() {
      const payload = {
        data: {
          type: 'products',
          id: this.dataProduct.id,
          attributes: {
            is_active: this.dataProduct.attributes.is_active,
          },
        },
      };
      this.updateProduct(payload).then(() => {
        this.fetchProducts({
          pageNumber: 1,
          pageSize: 5,
          sortName: this.sort.name,
          sortDirection: this.sort.direction,
          fields: {
            products: 'product_category_name,code,supplier_code,name',
          },
          ...this.fetchProductsParams,
        });
      });
    },
    onEditQr() {
      this.productQrModal.visible = true;
    },
    listenToExportProductEvent() {
      this.exportProductChannelName = `private-ProductExport.${this.getUser.id}`

      const exportProductChannel = this.$pusher.subscribe(this.exportProductChannelName);

      exportProductChannel.bind('ProductExportStatusUpdated', e => {
        if (e.status === 'processing') {
          this.isWaitForDownload = true
        } else if (e.status === 'failed') {
          this.isWaitForDownload = false

          this.createAlert({ data: e.message, status: 'error' })
        } else if (e.status === 'finished') {
          this.isWaitForDownload = false

          downloadFileUrl(e.file_url)
        }
      })
    },
    stopListenToExportProductEvent() {
      this.$pusher.unsubscribe(this.exportProductChannelName);
    }
  },
  created() {
    this.fetchProducts({
      pageNumber: 1,
      pageSize: 5,
      sortName: this.sort.name,
      sortDirection: this.sort.direction,
      fields: {
        products: 'product_category_name,code,supplier_code,name',
      },
      include: null,
      ...this.fetchProductsParams,
    });
    const handleEscape = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.openDetail = false;
      }
    };
    document.addEventListener('keydown', handleEscape);
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', handleEscape);
    });

    this.listenToExportProductEvent()
  },
  beforeRouteLeave(to, from, next) {
    this.stopListenToExportProductEvent()

    next()
  }
};
</script>
