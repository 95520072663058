<template>
  <Datatable
    with-overflow
    :total="getExpeditions.meta.page.total"
    :perPage="getExpeditions.meta.page.perPage"
    :currentPage="getExpeditions.meta.page.currentPage"
    @pagechanged="onPageChange"
  >
    <template v-slot:thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            v-for="item in ['Kode Expedisi', 'Nama Expedisi', 'Keterangan', '']"
            scope="col"
            :key="item"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            {{ item }}
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody>
      <tbody v-if="getExpeditions.data?.length > 0">
        <tr
          class="parent bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getExpeditions.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
        >
          <td
            @click="detail(data)"
            class="flex cursor-pointer items-center gap-x-2 whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
          >
            {{ data.attributes.code }}
            <img
              v-if="data.attributes.logo"
              :src="data.attributes.logo"
              class="h-5"
            />
            <span v-if="data.attributes.is_global" class="tooltip">
              <Icon
                class="h-5 w-5 text-blue-600"
                icon="heroicons:check-badge-20-solid"
              />
              <p class="tooltiptext">Terverifikasi NASA</p>
            </span>
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{ data.attributes.name }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            {{ data.attributes.description }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            <div class="flex space-x-2">
              <base-button
                for-icon
                class="child text-yellow-400 hover:text-yellow-600"
                @click="editExpedition(data)"
                type="button"
              >
                <Icon icon="heroicons:pencil-square" class="h-5 w-5" />
              </base-button>
              <base-button
                for-icon
                class="child text-red-400 hover:text-red-600"
                @click="removeExpedition(data)"
                type="button"
              >
                <Icon icon="heroicons:trash" class="h-5 w-5" />
              </base-button>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ExpeditionTable',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getExpeditions: 'expeditions/getExpeditions',
    }),
    // Your computed properties here
  },
  components: {
    // Your child components here
  },
  methods: {
    ...mapActions({
      updateExpedition: 'expeditions/updateExpedition',
      fetchExpeditions: 'expeditions/fetchExpeditions',
    }),
    detail(val) {
      this.$emit('click-row', val);
    },
    onPageChange(val) {
      this.$emit('page-change', val);
    },
    removeExpedition(data) {
      const payload = {
        data: {
          type: 'expeditions',
          id: data.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };
      this.updateExpedition(payload).then(() => {
        this.fetchExpeditions({
          params: this.expeditionParams,
        });
      });
    },
    async editExpedition(data) {
      this.$emit('click-edit', data);
    },
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
