<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg bg-white py-4">
      <div class="space-y-4 divide-y px-4">
        <h1 class="mt-2 text-lg font-bold text-gray-900">
          {{ $route.params.id ? 'Edit User' : 'Tambah User Baru' }}
        </h1>
        <div v-if="isLoading" class="justify-center text-center">
          <loading></loading>
        </div>
        <base-form
          :form="dataUser"
          :nullable="nullable"
          @submit="addUser"
          v-slot="{ errors }"
        >
          <div>
            <div class="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <base-input
                  type="text"
                  id="nama"
                  label="Nama"
                  placeholder="contoh: Ujang"
                  inset
                  with-label
                  :invalid="errors.nama"
                  :message="errors.nama"
                  v-model="dataUser.nama"
                />
              </div>
              <div class="sm:col-span-6">
                <base-input
                  type="email"
                  id="email"
                  label="Email"
                  placeholder="contoh=admin@nasa.com"
                  inset
                  with-label
                  :invalid="errors.email"
                  :message="errors.email"
                  v-model="dataUser.email"
                />
              </div>
              <template v-if="!isEdit">
                <div class="relative sm:col-span-6">
                  <base-input
                    :type="passwordType"
                    id="password"
                    label="Password"
                    placeholder="Password"
                    inset
                    with-label
                    :invalid="errors.password"
                    :message="errors.password"
                    v-model="dataUser.password"
                  />
                  <svg
                    v-if="passwordType === 'password'"
                    xmlns="http://www.w3.org/2000/svg"
                    class="absolute right-3 h-5 w-5 cursor-pointer hover:opacity-80"
                    fill="none"
                    style="top: 1.7rem"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    @click="changeShowPassword"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="absolute bottom-3 right-3 h-5 w-5 cursor-pointer hover:opacity-80"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    style="top: 1.7rem"
                    stroke-width="2"
                    @click="changeShowPassword"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                </div>
                <div class="sm:col-span-6">
                  <base-input
                    type="password"
                    id="konfirmasi_pass"
                    label="Konfirmasi Password"
                    placeholder="Konfirmasi Password"
                    inset
                    with-label
                    :invalid="errors.konfirmasi_pass"
                    :message="errors.konfirmasi_pass"
                    v-model="dataUser.konfirmasi_pass"
                  />
                </div>
              </template>
              <div class="sm:col-span-6">
                <base-input
                  label="Kategori Kantor"
                  inset
                  with-label
                  :invalid="errors.office_category"
                  :message="errors.office_category"
                >
                  <select
                    v-model="dataUser.office_category"
                    class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="Pilih Kategori Kantor"
                    id="office_category"
                  >
                    <option disabled value="">Pilih Kategori Kantor</option>
                    <option
                      v-for="category in getOfficeCategories.data"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.attributes.name }}
                    </option>
                  </select>
                </base-input>
              </div>
              <template v-if="dataUser.office_category">
                <div class="sm:col-span-6">
                  <base-input
                    label="Kantor"
                    inset
                    with-label
                    :invalid="errors.kantor.kode"
                    :message="errors.kantor.kode"
                  >
                    <button
                      type="button"
                      @click="openModal"
                      class="absolute inset-y-0 top-4 right-4 flex cursor-pointer items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-gray-500 hover:text-gray-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    <input
                      v-model="dataUser.kantor.kode"
                      @blur="searchOffice"
                      ref="focusNamaKantor"
                      type="text"
                      name="kantor_kode"
                      id="kantor_kode"
                      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="contoh: Gudang Pusat"
                    />
                  </base-input>
                </div>
                <div class="sm:col-span-6">
                  <base-input
                    label="Role"
                    inset
                    with-label
                    :invalid="errors.role.nama"
                    :message="errors.role.nama"
                  >
                    <button
                      type="button"
                      @click="openModalRole"
                      class="absolute inset-y-0 top-4 right-4 flex cursor-pointer items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-gray-500 hover:text-gray-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    <input
                      v-model="dataUser.role.nama"
                      ref="focusNamaKantor"
                      @blur="searchRole"
                      type="text"
                      name="role_nama"
                      id="role_nama"
                      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="contoh: Staff Gudang Pusat"
                    />
                  </base-input>
                </div>
              </template>
            </div>
          </div>
          <div class="flex justify-end space-x-2 pt-5">
            <base-button type="submit"> Simpan </base-button>
            <base-button color="white" to="/admin/master/users">
              Kembali
            </base-button>
          </div>
        </base-form>

        <transition
          name="customFade"
          enter-active-class="fadeIn"
          leave-active-class="fadeOut"
        >
          <div
            v-if="openListOffices"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
              >
                <div class="flex justify-between px-4 py-5 sm:px-6">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Daftar Kantor
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Daftar Data Kantor yang tersedia
                    </p>
                  </div>
                  <div>
                    <img src="@/assets/images/logo.png" alt="" class="w-52" />
                  </div>
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div class="flex justify-end">
                    <base-search
                      v-model="search"
                      @input="debounceSearch"
                      placeholder="Cari kode atau nama"
                    />
                  </div>
                  <div class="my-2 flex sm:-mx-6 lg:-mx-8">
                    <div
                      class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                    >
                      <Datatable
                        :total="getOfficeByOfficesCategories.meta.page.total"
                        :perPage="
                          getOfficeByOfficesCategories.meta.page.perPage
                        "
                        :currentPage="
                          getOfficeByOfficesCategories.meta.page.currentPage
                        "
                        @pagechanged="onPageChangeOffices"
                      >
                        <template v-slot:thead>
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Kode Office
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Nama Office
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Alamat
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                No. Telp
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:tbody>
                          <tbody>
                            <tr
                              class="cursor-pointer bg-white hover:bg-green-100"
                              v-for="(
                                data, dataIdx
                              ) in getOfficeByOfficesCategories.data"
                              :key="data.id"
                              :class="
                                dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              "
                              @click="changeOffice(data)"
                            >
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                              >
                                {{ data.attributes.code }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.name }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.address }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ data.attributes.phone }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </Datatable>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    @click="openListOffices = !openListOffices"
                    type="button"
                    class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition
          name="customFade"
          enter-active-class="fadeIn"
          leave-active-class="fadeOut"
        >
          <div
            v-if="openListRoles"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
              >
                <div class="flex justify-between px-4 py-5 sm:px-6">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Daftar Role
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Daftar Data role yang tersedia
                    </p>
                  </div>
                  <div>
                    <img src="@/assets/images/logo.png" alt="" class="w-52" />
                  </div>
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <div class="flex justify-end">
                    <base-search
                      v-model="searchRole"
                      @input="debounceSearchRoles"
                      placeholder="Cari kode atau nama"
                    />
                  </div>
                  <div class="my-2 flex sm:-mx-6 lg:-mx-8">
                    <div
                      class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                    >
                      <Datatable
                        :total="getRoles.meta.page.total"
                        :perPage="getRoles.meta.page.perPage"
                        :currentPage="getRoles.meta.page.currentPage"
                        @pagechanged="onPageChangeRoles"
                      >
                        <template v-slot:thead>
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                              >
                                Nama Role
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:tbody>
                          <tbody>
                            <tr
                              class="cursor-pointer bg-white hover:bg-green-100"
                              v-for="(data, dataIdx) in getRoles.data"
                              :key="data.id"
                              :class="
                                dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              "
                              @click="changeRole(data)"
                            >
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-700"
                              >
                                {{ data.attributes.name }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </Datatable>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end">
                  <button
                    @click="openListRoles = !openListRoles"
                    type="button"
                    class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="success"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <modal @modal-action="successModal" link="/admin/master/users">
              <template v-slot:svg>
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                >
                  <svg
                    class="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </template>
              <template v-slot:heading>
                <h3
                  class="text-lg font-bold leading-6 text-gray-900"
                  id="modal-title"
                >
                  Data Berhasil disimpan
                </h3>
              </template>
            </modal>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Modal from '@/components/Modal.vue';
import Loading from '@/components/Loading.vue';
import Datatable from '@/components/base/Datatable';
import { getRelationships } from '@/services/utils.service.js';
import BaseForm from '@/components/base/BaseForm.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'TambahUser',
  components: {
    Loading,
    Datatable,
    Modal,
    BaseForm,
    BaseInput,
    BaseSearch,
  },
  data() {
    return {
      success: false,
      failed: false,
      openListOffices: false,
      openListRoles: false,
      search: null,
      searchRole: '',
      isEdit: !!this.$route.params.id,
      dataUser: {
        id: '',
        nama: '',
        email: '',
        password: '',
        konfirmasi_pass: '',
        office_category: '',
        kantor: {
          id: '',
          kode: '',
        },
        role: {
          id: '',
          nama: '',
        },
        status: 1,
      },
      showPassword: false,
      passwordType: 'password',
    };
  },
  created() {
    const handleEscape = () => {
      this.success = false;
    };
    this.setEscape(handleEscape);

    if (this.$route.params.id) {
      this.fetchUser({
        userId: this.$route.params.id,
        params: { include: 'office,roles' },
      }).then(() => this.setDataUser());
    }

    this.fetchOfficeCategories();
  },
  computed: {
    ...mapGetters({
      isLoading: 'users/getLoading',
      getUser: 'users/getUser',
      getError: 'users/getError',
      getOffices: 'offices/getOffices',
      getOfficeByOfficesCategories:
        'office_categories/getOfficeByOfficesCategories',
      getOfficeCategories: 'office_categories/getOfficeCategories',
      getRoles: 'roles/getRoles',
    }),
    nullable: function () {
      return {
        id: null,
        status: null,
        ...(this.dataUser.office_category
          ? {
              kantor: { id: null },
              role: { id: null },
            }
          : {
              kantor: { id: null, kode: null },
              role: { id: null, nama: null },
            }),
        is_active: null,
        referal: { nama_upline: null },
        bank: { nama_bank: null },
        password: this.$route.params.id ? null : undefined,
        konfirmasi_pass: this.$route.params.id ? null : undefined,
      };
    },
  },
  methods: {
    ...mapActions({
      createUser: 'users/createUser',
      updateUser: 'users/updateUser',
      fetchUser: 'users/fetchUser',
      fetchOffices: 'offices/fetchOffices',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchOfficeCategories: 'office_categories/fetchOfficeCategories',
      fetchOfficesByOfficeCategory:
        'office_categories/fetchOfficesByOfficeCategory',
      fetchRoles: 'roles/fetchRoles',
    }),
    changeShowPassword() {
      if (this.showPassword) {
        this.passwordType = 'password';
      } else {
        this.passwordType = 'text';
      }
      this.showPassword = !this.showPassword;
    },
    successModal() {
      this.success = !this.success;
      this.clearDataUser();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearDataUser();
    },
    openModal() {
      this.openListOffices = !this.openListOffices;
      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataUser.office_category,
        pageNumber: 1,
        pageSize: 5,
      });
    },
    openModalRole() {
      this.openListRoles = !this.openListRoles;
      this.fetchRoles({
        pageNumber: 1,
        pageSize: 5,
      });
    },
    debounceSearch: debounce(function () {
      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataUser.office_category,
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
      });
    }, 300),
    debounceSearchRoles: debounce(function () {
      this.fetchRoles({
        pageNumber: 1,
        pageSize: 5,
        search: this.searchRole,
      });
    }, 300),
    onPageChangeOffices(page) {
      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataUser.office_category,
        pageNumber: page,
        pageSize: 5,
        search: this.search,
      });
    },
    searchOffice() {
      this.fetchOfficesByOfficeCategory({
        office_category_id: this.dataUser.office_category,
        pageNumber: 1,
        pageSize: 5,
        search: this.dataUser.kantor.kode,
      }).then((response) => {
        this.dataUser.kantor.id = response.data.data[0].id;
        this.dataUser.kantor.kode = response.data.data[0].attributes.code;
      });
    },
    onPageChangeRoles(page) {
      this.fetchRoles({
        pageNumber: page,
        pageSize: 5,
        search: this.searchRole,
      });
    },
    changeOffice(data) {
      this.dataUser.kantor.id = data.id;
      this.dataUser.kantor.kode = data.attributes.code;
      this.openListOffices = !this.openListOffices;
      this.search = '';
    },
    changeRole(data) {
      this.dataUser.role.id = data.id;
      this.dataUser.role.nama = data.attributes.name;
      this.openListRoles = !this.openListRoles;
      this.searchRole = '';
    },
    addUser() {
      const payload = {
        data: {
          type: 'users',
          attributes: {
            name: this.dataUser.nama,
            email: this.dataUser.email,
            password: this.$route.params.id
              ? undefined
              : this.dataUser.password,
            password_confirmation: this.$route.params.id
              ? undefined
              : this.dataUser.konfirmasi_pass,
          },
          relationships: {
            office: {
              data: {
                type: 'offices',
                id: this.dataUser.kantor.id,
              },
            },
            roles: {
              data: [
                {
                  type: 'roles',
                  id: this.dataUser.role.id,
                },
              ],
            },
          },
        },
      };

      if (this.$route.params.id) {
        payload.data.id = this.$route.params.id;

        this.updateUser(payload).then((response) => {
          if (response) {
            this.success = true;
          } else {
            this.failed = true;
          }
        });
      } else {
        this.createUser(payload).then((response) => {
          if (response) {
            this.success = true;
          } else {
            this.failed = true;
          }
        });
      }
    },
    setDataUser() {
      this.dataUser.id = this.getUser.data.id;
      this.dataUser.nama = this.getUser.data.attributes.name;
      this.dataUser.email = this.getUser.data.attributes.email;
      this.dataUser.password = '';
      this.dataUser.konfirmasi_pass = '';
      this.dataUser.kantor = {
        id: this.getUser.data.relationships.office.data.id,
        kode: getRelationships(
          this.getUser,
          this.getUser.data.relationships.office.data.id
        ).attributes.code,
      };
      this.dataUser.role = {
        id: this.getUser.data.relationships.roles.data[0].id,
        nama: getRelationships(
          this.getUser,
          this.getUser.data.relationships.roles.data[0].id
        ).attributes.name,
      };
      this.dataUser.status = 1;

      this.fetchOfficeById({
        office_id: this.getUser.data.relationships.office.data.id,
        params: { include: 'office-category' },
      }).then((res) => {
        this.dataUser.office_category =
          res.data.data.relationships['office-category'].data.id;
      });
    },
    clearDataUser() {
      this.dataUser = {
        id: '',
        nama: '',
        email: '',
        password: '',
        konfirmasi_pass: '',
        office_category: '',
        kantor: {
          status: 1,
          id: '',
          kode: '',
        },
        role: {
          id: '',
          nama: '',
        },
      };
    },
  },
};
</script>
