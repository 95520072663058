<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
      <template v-if="role.data">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                {{ role.data.attributes.name }}
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                {{ role.data.attributes.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-6 space-y-4 border-t border-gray-200 pt-6">
          <div
            :class="[
              'flex items-center',
              breadcrumbs.length ? 'justify-between' : 'justify-end',
            ]"
          >
            <ul v-if="breadcrumbs.length" class="flex items-center space-x-4">
              <li>
                <div>
                  <a
                    href="#"
                    class="text-gray-400 hover:text-gray-500"
                    @click="onResetBreadcrumb"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 flex-shrink-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                  </a>
                </div>
              </li>
              <li v-for="breadcrumb in breadcrumbs" :key="breadcrumb.id">
                <div class="flex items-center space-x-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 flex-shrink-0 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                  <a
                    href="#"
                    class="text-sm font-medium text-gray-500 hover:text-gray-700"
                    @click="onClickBreadcrumb(breadcrumb)"
                    >{{ breadcrumb.attributes.name }}</a
                  >
                </div>
              </li>
            </ul>
            <div class="flex items-center gap-x-4">
              <div class="space-x-1">
                <base-button
                  v-if="checkedMenuIds.length !== menus.data.length"
                  size="sm"
                  @click="onSave"
                  >Simpan Perubahan</base-button
                >
                <base-button size="sm" @click="onAttachMenu"
                  >Tambah Menu</base-button
                >
              </div>
            </div>
          </div>
          <datatable
            :columns="columns"
            :scroll-x="false"
            :total="menus.meta.page.total"
            :perPage="menus.meta.page.perPage"
            :currentPage="menus.meta.page.currentPage"
            @pagechanged="onPageChanged"
          >
            <template v-slot:tbody="{ classes }">
              <tr
                v-for="menu in menus.data"
                :key="menu.id"
                :class="[classes.tr, 'hover:bg-gray-50']"
              >
                <td :class="[classes.td]" style="width: 1px">
                  <base-checkbox
                    :with-label="false"
                    :input-value="menu.id"
                    v-model="checkedMenuIds"
                  />
                </td>
                <td :class="[classes.td]">{{ menu.attributes.name }}</td>
                <td :class="[classes.td]">
                  <div class="flex justify-end">
                    <button
                      class="flex h-8 w-8 items-center justify-center rounded-lg hover:bg-gray-100"
                      @click="onDetailPermission(menu)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                    <button
                      class="flex h-8 w-8 items-center justify-center rounded-lg hover:bg-gray-100"
                      v-if="menu.attributes.type === 'group'"
                      @click="onClickDetail(menu)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                    <div class="w-8" v-else-if="anyMenuGroups"></div>
                  </div>
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </template>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';

export default {
  mixins: [requestMixin],
  components: { BaseModal, BaseCheckbox },
  props: {
    visible: Boolean,
    roleId: String,
    reload: Boolean,
  },
  emits: ['close', 'attach-menu', 'detail-permission'],
  data() {
    return {
      loading: false,
      menus: {
        data: [],
        meta: {
          page: {},
        },
      },
      role: {
        data: null,
      },
      breadcrumbs: [],
      checkedMenuIds: [],
    };
  },
  computed: {
    columns() {
      return [
        { id: 'check', name: '' },
        { id: 'name', name: 'Nama' },
        { id: 'actions', name: '' },
      ];
    },
    activeMenu() {
      return this.breadcrumbs.length
        ? this.breadcrumbs[this.breadcrumbs.length - 1]
        : null;
    },
    anyMenuGroups() {
      return this.menus.data.filter((menu) => menu.attributes.type === 'group')
        .length;
    },
  },
  methods: {
    async onOpened() {
      if (this.reload) {
        this.loading = true;

        this.breadcrumbs = [];

        await Promise.all([this.loadRole(), this.loadMenus()]);

        this.loading = false;
      }
    },
    async onClickDetail(menu) {
      if (menu.attributes.type === 'group') {
        this.loading = true;

        await this.loadMenus({
          'filter[parent_id]': menu.id,
          'filter[is_parent]': null,
        });

        this.breadcrumbs.push(menu);

        this.loading = false;
      }
    },
    async onClickBreadcrumb(menu) {
      this.loading = true;

      const breadcrumbIndex = this.breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.id === menu.id
      );

      this.breadcrumbs.splice(
        breadcrumbIndex + 1,
        this.breadcrumbs.length - breadcrumbIndex + 1
      );

      await this.loadMenus({
        'filter[parent_id]': menu.id,
        'filter[is_parent]': null,
      });

      this.loading = false;
    },
    async onResetBreadcrumb() {
      this.loading = true;

      this.breadcrumbs = [];

      await this.loadMenus();

      this.loading = false;
    },
    async onSave() {
      this.loading = true;

      await this.request(`/api/v1/roles/${this.roleId}/-actions/detach-menus`, {
        method: 'post',
        data: {
          menu_uuids: this.menus.data
            .filter((menu) => !this.checkedMenuIds.includes(menu.id))
            .map((menu) => menu.id),
        },
      });

      await Promise.all([
        this.loadRole(),
        this.loadMenus(
          this.activeMenu
            ? {
                'filter[parent_id]': this.activeMenu.id,
                'filter[is_parent]': null,
              }
            : {}
        ),
      ]);

      this.loading = false;
    },
    onAttachMenu() {
      this.$emit('attach-menu', this.role.data);
    },
    onDetailPermission(menu) {
      this.$emit('detail-permission', { menu, role: this.role.data });
    },
    async onPageChanged(page) {
      this.loading = true;

      await this.loadMenus({
        'page[number]': page,
        'filter[parent_id]': this.activeMenu ? this.activeMenu.id : null,
        'filter[is_parent]': this.activeMenu ? null : true,
      });

      this.loading = false;
    },
    async loadRole() {
      const [res, error] = await this.request(`/api/v1/roles/${this.roleId}`);

      if (!error) {
        this.role = res;
      }

      return [res, error];
    },
    async loadMenus(params) {
      const [res, error] = await this.request(
        `/api/v1/roles/${this.roleId}/menus`,
        {
          params: {
            'page[size]': 5,
            'filter[is_parent]': true,
            ...params,
          },
        }
      );

      if (!error) {
        this.menus = res;
        this.checkedMenuIds = this.menus.data.map((menu) => menu.id);
      }

      return [res, error];
    },
  },
};
</script>
