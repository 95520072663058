<template>
    <base-wrapper>
        <setup-product-required-create-member />
    </base-wrapper>
</template>

<script>
import SetupProductRequiredCreateMember from '@/components/setup/setup-product-required-create-member.vue';

export default {
    components: { SetupProductRequiredCreateMember }
}
</script>