<template>
  <div>
    <base-select-search
      placeholder="Cari Role"
      :is-loading="loading"
      :data="roles.data"
      :shadow="shadow"
      label="name"
      v-model="selected"
      v-on:infinite-scroll="handleInfiniteScroll"
      v-on:search="handleSearch"
      v-on:change="handleChange"
    />
  </div>
</template>

<script>
import BaseSelectSearch from '@/components/base/BaseSelectSearch.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  name: 'RoleSelectSearch',
  mixins: [requestMixin],
  components: { BaseSelectSearch },
  props: {
    value: null,
    limit: {
      type: Number,
      default: 10,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input', 'change'],
  data: function () {
    return {
      search: null,
      size: null,
      loading: false,
      roles: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    selected: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    init: function () {
      this.size = this.limit;

      this.loadRoles();
    },
    loadRoles: async function () {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/roles', {
        params: {
          'page[size]': this.size,
          'filter[search]': this.search,
          'fields[roles]': 'name',
        },
      });

      if (!error) {
        this.roles = res;
      }

      this.loading = false;
    },
    handleInfiniteScroll: function () {
      if (this.size < this.roles.meta.page.total) {
        this.size += this.limit;

        this.loadRoles();
      }
    },
    handleSearch: function (search) {
      this.size = this.limit;
      this.search = search;

      this.loadRoles();
    },
    handleChange: function () {
      this.$emit('change');
    },
  },
  created: function () {
    this.init();
  },
};
</script>
