<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Edit Hak Akses
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Edit Hak
            </p>
          </div>
        </div>
      </div>
      <form
        class="mt-6 space-y-4 border-t border-gray-200 pt-6"
        @submit.prevent="onSave"
      >
        <base-input
          label="Nama Hak Akses"
          :shadow="false"
          fullwidth
          placeholder="Nama Hak Akses"
          type="text"
          v-model="form.name"
        />
        <base-input
          tag="textarea"
          rows="3"
          label="Deskripsi Hak Akses"
          :shadow="false"
          fullwidth
          placeholder="Deskripsi Hak Akses"
          type="text"
          v-model="form.description"
        />
        <div class="space-x-2">
          <base-button color="white" type="button" @click="$emit('close')"
            >Batal</base-button
          >
          <base-button>Simpan</base-button>
        </div>
      </form>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: { BaseModal },
  props: {
    visible: Boolean,
    role: Object
  },
  emits: ['close', 'success'],
  data() {
    return {
      loading: false,
      form: {
        name: null,
        description: null,
      },
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onOpened() {
      this.form.name = this.role.attributes.name;
      this.form.description = this.role.attributes.description;
    },
    async onSave() {
      this.loading = true;

      const [, error] = await this.request(`/api/v1/roles/${this.role.id}`, {
        method: 'patch',
        data: {
          data: {
            type: 'roles',
            id: this.role.id,
            attributes: {
              name: this.form.name,
              description: this.form.description,
            },
          },
        },
      });

      if (!error) {
        this.$emit('success');
        this.$emit('close');
      } else {
        this.createAlert({
          data: error.message,
          status: 'error',
        });
      }

      this.loading = false;
    },
  },
};
</script>
