import { render, staticRenderFns } from "./setup-product-required-create-member.vue?vue&type=template&id=01f87a57"
import script from "./setup-product-required-create-member.vue?vue&type=script&lang=js"
export * from "./setup-product-required-create-member.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports