<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <base-input
          :shadow="false"
          placeholder="Cari Hak Akses"
          type="search"
          debounce
          v-on:native-input="onSearch"
          v-model="filter.search"
        />
        <base-button @click="onCreate">Tambah Hak Akses</base-button>
      </div>
      <datatable
        :columns="columns"
        :scroll-x="false"
        :total="roles.meta.page.total"
        :perPage="roles.meta.page.perPage"
        :currentPage="roles.meta.page.currentPage"
        @pagechanged="onPageChanged"
      >
        <template v-slot:tbody="{ classes }">
          <tr
            v-for="role in roles.data"
            :key="role.id"
            :class="[classes.tr, 'bg-white']"
          >
            <td @click="onOpenDetail(role)" :class="[classes.td, 'cursor-pointer']">{{ role.attributes.name }}</td>
            <td @click="onOpenDetail(role)" :class="[classes.td, 'cursor-pointer']">{{ role.attributes.description | truncate(100) }}</td>
            <td :class="[classes.td]">
              <button
                class="flex h-8 w-8 items-center justify-center rounded-lg hover:bg-gray-100"
                @click="onEdit(role)"
              >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </svg>
              </button>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <role-create-modal
      :visible="createModalVisible"
      @close="createModalVisible = false"
      @success="onSuccessCreate"
    />
    <role-edit-modal
      :visible="editModal.visible"
      :role="editModal.role"
      @close="editModal.visible = false"
      @success="onSuccessEdit"
    />
    <role-detail-modal
      :visible="detailModal.visible"
      :role-id="detailModal.roleId"
      :reload="detailModal.reload"
      @close="onCloseDetail"
      @attach-menu="onAttachMenu"
      @detail-permission="onDetailMenuPermission"
    />
    <role-attach-menu-modal
      :visible="attachMenuModal.visible"
      :role-id="attachMenuModal.roleId"
      @close="onCloseAttachMenu"
    />
    <role-menu-permission-modal
      :visible="menuPermissionModal.visible"
      :reload="menuPermissionModal.reload"
      :menu-id="menuPermissionModal.menuId"
      :role-id="menuPermissionModal.roleId"
      @attach-permission="onAttachMenuPermission"
      @close="onCloseDetailMenuPermission"
    />
    <role-attach-menu-permission-modal
      :visible="attachMenuPermissionModal.visible"
      :menu-id="attachMenuPermissionModal.menuId"
      :role-id="attachMenuPermissionModal.roleId"
      @attach-permission="onAttachMenuPermission"
      @close="onCloseAttachMenuPermission"
    />

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import RoleDetailModal from '@/components/role/role-detail-modal.vue';
import RoleAttachMenuModal from '@/components/role/role-attach-menu-modal.vue';
import RoleCreateModal from '@/components/role/role-create-modal.vue';
import RoleEditModal from '@/components/role/role-edit-modal.vue';
import RoleMenuPermissionModal from '@/components/role/role-menu-permission-modal.vue';
import RoleAttachMenuPermissionModal from '@/components/role/role-attach-menu-permission-modal.vue';

export default {
  mixins: [requestMixin],
  components: {
    RoleDetailModal,
    RoleAttachMenuModal,
    RoleCreateModal,
    RoleMenuPermissionModal,
    RoleAttachMenuPermissionModal,
    RoleEditModal
  },
  data() {
    return {
      loading: false,
      roles: {
        data: [],
        meta: {
          page: {},
        },
      },
      filter: {
        search: null,
      },
      createModalVisible: false,
      editModal: {
        visible: false,
        role: null,
      },
      detailModal: {
        visible: false,
        reload: true,
        roleId: null,
      },
      attachMenuModal: {
        visible: false,
        roleId: null,
      },
      menuPermissionModal: {
        visible: false,
        reload: true,
        roleId: null,
        menuId: null,
      },
      attachMenuPermissionModal: {
        visible: false,
        roleId: null,
        menuId: null,
      },
    };
  },
  computed: {
    columns() {
      return [
        { id: 'name', name: 'Nama' },
        { id: 'description', name: 'Deskripsi' },
        { id: 'action', name: 'Aksi' },
      ];
    },
  },
  methods: {
    onPageChanged(page) {
      this.loadRoles({
        'page[number]': page,
      });
    },
    onSearch() {
      this.loadRoles();
    },
    onCreate() {
      this.createModalVisible = true;
    },
    onSuccessCreate() {
      this.loadRoles();
    },
    onEdit(role) {
      this.editModal.role = role;
      this.editModal.visible = true;
    },
    onSuccessEdit() {
      this.loadRoles();
    },
    onOpenDetail(role) {
      this.detailModal.roleId = role.id;
      this.detailModal.visible = true;
    },
    onCloseDetail() {
      this.detailModal.reload = true;
      this.detailModal.visible = false;
    },
    onAttachMenu(role) {
      this.detailModal.visible = false;

      this.attachMenuModal.roleId = role.id;
      this.attachMenuModal.visible = true;
    },
    onCloseAttachMenu(options = {}) {
      this.attachMenuModal.visible = false;

      this.detailModal.reload = options?.reload ?? false;
      this.detailModal.visible = true;
    },
    onDetailMenuPermission({ menu, role }) {
      this.detailModal.visible = false;

      this.menuPermissionModal.menuId = menu.id;
      this.menuPermissionModal.roleId = role.id;
      this.menuPermissionModal.visible = true;
    },
    onCloseDetailMenuPermission() {
      this.menuPermissionModal.visible = false;
      this.menuPermissionModal.reload = true;

      this.detailModal.reload = false;
      this.detailModal.visible = true;
    },
    onAttachMenuPermission() {
      this.menuPermissionModal.visible = false;

      this.attachMenuPermissionModal.menuId = this.menuPermissionModal.menuId;
      this.attachMenuPermissionModal.roleId = this.menuPermissionModal.roleId;
      this.attachMenuPermissionModal.visible = true;
    },
    onCloseAttachMenuPermission(options = {}) {
      this.attachMenuPermissionModal.visible = false;

      this.menuPermissionModal.reload = options?.reload ?? false;
      this.menuPermissionModal.visible = true;
    },
    async loadRoles(params) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/roles', {
        params: {
          'page[size]': 5,
          sort: 'name',
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!error) {
        this.roles = res;
      }

      this.loading = false;
    },
  },
  created() {
    this.loadRoles();
  },
};
</script>
