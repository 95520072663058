<template>
  <div class="mb-5 rounded-lg bg-white py-4">
    <div class="space-y-4 px-4">
      <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-12">
          <label for="status_point" class="text-xs font-bold text-gray-700">
            P/N
          </label>
          <div class="my-4 flex gap-4">
            <div class="text-sm text-gray-700">
              <input
                v-model="value.status_point"
                type="radio"
                name="status_point"
                id="status_point"
                :value="true"
                class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              <label for="status_point"> Point </label>
            </div>
            <div class="text-sm text-gray-700">
              <input
                v-model="value.status_point"
                type="radio"
                name="status_point"
                id="status_non_point"
                :value="false"
                class="mr-2 h-4 w-4 cursor-pointer border-gray-300 text-green-600 focus:ring-green-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              <label for="status_non_point"> Non-Point </label>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 flex sm:flex-row sm:gap-x-4" v-if="value.status_point">
        <base-input
          type="text"
          label="Point Value"
          placeholder="Masukkan Point Value"
          id="point_value"
          with-label
          inset
          fullwidth
          :invalid="errors.point_value"
          :message="errors.point_value"
          v-model="value.point_value"
          currency
        />
        <base-input
          type="text"
          label="Bonus Value"
          placeholder="Masukkan Bonus Value"
          id="bonus_value"
          with-label
          inset
          fullwidth
          :invalid="errors.bonus_value"
          :message="errors.bonus_value"
          v-model="value.bonus_value"
          currency
        />
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@/components/base/BaseInput.vue';

export default {
  name: 'PointInformationForm',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
};
</script>
