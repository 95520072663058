<template>
  <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
    <template v-if="getProduct.data.attributes.is_product">
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.code }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.name }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Kategori Barang</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.product_category_name }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Kode Supplier</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.supplier_code }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Nama Supplier</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.supplier_name }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Harga Supplier</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.supplier_price | toCurrency }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">P/N</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ !!getProduct.data.attributes.is_point ? 'Point' : 'Non Point' }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">PV</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.point }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">BV</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.bonus | toCurrency }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Lembaga Ijin</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.license_institution }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Status Izin</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <span
            v-if="getProduct.data.attributes.license_status"
            class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
          >
            Lengkap
          </span>
          <span
            v-else
            class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
          >
            Sedang Proses
          </span>
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Mulai Izin - Akhir Izin</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.license_start }}
          <span class="px-1 font-light text-gray-300">s.d</span>
          {{ getProduct.data.attributes.license_end }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.createdAt | formatDate }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Terakhir Diperbarui</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.updatedAt | formatDate }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="flex items-center gap-x-4 text-sm font-bold text-gray-700">
          <span>QR</span>
          <toggle-button
            v-if="getProduct.data.attributes.qrcode"
            :labels="{
              checked: 'Aktif',
              unchecked: 'Non Aktif',
            }"
            :width="getProduct.data.attributes.is_qrcode_active ? 60 : 80"
            v-model="getProduct.data.attributes.is_qrcode_active"
            @change="onChangeQrCodeStatus"
          />
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <a
            href=""
            class="font-medium text-green-600 hover:text-green-500"
            @click.prevent="onEditQr"
            >Setup QR</a
          >
          <div class="mt-2">
            <qr-code
              v-if="getProduct.data.attributes.qrcode"
              :text="getProduct.data.attributes.qrcode"
              :size="100"
            />
          </div>
        </dd>
      </div>
    </template>
    <template v-else>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Kode Box</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.code }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Nama Box</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ getProduct.data.attributes.name }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Total Barang</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{
            getSingleIncluded(
              getProduct,
              getProduct.data.relationships.product.data.id
            ).attributes.product_count
          }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{
            getSingleIncluded(
              getProduct,
              getProduct.data.relationships.product.data.id
            ).attributes.code
          }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{
            getSingleIncluded(
              getProduct,
              getProduct.data.relationships.product.data.id
            ).attributes.name
          }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">Kategori Barang</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{
            getSingleIncluded(
              getProduct,
              getProduct.data.relationships.product.data.id
            ).attributes.product_category_name
          }}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">QR Box</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <qr-code
            v-if="getProduct.data.attributes.qrcode"
            :text="getProduct.data.attributes.qrcode"
            :size="100"
          />
          <a
            v-else
            href=""
            class="font-medium text-green-600 hover:text-green-500"
            @click.prevent="onEditQr"
            >Setup QR</a
          >
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-sm font-bold text-gray-700">QR Barang</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <qr-code
            v-if="
              getSingleIncluded(
                getProduct,
                getProduct.data.relationships.product.data.id
              ).attributes.qrcode
            "
            :text="
              getSingleIncluded(
                getProduct,
                getProduct.data.relationships.product.data.id
              ).attributes.qrcode
            "
            :size="100"
          />
          <span v-else>Belum ada qr</span>
        </dd>
      </div>
    </template>
  </div>
</template>

<script>
import QrCode from 'vue-qrcode-component';
import { ToggleButton } from 'vue-js-toggle-button';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    QrCode,
    ToggleButton,
  },
  emits: ['edit-qr'],
  computed: {
    ...mapGetters({
      getProduct: 'products/getProduct',
    }),
  },
  methods: {
    ...mapActions({
      updateProduct: 'products/updateProductWithParams',
    }),
    onEditQr() {
      this.$emit('edit-qr');
    },
    onChangeQrCodeStatus({ value }) {
      this.updateProduct({
        payload: {
          data: {
            type: 'products',
            id: this.getProduct.data.id,
            attributes: {
              is_qrcode_active: value,
            },
          },
        },
        params: {
          include: 'product-prices,product',
          'fields[product-prices]': 'price,area,buyer-type',
        },
      });
    },
  },
};
</script>
