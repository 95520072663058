<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            placeholder="Cari Nama Wilayah"
            v-model="filter.search"
            v-on:input="debounceSearch"
            :withThreeLetterValidation="false"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getAll['cities'].meta?.page.total"
              :perPage="getAll['cities'].meta?.page.perPage"
              :currentPage="getAll['cities'].meta?.page.currentPage"
              :is-empty="!getAll['cities'].data?.length"
              @pagechanged="onPageChangeAreasCities"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Kota
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Kota
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Provinsi
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Area
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    class="bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getAll['cities'].data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        data.relationships['province'].data
                          ? getRelationships(
                              data.relationships['province'].data.id
                            ).attributes.name
                          : '-'
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.area_code }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Barang',
  components: {
    Datatable,
    BaseSearch,
  },
  data() {
    return {
      search: null,
      searchCities: '',
      openDetail: false,
      filter: {
        page: 1,
        search: null,
      },
      area_id: '',
      sort: {
        name: 'createdAt',
        direction: 'desc',
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'areas/getLoading',
      getAreas: 'areas/getAreas',
      getAreaCities: 'areas/getAreaCities',
      getAll: 'main/getAll',
    }),
    citiesParams() {
      return {
        'page[number]': this.filter.page,
        'page[size]': 5,
        'filter[search]': this.filter.search,
        sort: this.sort.name,
        include: 'province',
      };
    },
  },
  methods: {
    ...mapActions({
      fetchAreas: 'areas/fetchAreas',
      fetchAreaCities: 'areas/fetchAreaCities',
      fetchAll: 'main/fetchAll',
    }),
    getRelationships(id) {
      let data = this.getAll['cities'].included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    closeModal() {
      this.openDetail = !this.openDetail;
      this.fetchAreas({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
      });
    },
    onPageChangeAreas(page) {
      this.fetchAreas({
        pageNumber: page,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
      });
    },
    onPageChangeAreasCities(page) {
      this.filter.page = page;
      this.loadCities();
    },
    sortData(sortName) {
      if (this.sort.name === sortName) {
        this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort.name = sortName;
        this.sort.direction = 'asc';
      }
      this.fetchAreas({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
      });
    },
    debounceSearch: debounce(function () {
      this.loadCities();
    }, 300),
    debounceSearchCities: debounce(function () {
      this.fetchAreaCities({
        areaId: this.area_id,
        pageNumber: 1,
        pageSize: 5,
        search: this.searchCities,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
      });
    }, 300),
    detailArea(city) {
      this.area_id = city.id;
      this.fetchAreaCities({
        areaId: city.id,
        pageNumber: 1,
        pageSize: 5,
      });

      this.openDetail = !this.openDetail;
    },
    loadCities() {
      this.fetchAll({
        schema: 'cities',
        params: this.citiesParams,
      });
    },
  },
  created() {
    this.loadCities();
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
