<template>
  <base-modal :showing="visible" size="max-w-lg" @close="$emit('close')">
    <div class="space-y-4">
      <div
        class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
      >
        <svg
          class="h-6 w-6 text-green-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>

      <div class="text-center">
        <slot />
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';

export default {
  components: {
    BaseModal,
  },
  props: {
    visible: Boolean,
  },
  emits: ['close'],
};
</script>
