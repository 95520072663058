<template>
  <base-success-modal :visible="visible" @close="$emit('close')">
    <p>Klik link di bawah ini untuk mereset password.</p>
    <a :href="link" class="mt-1 block break-all font-semibold text-green-700">{{
      link
    }}</a>
  </base-success-modal>
</template>

<script>
import BaseSuccessModal from '@/components/base/BaseSuccessModal.vue';

export default {
  components: {
    BaseSuccessModal,
  },
  props: {
    visible: Boolean,
    token: String,
  },
  emits: ['close'],
  computed: {
    link() {
      if (!this.token) {
        return '';
      }

      const link = this.$router.resolve({
        name: 'reset-password',
        params: { token: this.token },
      });

      return new URL(link.href, window.location.origin);
    },
  },
};
</script>
