<template>
  <base-modal :showing="visible" :with-padding="false" size="max-w-5xl" @close="onClose">
    <div class="grid grid-cols-1 gap-5 p-8">
      <base-input
        :shadow="false"
        label="Nama Bundle"
        type="text"
        placeholder="Contoh: Promo MORESKIN"
        fullwidth
        :disabled="!!productBundle"
        v-model="form.name"
      />
      <base-input label="Syarat Barang">
        <div class="flex items-center space-x-8">
          <base-radio
            :disabled="!!productBundle"
            :val="false"
            v-model="form.requirement.using_min_qty"
            label="Syarat Barang Tetap"
            id="requirement_using_min_qty"
          />
          <base-radio
            :disabled="!!productBundle"
            :val="true"
            v-model="form.requirement.using_min_qty"
            label="Syarat Minimal Total Pembelian"
            id="requirement_not_using_min_qty"
          />
        </div>
      </base-input>
      <base-input
        v-if="form.requirement.using_min_qty"
        :shadow="false"
        label="Minimal Total Pembelian"
        type="number"
        placeholder="Contoh: 10"
        v-model="form.requirement.min_qty"
        fullwidth
        :disabled="!!productBundle"
      />
      <base-input v-if="!form.requirement.using_min_qty" label="Jumlah Syarat Barang">
        <div class="flex items-center space-x-8">
          <base-radio
            :disabled="!!productBundle"
            :val="false"
            v-model="form.requirement.multiple_products"
            label="Satu Barang"
            id="requirement_one_products"
          />
          <base-radio
            :disabled="!!productBundle"
            :val="true"
            v-model="form.requirement.multiple_products"
            label="Lebih Dari Satu Barang"
            id="requirement_multiple_products"
          />
        </div>
      </base-input>
      <base-input v-if="productBundle" label="Daftar Syarat Barang">      
        <product-bundle-requirement-table :product-bundle="productBundle" />
      </base-input>
      <base-input label="Jumlah Bonus Barang">
        <div class="flex items-center space-x-8">
          <base-radio
            :disabled="!!productBundle"
            val="single_product"
            v-model="form.bonus.type"
            label="Satu Barang"
            id="bonus_single_products"
          />
          <base-radio
            :disabled="!!productBundle"
            val="multiple_product"
            v-model="form.bonus.type"
            label="Lebih Dari Satu Barang"
            id="bonus_multiple_products"
          />
          <base-radio
            :disabled="!!productBundle"
            val="choose_one_product"
            v-model="form.bonus.type"
            label="Pilih Salah Satu Barang"
            id="bonus_choose_one_products"
          />
        </div>
      </base-input>
      <base-input v-if="productBundle" label="Daftar Syarat Barang">      
        <product-bundle-bonus-table :product-bundle="productBundle" />
      </base-input>
    </div>
    <div class="flex items-center justify-end space-x-2 border-t px-8 py-4">
      <base-button color="danger" @click="onDelete">Hapus</base-button>
      <base-button v-if="!productBundle" @click="onSave">Simpan</base-button>
    </div>
    <teleport to="body">
      <loading v-if="loading" />
    </teleport>
  </base-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal';
import BaseRadio from '@/components/base/BaseRadio.vue';
import ProductBundleRequirementTable from './product-bundle-requirement-table.vue'
import ProductBundleBonusTable from './product-bundle-bonus-table.vue'
import Teleport from 'vue2-teleport';

export default {
  components: { BaseModal, BaseRadio, ProductBundleRequirementTable, ProductBundleBonusTable, Teleport },
  mixins: [requestMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    productPromo: {
      type: Object,
      required: true
    },
    productBundleId: String
  },
  emits: ['close', 'created'],
  data() {
    return {
      loading: false,
      productBundle: null,
      form: {
        name: null,
        requirement: {
          using_min_qty: false,
          multiple_products: false,
          min_qty: null,
        },
        bonus: {
          type: 'single_product',
        },
      },
    };
  },
  watch: {
    visible(value) {
      if (value) {
        this.setForm()
      }
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onClose() {
      this.$emit('close');
    },
    async onDelete() {
      this.loading = true

      const [, error] = await this.request(`/api/v1/product-bundles/${this.productBundle.data.id}`, {
        method: 'delete'
      });

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
        });
      } else {
        this.$emit('close')
      }

      this.loading = false
    },
    async onSave() {
      this.loading = true

      const [res, error] = await this.request('/api/v1/product-bundles', {
        method: 'post',
        data: {
          data: {
            type: 'product-bundles',
            attributes: {
              name: this.form.name,
              min_qty: parseInt(this.form.requirement.min_qty),
              using_min_qty: this.form.requirement.using_min_qty,
              requirement_type: this.form.requirement.multiple_products ? 'multiple' : 'single',
              bonus_type: this.form.bonus.type === 'single_product' ? 'single' : this.form.bonus.type === 'multiple_product' ? 'multiple' : 'choose'
            },
            relationships: {
              'product-promo': {
                data: {
                  type: 'product-promos',
                  id: this.productPromo.data.id
                }
              }
            }
          }
        },
      });

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
        });
      } else {
        this.setProductBundle(res.data.id)
        this.$emit('created')
      }

      this.loading = false
    },
    async setForm() {
      this.productBundle = null

      if (this.productBundleId) {      
        this.setProductBundle(this.productBundleId)
      } else {
        this.form.name = null
        this.form.requirement.using_min_qty = false
        this.form.requirement.multiple_products = false
        this.form.requirement.min_qty = null
        this.form.bonus.type = 'single_product'
      }
    },
    async setProductBundle(id) {
      this.loading = true

      const [productBundle] = await this.request(`/api/v1/product-bundles/${id}`, {
        params: {
          include: 'product-requirements,product-bonuses'
        }
      })

      this.form.requirement.using_min_qty = productBundle.data.attributes.using_min_qty
      this.form.requirement.min_qty = productBundle.data.attributes.min_qty
      this.form.requirement.multiple_products = productBundle.data.attributes.requirement_type === 'multiple'
      this.form.bonus.type = productBundle.data.attributes.bonus_type === 'single'
        ? 'single_product'
        : productBundle.data.attributes.bonus_type === 'multiple'
          ? 'multiple_product'
          : 'choose_one_product'

      this.form.name = productBundle.data.attributes.name
      
      this.productBundle = productBundle

      this.loading = false
    }
  },
};
</script>
